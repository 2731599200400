import react, { useState } from "react";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { generateData } from "./data";
import { MdMoreHoriz, MdMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const DataTable = () => {
  const navigate = useNavigate();
  const data = generateData(1000);

  const details = (data: any) => {
    console.log(data);
    // navigate("/auth/shipments-details/" + data[0].id);
    navigate("/auth/shipments-details/" + data)
  };
  const tracking = (data: any) => {
    console.log(data);
    // navigate("/auth/shipments-details/" + data[0].id);
    navigate("/auth/tracking-details/" + data)
  };

  return (
    <div className="">
      <DataGrid
        id="gridContainer" 
        className="datagrid-height"
        dataSource={data}
        keyExpr="id"
        showRowLines={true}
        showColumnLines={false}
        rowAlternationEnabled={false}
        rtlEnabled={false}
        columnHidingEnabled={true}
        allowColumnResizing={true}
        onSelectionChanged={(val: any) => details(val.selectedRowsData)}
      >
        {/* <Selection mode="multiple" /> */}
        <Column
          dataField="id"
          headerCellRender={() => <div className="DataTable-Header">ID</div>}
          width={50}
        />

        <Column
          dataField="firstName"
          headerCellRender={() => (
            <i className="DataTable-Header">First Name</i>
          )}
        />
        <Column
          dataField="lastName"
          headerCellRender={() => <i className="DataTable-Header">Last Name</i>}
        />
        <Column
          dataField="gender"
          headerCellRender={() => <i className="DataTable-Header">Gender</i>}
        />
        <Column
          dataField="birthDate"
          headerCellRender={() => (
            <i className="DataTable-Header">Birth Date</i>
          )}
        />

        <Column
          dataField="sourceAgent"
          headerCellRender={() => (
            <i className="DataTable-Header">Source Agent</i>
          )}
        />

        <Column
          dataField="destinationAgent"
          headerCellRender={() => (
            <i className="DataTable-Header">Destination Agent</i>
          )}
        />
        <Column
          cssClass={"popupButton"}
          width={80}
          cellRender={() => (
            <div className="datagrid-dropdown">
               <Dropdown>
                <Dropdown.Toggle className="datagrid-dropdown-toggle" id="dropdown-basic">
                <MdMoreHoriz
                  fontSize={20}
                  fontWeight={700}
                />
                </Dropdown.Toggle>

                <Dropdown.Menu className="datagrid-dropdown-menu">
                  <Dropdown.Item onClick={() => details(1)} className="datagrid-dropdown-item">Explore</Dropdown.Item>
                  <Dropdown.Item onClick={() => tracking(1)} className="datagrid-dropdown-item">Tracking</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          headerCellRender={() => (
            <div className="DataTable-Header-Icon">
              <div>
                <MdMoreVert
                  fontSize={25}
                  fontWeight={900}
                />
              </div>
            </div>
          )}
        />
      </DataGrid>
    </div>
  );
};
export default DataTable;
