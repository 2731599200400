import { Button } from 'antd';
import React, { useState } from 'react'
import { AiOutlineExport } from 'react-icons/ai';
import { MdManageSearch } from 'react-icons/md';
import DataTable from './DataTable';
import SearchPopup from './SearchPopup';

const InvoiceScreen = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const searchPopup = () => {
      setIsPopupVisible(true);
    };
    return (
      <div className="Screen-Container">
        <div className="Screen-Header">
          <div className="Screen-Headertxt1">Invoice</div>
          <div className="Header-Box1">
            <div>
              <Button
              className="btn-advancesearch"
                type="link"
                size="large"
                icon={
                  <AiOutlineExport
                    size={18}
                    style={{ marginRight: 10, marginBottom: 5 }}
                  />
                }
              >
                Export To Excell
              </Button>
            </div>
            <div>
              <Button
                className="btn-advancesearch"
                type="link"
                size="large"
                icon={
                  <MdManageSearch
                    size={18}
                    style={{ marginRight: 10, marginBottom: 5 }}
                  />
                }
                onClick={() => searchPopup()}
              >
                Advance Search
              </Button>
              <SearchPopup
                visible={isPopupVisible}
                close={() => setIsPopupVisible(!isPopupVisible)}
              />
            </div>
          </div>
        </div>
        <div className="Screen-box">
          <DataTable />
        </div>
      </div>
    );
  };
  
  export default InvoiceScreen;