import React, { useState, useEffect, useCallback } from "react";
import { MdArrowBack, MdOutlineDelete, MdOutlineDownload } from "react-icons/md";
import { message } from "antd";
import { RiShieldStarFill } from "react-icons/ri";
import { BiRefresh } from "react-icons/bi";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../Config/API";
import { Tag } from "antd";
import UpdateDocumentsModal from "./UpdateDocumentsModal";
import { getDocumentJobFromPortal } from "../../Services/document-job-service";
import { searchAttachment, downloadAttachmentFile, downloadFile, deleteAttachmentFile, updateAttachmentStatus, searchAttachmentByGuid } from "../../Services/attachment-service";
import { updateBusinessNoteReadStatus } from "../../Services/note-service";
import { TbFileDescription } from "react-icons/tb";
import LoadingBox from "../../Components/LoadingBox";
import { DocumentAttachmentStatus } from "../../Config/default-value-enum";
import { RxCounterClockwiseClock } from "react-icons/rx";
import StatusHistoryModal from "./HistoryModal";
import { TbMessages } from "react-icons/tb";
import ChatModal from "./ChatModal";
import styles from "./styles";

function ApplicationDetails(props: any) {
  const location = useLocation();
  const { documentTypeGuid } = location.state;
  const navigate = useNavigate();
  const [form, setForm] = useState(false);
  const [viewHistoryPopup, setHistoryPopup] = useState(false);
  const [openChatPopup, setChatPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentJob, setDocumentJob] = React.useState<Partial<DocumentJob>>({});

  const [attachment, setAttchemnt] = useState({} as any);
  const [inboundDocument, setInboundDocument] = useState([]);
  const [outboundDocument, setOutboundDocument] = useState([]);
  const [statusBorderColor, setBorderColor] = useState('');
  const [statusBgColor, setBgColor] = useState('');
  const [statusList, setStatusList] = useState([] as any);

  useEffect(() => {

    const getAllData = async () => {
      if (documentTypeGuid !== null && documentTypeGuid !== undefined) {
        setLoading(true);

        let attchmentStatus = [
          { "Id": 1, "Name": "Open", "Value": DocumentAttachmentStatus.OPEN, },
          { "Id": 2, "Name": "Approved", "Value": DocumentAttachmentStatus.APPROVED, },
          { "Id": 3, "Name": "Declined", "Value": DocumentAttachmentStatus.DISAPPROVED }
        ];

        setStatusList(attchmentStatus);

        await loadDocumentJob();
        await loadAttachment();
        setLoading(false);
      };
    };
    getAllData();
  }, []);


  const loadDocumentJob = useCallback(async () => {
    setLoading(true);
    await getDocumentJobFromPortal(documentTypeGuid)
      .then(function (response) {
        let documentJob = response[0];
        documentJob.CreatedDate = new Date(documentJob.CreatedDate).toLocaleString();
        setDocumentJob(documentJob);
        setBorderColor(documentJob?.DocumentStatus.BorderColor);
        setBgColor(documentJob?.DocumentStatus.BackgroundColor);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const loadAttachment = useCallback(async () => {
    setLoading(true);
    await searchAttachment(documentTypeGuid)
      .then(function (response) {
        let attchmentList = response;
        filterFilesByType(attchmentList);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error(error);
        setLoading(false);
      });
  }, []);

  const refreshAttachment = async () => {
    await loadAttachment();
  }

  const filterFilesByType = async (attchmentList: any) => {
    let inboundDocumentFiles = [];
    let outboundDocumentFiles = [];

    inboundDocumentFiles = attchmentList.filter((attchment: any) => {
      return attchment.IsInboundFile
    });

    await setAggregationOfAttachment(inboundDocumentFiles);

    outboundDocumentFiles = attchmentList.filter((attchment: any) => {
      return attchment.IsOutBoundFile
    });

    await setAggregationOfAttachment(outboundDocumentFiles);

    setInboundDocument(inboundDocumentFiles);
    setOutboundDocument(outboundDocumentFiles);

  }

  const setAggregationOfAttachment = async (attchmentList: any) =>
    await new Promise((resolve, reject) => {

      if (attchmentList.length) {

        attchmentList.forEach((documentJobObj: any) => {

          documentJobObj.CreatedDate = new Date(documentJobObj.CreatedDate).toLocaleString();

          if (documentJobObj.hasOwnProperty('ModifiedDate')) {
            documentJobObj["ModifiedDate"] = new Date(documentJobObj["ModifiedDate"]).toLocaleString();
          }
        });
      }

      resolve(attchmentList);
    });

  const downloadAttachment = async (fileObj: any) => {
    setLoading(true);

    await downloadAttachmentFile(fileObj)
      .then(async function (response) {
        let objBase64String = response;

        let fileName = fileObj.FileName;
        let fileType = fileObj.FileType;
        await downloadFile(objBase64String, fileName, fileType);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error(error);
        setLoading(false);
      });
  }

  const approveAttachment = async (attachmentObj: any) => {
    setLoading(true);

    let inputAttachmentViewModel = {
      "StatusID": DocumentAttachmentStatus.APPROVED,
      "DocumentAttachmentGuid": attachmentObj.DocumentAttachmentGuid
    }

    await updateAttachmentStatus(inputAttachmentViewModel)
      .then(async function (response) {
        updateOutboundAttachmentByGuid(attachmentObj, response);
        message.success("Document approved successfully.");
        setLoading(false);
      })
      .catch(function (error) {
        console.log('error', error);
        message.error(error);
        setLoading(false);
      });

  }

  const updateOutboundAttachmentByGuid = (attachmentObj: any, updatedResponse: any) => {
    let newOutboundDocument = JSON.parse(JSON.stringify(outboundDocument));

    let objIndex = newOutboundDocument.findIndex((x: any) => x.DocumentAttachmentGuid.toLowerCase() == attachmentObj.DocumentAttachmentGuid.toLowerCase());

    if (objIndex > -1) {
      let aggregatedDocumentType = JSON.parse(JSON.stringify(newOutboundDocument[objIndex].DocumentType));
      newOutboundDocument[objIndex] = updatedResponse;
      newOutboundDocument[objIndex].DocumentType = aggregatedDocumentType;
    }

    setOutboundDocument(newOutboundDocument);
  }

  const disApproveAttachment = async (attachmentObj: any) => {
    setLoading(true);

    let inputAttachmentViewModel = {
      "StatusID": DocumentAttachmentStatus.DISAPPROVED,
      "DocumentAttachmentGuid": attachmentObj.DocumentAttachmentGuid
    }

    await updateAttachmentStatus(inputAttachmentViewModel)
      .then(async function (response) {
        updateOutboundAttachmentByGuid(attachmentObj, response);
        message.success("Document disapproved successfully.");
        setLoading(false);
      })
      .catch(function (error) {
        console.log('error', error);
        message.error(error);
        setLoading(false);
      });

  }

  const openAttachment = async (attachmentObj: any) => {
    setLoading(true);

    let inputAttachmentViewModel = {
      "StatusID": DocumentAttachmentStatus.OPEN,
      "DocumentAttachmentGuid": attachmentObj.DocumentAttachmentGuid
    }

    await updateAttachmentStatus(inputAttachmentViewModel)
      .then(async function (response) {
        updateOutboundAttachmentByGuid(attachmentObj, response);
        message.success("Document opend successfully.");
        setLoading(false);
      })
      .catch(function (error) {
        console.log('error', error);
        message.error(error);
        setLoading(false);
      });

  }

  const openChat = async (attachmentObj: any) => {
    setAttchemnt(attachmentObj);
    setChatPopup(true);
  }

  const viewAttachmentStatusHistory = async (attachmentObj: any) => {
    setAttchemnt(attachmentObj);
    setHistoryPopup(true);
  }

  const deleteAttachment = async (fileObj: any) => {

    if (documentJob.DocumentStatusGuid?.toLowerCase() == API.REACT_APP_OPEN_STATUS.toLowerCase()) {

      setLoading(true);

      Object.keys(fileObj).forEach(key => {
        if (fileObj[key] === null) {
          delete fileObj[key];
        }
      });

      let objNewDocumentList = [];
      let attachementToDelete = JSON.parse(JSON.stringify(fileObj));
      delete attachementToDelete.ModifiedDate;
      delete attachementToDelete.CreatedDate;
      delete attachementToDelete.DocumentType;
      objNewDocumentList.push(attachementToDelete);

      await deleteAttachmentFile(objNewDocumentList)
        .then(async function (response) {
          let newInboundDocument = JSON.parse(JSON.stringify(inboundDocument));

          let objIndex = newInboundDocument.findIndex((x: any) => x.DocumentAttachmentGuid.toLowerCase() == fileObj.DocumentAttachmentGuid.toLowerCase());

          if (objIndex > -1) {
            newInboundDocument.splice(objIndex, 1);
          }

          setInboundDocument(newInboundDocument);
          message.success("Successfully deleted document");
          setLoading(false);
        })
        .catch(function (error) {
          console.log('error', error);
          message.error(error);
          setLoading(false);
        });
    }
    else {
      message.error("Unable to delete document. Application is in " + (documentJob?.DocumentStatus?.Name)?.toLowerCase() + ' state.');
    }

  }

  const handleCallback = async (childData: any[]) => {
    await setAggregationOfAttachment(childData);
    let tempInboundDocument = []
    tempInboundDocument = JSON.parse(JSON.stringify(inboundDocument));
    tempInboundDocument.push(childData[0]);
    setInboundDocument(tempInboundDocument);
  }

  const handleUnReadMessageCallback = async (unReadMessageList: [string]) => {

    if (unReadMessageList.length) {
      await updateBusinessNoteReadStatus(unReadMessageList)
        .then(async function (response) {
          const customEvent = new Event('updateMessgeUnReadCount');
          document.dispatchEvent(customEvent);
          getUpdatedBusinessAttachment();
          setLoading(false);
        })
        .catch(function (error) {
          console.log('error', error);
          setLoading(false);
        });
    }
  }

  const getUpdatedBusinessAttachment = async () => {

    if (attachment != null && attachment != undefined && Object.keys(attachment).length > 0) {
      let attachmentGuid = attachment?.DocumentAttachmentGuid;

      await searchAttachmentByGuid(attachmentGuid)
        .then(async function (response) {
          let updatedAttachment = response;
          await setAggregationOfUpdatedAttachment(updatedAttachment, attachmentGuid);
          setLoading(false);
        })
        .catch(function (error) {
          console.log('error', error);
          message.error(error);
          setLoading(false);
        });
    }
  }

  const setAggregationOfUpdatedAttachment = (updatedAttachment: any[], attachmentGuid: string) => {
    if (updatedAttachment.length) {

      updatedAttachment.forEach((documentJobObj: any) => {

        documentJobObj.CreatedDate = new Date(documentJobObj.CreatedDate).toLocaleString();

        if (documentJobObj.hasOwnProperty('ModifiedDate')) {
          documentJobObj["ModifiedDate"] = new Date(documentJobObj["ModifiedDate"]).toLocaleString();
        }
      });

      if (updatedAttachment[0].IsOutBoundFile) {
        let newOutboundDocument = JSON.parse(JSON.stringify(outboundDocument));

        let objIndex = newOutboundDocument.findIndex((x: any) => x.DocumentAttachmentGuid.toLowerCase() == attachmentGuid.toLowerCase());

        if (objIndex > -1) {
          newOutboundDocument[objIndex] = updatedAttachment[0];
        }

        setOutboundDocument(newOutboundDocument);
      }
      else if (updatedAttachment[0].IsInboundFile) {
        let newInboundDocument = JSON.parse(JSON.stringify(inboundDocument));

        let objIndex = newInboundDocument.findIndex((x: any) => x.DocumentAttachmentGuid.toLowerCase() == attachmentGuid.toLowerCase());

        if (objIndex > -1) {
          newInboundDocument[objIndex] = updatedAttachment[0];
        }

        setInboundDocument(newInboundDocument);
      }
    }
  }

  const handleStatusOnChange = async (attachment: any, e: any) => {

    let selectedStatusValue = e.target.value;

    if (attachment.Status != selectedStatusValue) {
      if (selectedStatusValue == DocumentAttachmentStatus.APPROVED) {
        approveAttachment(attachment);
      }
      else if (selectedStatusValue == DocumentAttachmentStatus.DISAPPROVED) {
        disApproveAttachment(attachment);
      }
      else if (selectedStatusValue == DocumentAttachmentStatus.OPEN) {
        openAttachment(attachment);
      }
    }
  }

  const onCloseChatPopup = async () => {
    setChatPopup(false);
    setAttchemnt({});
  }

  const onCloseHistoryPopup = async () => {
    setHistoryPopup(false);
    setAttchemnt({});
  }


  return loading ? (<LoadingBox />) : (
    <div className="Screen-Container-Detail">
      <div className="Screen-Header3">
        <div className="Screen-Headertxt1">
          <MdArrowBack
            size={25}
            style={{ marginRight: 15 }}
            onClick={() => navigate(-1)}
            className="Screen-HeaderIcon1"
          />{" "}
          Application Details ( {documentJob.DocumentJobNo} )
        </div>
      </div>
      <div className="Screen-box">
        <div className="ApplicationDetails-box1">

          <div className="ApplicationDetails-box3 width_100 margin_bottom_1_5">
            <div className="ApplicationDetails-txt4">Contact Details</div>
            <div className="display_flex flex_row width_100" style={{ flex: 3 }}>
              <div className="display_flex flex_row ApplicationDetails-box2 width_100">

                <div className="display_flex flex_row width_100">
                  <div className="display_flex flex_column width_100">
                    <div style={{ margin: "2%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt2 ApplicationDetails-txt2-direction" >Full Name</div>
                      <div className="ApplicationDetails-txt3 " >{documentJob?.Customer?.DocumentJobCustomerName}</div>
                    </div>
                    <div style={{ margin: "1.5%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt2 ApplicationDetails-txt2-direction">Email</div>
                      <div className="ApplicationDetails-txt3 ">
                        {documentJob?.Customer?.DocumentJobCustomerEmailAddress}
                      </div>
                    </div>
                    <div style={{ margin: "1.5%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt2 ApplicationDetails-txt2-direction">Contact Number</div>
                      <div className="ApplicationDetails-txt3 ">
                        {documentJob?.Customer?.DocumentJobCustomerCellPhoneNumber}
                      </div>
                    </div>
                    <div style={{ margin: "1.5%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt2 ApplicationDetails-txt2-direction">Business Name</div>
                      <div className="ApplicationDetails-txt3 ">
                        {documentJob?.Customer?.DocumentJobCustomerCompanyName}
                      </div>
                    </div>
                    <div style={{ margin: "1.5%" }} />
                  </div>
                  <div className="display_flex flex_column width_100">
                    <div style={{ margin: "2%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt2 ApplicationDetails-txt2-direction">Address</div>
                      <div className="ApplicationDetails-txt3 ">
                        {documentJob?.Customer?.DocumentJobCustomerCompanyAddress}
                      </div>
                    </div>
                    <div style={{ margin: "1.5%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt9 ApplicationDetails-txt2-direction">Send Date</div>
                      <div className="ApplicationDetails-txt10 ">
                        {documentJob?.CreatedDate}
                      </div>
                    </div>
                    <div style={{ margin: "1.5%" }} />
                    <div className="ApplicationDetails-box6">
                      <div className="ApplicationDetails-txt9 ApplicationDetails-txt2-direction">Current Status</div>
                      <div className="ApplicationDetails-txt10">
                        <Tag
                          style={{ fontSize: "10px", backgroundColor: statusBgColor, borderColor: statusBorderColor, color: statusBorderColor }}>
                          {documentJob?.DocumentStatus?.Name}
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ApplicationDetails-box4 margin_bottom_1_5 width_35" >
                  <RiShieldStarFill size={20} />
                  <div style={{ margin: 5 }} />

                  <div className="ApplicationDetails-txt1">{documentJob?.DocumentCategory?.Name}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="ApplicationDetails-box3 width_100 margin_bottom_1_5">
            <div className="ApplicationDetails-box-upload">
              <div className="ApplicationDetails-txt4">Uploaded Documents</div>
              <div
                className="ApplicationDetails-txt12 Screen-HeaderIcon1"
              >
                <div className="display_flex flex_row ">
                  <div className="margin_lr_8">
                    <BiRefresh size={24} onClick={() => refreshAttachment()} />
                  </div>
                  <div className="display_flex flex_row justify_center flex_align_center" onClick={() => setForm(true)} >
                    <BsFillPlusCircleFill size={17} />
                    <span style={{ margin: 3 }} className="Screen-HeaderIcon1"></span> New Upload
                  </div>
                </div>
              </div>
            </div>
            <div style={{ margin: "1%" }} />
            <div className="ApplicationDetails-txt5">{inboundDocument.length} Items</div>
            <div style={{ margin: "1%" }} />
            <div className="display_flex flex_flow">
              {inboundDocument?.map((item: any, index) => {
                return (
                  <div className="margin_bottom_20 margin_right_20">
                    <div className="ApplicationDetails-box7">
                      <div className="ApplicationDetails-box8">
                        <div
                          className="DeailsScreen-box2"
                          style={{ background: "#f0f0f0" }}
                        >
                          <TbFileDescription color="#9ED10F" size={40} />
                        </div>
                      </div>
                      <div className="ApplicationDetails-box9 position_relative">
                        <div className="ApplicationDetails-box10 width_100">

                          <div className="ApplicationDetails-txt6">
                            {item.DocumentType?.Name}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          <div className="ApplicationDetails-txt7">
                            {item.FileName}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          <div className="ApplicationDetails-txt8">
                            {item.TenantUserName}{" "}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          <div className="ApplicationDetails-txt8">
                            {item.CreatedDate}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          {/* <div className="display_flex flex_row width_100 justify_end">
                            <TbMessages className="Screen-HeaderIcon1" size={21} color="#707070" onClick={() => openChat(item)} />
                          </div> */}

                          <span className="display_flex flex_row justify_end align_center margin_right_10">
                            <div className="position_relative">
                              <div className="display_flex align_center">
                                <TbMessages className="Screen-HeaderIcon1" size={21} color="#707070" onClick={() => openChat(item)} />
                              </div>
                              {item.TotalUnReadedMessageCount > 0 ? (
                                <div className="message_count_circle font_8 position_absolute" >
                                  {item.TotalUnReadedMessageCount}
                                </div>
                              ) : (<></>)
                              }
                            </div>
                          </span>

                        </div>

                        <div className="position_absolute right_10 display_flex flex_align_center space_between width_100">
                          <div className="display_flex justify_end width_100">
                            <MdOutlineDownload className="Screen-HeaderIcon1" size={20} color="#707070" onClick={() => downloadAttachment(item)} />

                            <div style={{ margin: "1%" }} />

                            <MdOutlineDelete className="Screen-HeaderIcon1" size={20} color="#707070" onClick={() => deleteAttachment(item)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              }
            </div>
          </div>

          <div className="ApplicationDetails-box3 width_100 margin_bottom_1_5">
            <div className="ApplicationDetails-box-upload">
              <div className="ApplicationDetails-txt4">Received Documents</div>
              <div
                className="ApplicationDetails-txt12 Screen-HeaderIcon1"
              >
                <div className="display_flex flex_row ">
                  <div className="margin_lr_8">
                    <BiRefresh size={24} onClick={() => refreshAttachment()} />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ margin: "1%" }} />
            <div className="ApplicationDetails-txt5">{outboundDocument.length} Items</div>
            <div style={{ margin: "1%" }} />

            <div className="display_flex flex_flow">
              {outboundDocument?.map((item: any, index) => {
                return (
                  <div className="margin_bottom_20 margin_right_20">
                    <div className="ApplicationDetails-box7">
                      <div className="ApplicationDetails-box8">
                        <div
                          className="DeailsScreen-box2"
                          style={{ background: "#f0f0f0" }}
                        >
                          <TbFileDescription color="#9ED10F" size={40} />
                        </div>
                      </div>
                      <div className="ApplicationDetails-box9 position_relative">
                        <div className="ApplicationDetails-box10 width_100">

                          <div style={{ margin: "5%" }} />

                          <div className="ApplicationDetails-txt6">
                            {item.DocumentType?.Name}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          <div className="ApplicationDetails-txt7">
                            {item.FileName}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          <div className="ApplicationDetails-txt8">
                            {item.TenantUserName}{" "}
                          </div>

                          <div style={{ margin: "1.5%" }} />

                          <div className="ApplicationDetails-txt8">
                            {item.CreatedDate}
                          </div>

                          <div style={{ margin: "3%" }} />

                          <div className="right_10 display_flex flex_align_center space_between width_100">
                            <div className=" display_flex align_center">
                              <div className="margin_right_12">
                                {/* <Select className="custom-select" size="small"
                                  onChange={(e: any) => handleStatusOnChange(item, e)}
                                  value={item.Status}
                                >
                                  {statusList.length &&
                                    statusList?.map((status: any, index: number) => {
                                      return (
                                        <Select.Option disabled={item.Status == 1 ? true : false} key={index} value={status.Value}>
                                          {status.Name}
                                        </Select.Option>
                                      );
                                    })}
                                </Select> */}

                                <select className="custom-select status_select_box" value={item.Status}
                                  disabled={item.Status == 1 ? true : false}
                                  style={{
                                    backgroundColor: item.Status == 0 ? styles.openStatus.backgroundColor : item.Status == 1 ? styles.approvedStatus.backgroundColor : styles.declinedStatus.backgroundColor,
                                    border: item.Status == 0 ? styles.openStatus.border : item.Status == 1 ? styles.approvedStatus.border : styles.declinedStatus.border
                                  }}
                                  onChange={(e: any) => handleStatusOnChange(item, e)}>

                                  {statusList.length &&
                                    statusList?.map((status: any, index: number) => {
                                      return (
                                        <option disabled={item.Status == 1 ? true : false} key={index} value={status.Value} style={{ color: "balck !important", backgroundColor: "white" }}>
                                          {status.Name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>

                              <RxCounterClockwiseClock className="Screen-HeaderIcon1 margin_right_12" size={17} color="#707070" onClick={() => viewAttachmentStatusHistory(item)} />

                              <span className="display_flex flex_row align_center">
                                <div className="position_relative">
                                  <div className="display_flex align_center">
                                    <TbMessages className="Screen-HeaderIcon1 margin_right_12" size={21} color="#707070" onClick={() => openChat(item)} />
                                  </div>
                                  {item.TotalUnReadedMessageCount > 0 ? (
                                    <div className="message_circle font_8 position_absolute" >
                                      {item.TotalUnReadedMessageCount}
                                    </div>
                                  ) : (<></>)
                                  }
                                </div>
                              </span>
                            </div>


                            {/* <div className="display_flex height_100P">
                              {item.TotalUnReadedMessageCount > 0 ? (
                                <div className="message_circle position_relative">
                                  <span className="circle_text position_absolute">+ {item.TotalUnReadedMessageCount}</span>
                                </div>
                              ) : (
                                <>
                                </>
                              )}

                            </div> */}
                          </div>
                        </div>

                        <div className="position_absolute right_10 display_flex flex_align_center space_between width_100">
                          <div className="display_flex justify_end width_100">
                            <MdOutlineDownload className="Screen-HeaderIcon1" size={20} color="#707070" onClick={() => downloadAttachment(item)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              }
            </div>
          </div>
        </div>
      </div >
      {
        form ? (
          <UpdateDocumentsModal visible={form}
            props={documentJob}
            close={() => setForm(false)
            }
            parentCallback={handleCallback} />
        ) : null
      }

      {
        viewHistoryPopup ? (
          <StatusHistoryModal
            visible={viewHistoryPopup}
            props={attachment}
            close={() => onCloseHistoryPopup()}
          />
        ) : null
      }

      {
        openChatPopup ? (
          <ChatModal
            visible={openChatPopup}
            props={attachment}
            close={() => { onCloseChatPopup() }}
            unReadMessageCallback={handleUnReadMessageCallback}
          />
        ) : null
      }
    </div >
  );
}

export default ApplicationDetails;

interface DocumentJob {
  DocumentJobID: number;
  DocumentJobGuid: string;
  DocumentJobNo: string;
  BusinessNo: string;
  DocumentCategoryGuid: string;
  DocumentCategory: {
    DocumentCategoryID: number;
    DocumentCategoryGuid: string;
    Name: string;
    DisplayName: string;
    IconName: string;
    InboundDocumentTypeList: [

    ],
    OutboundDocumentTypeList: [

    ],
    InboundDocumentTypes: [];
    IsActive: boolean;
    IsSystemGenerated: boolean;
    LocationGuid: string;
    CreatedBy: string;
    LanguageGuid: string;
    LanguageGroupGuid: string;
    ModifiedDate: string;
    Timestamp: string;
  },
  DocumentStatusGuid: string;
  DocumentStatus: {
    DocumentStatusID: number;
    DocumentStatusGuid: string;
    Name: string;
    DisplayName: string;
    BackgroundColor: string;
    BorderColor: string;
    IsActive: boolean;
    IsSystemGenerated: boolean;
    LocationGuid: string;
    CreatedBy: string;
    LanguageGuid: string;
    LanguageGroupGuid: string;
    ModifiedDate: string;
    Timestamp: string;
  },
  Customer: {
    DocumentJobCustomerID: number;
    DocumentJobCustomerGuid: string;
    DocumentJobCustomerReferenceGuid: string;
    DocumentJobCustomerReferenceGroupGuid: string;
    DocumentJobCustomerReferenceCode: number;
    DocumentJobCustomerBillingAccountGuid: string;
    DocumentJobCustomerName: string;
    DocumentJobCustomerAddress: string;
    DocumentJobCustomerEmailAddress: string;
    DocumentJobCustomerCompanyName: string;
    DocumentJobCustomerCompanyAddress: string;
    DocumentJobCustomerShortName: string;
    DocumentJobCustomerCellPhoneNumber: string;
    DocumentJobCustomerIsCompanyCustomer: boolean;
    ModifiedDate: string;
    TimeStamp: string;
  },
  CreatedLocationGuid: string;
  InboundDocumentsCount: number;
  OutboundDocumentsCount: number;
  CreatedBy: string;
  IsFromPortal: boolean;
  CreatedDate: string;
  ModifiedDate: string;
  TimeStamp: string;
}
