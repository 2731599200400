const styles = {
  btn_Icon: {
    marginRight: 10,
    fontSize: 20,
    marginBottom: 5,
  },
  input: {
    height: 40,
  },
  input_Icon: {
    fontSize: 18,
    color: "#BBBBBB",
  },
};

export default styles;
