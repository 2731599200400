
const API = {

  BASR_URL: "https://w494kqx1n1.execute-api.eu-central-1.amazonaws.com/production/tenantIdentity/",
  GET_LANGUAGES: " https://w494kqx1n1.execute-api.eu-central-1.amazonaws.com/production/tenantIdentity/getTenantSubscribedLanguageList?userPortalKey=26734c2e-c71b-4765-9fc3-6183d622dd34",
  APPLICATION_URL: "https://1rl8p9p3v5.execute-api.eu-central-1.amazonaws.com/stage1",
  DOCUMENT_TYPE_URL: "https://wjuslt5ui5.execute-api.eu-central-1.amazonaws.com/stage1",
  ATTACHMENT_URL: 'https://dgwohx6v3b.execute-api.eu-central-1.amazonaws.com/prod',

  REACT_APP_GET_CUSTOMER_URL: "https://4rar2xalwj.execute-api.eu-central-1.amazonaws.com/prod",
  REACT_APP_TENANT_IDENTITY_URL: "https://w494kqx1n1.execute-api.eu-central-1.amazonaws.com/production",
  REACT_APP_LANGUAGE_SERVICE_URL: "https://redboco4d4.execute-api.eu-central-1.amazonaws.com/prod",

  REACT_APP_OPEN_STATUS: "B4D1A5C1-0DA9-4E12-855E-632A2EF06F53",
  REACT_APP_CANCELED_STATUS: "A24AED48-08E2-47E3-95BF-B503BC755875",
  REACT_APP_COMPLETED_STATUS: "D92E6F9E-56DB-4B57-9C40-06BA79EF2377",
  REACT_APP_USER_PORTAL_KEY: "26734c2e-c71b-4765-9fc3-6183d622dd34",  
  REACT_APP_ENGLISH_LANGUAGE_GUID: "F8C0FDCA-11CA-4321-B207-46A75E12A3E5",

  REGISTER: "registerUserPortalUser",
  REACT_APP_ENGLISH_LANGUAGE_CODE: "en",
  IF_EMAIL_EXIST: "getLoginUserPoolInformation/?email="
  
};

export default API;
