import React, { useState } from "react";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { Auth, Amplify } from "aws-amplify";
import { getLoginUserPoolInformation } from "../../Services/tenant-identity-service";
import { getMessages } from "../../Services/language-service";
import awsPoolConfiguration from "../../Config/aws-exports";
import { MESSAGE } from "../../Config/message-enum";
import { Card, Form, Input, Button, Checkbox, message } from "antd";
import { MdPersonOutline } from "react-icons/md";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { USER_ROLE } from "../../Enum/user-role-enum";
import { clearLocalStorage } from "../../Services/cognito-token-service";


const LoginScreen = (props: any) => {
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [isFirstTimeLogin, SetisFirstTimeLogin] = useState(false);
  const [newPassword, SetnewPassword] = useState("");
  let i = 0;
  const changePasswordForFirstTimeLogin = (user: any, password: any) => {
    setIsLoading(true);
    let currentLanguage = "en";

    Auth.completeNewPassword(user, password)
      .then(async (user) => {
        setIsLoading(false);
        SetisFirstTimeLogin(false);
        let currentSession = await Auth.currentSession();
        let idToken = currentSession.getIdToken().getJwtToken()
        localStorage.setItem('authenticationToken', idToken);
        navigate("/auth/dashboard");
      })
      .catch(async (err) => {
        let validationMessage = await getMessages(
          MESSAGE.PASSWORD_POLICY_MESSAGE,
          currentLanguage
        );
        message.error(validationMessage);
        setIsLoading(false);
      });
  };

  const login = async (value: any) => {
    setIsLoading(true);
    try {
      await clearLocalStorage();
      let currentLanguage = "en";
      let obj = {
        email: value.username.trim(),
        password: value.password,
        newPassword: value.newPassword,
        isFirstTimeLogin: false,
      };

      let userResult = await getLoginUserPoolInformation(obj.email);

      if (userResult.length > 0) {
        let userData = userResult[0];
        let userRole = userData.role;
        let tenantResult = userData.Tenant;

        if (userRole === USER_ROLE.ROLE_CUSTOMER) {
          awsPoolConfiguration.aws_project_region = tenantResult.region;
          awsPoolConfiguration.aws_cognito_identity_pool_id =
            tenantResult.identityPoolId;
          awsPoolConfiguration.aws_cognito_region = tenantResult.region;
          awsPoolConfiguration.aws_user_pools_id = tenantResult.userPoolId;
          awsPoolConfiguration.aws_user_pools_web_client_id =
            tenantResult.clientId;

          Amplify.configure(awsPoolConfiguration);
          Auth.configure(awsPoolConfiguration);

          Auth.signIn(userData.email, obj.password)
            .then(async (user) => {
              setIsLoading(false);
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                if (obj.newPassword !== undefined) {
                  changePasswordForFirstTimeLogin(user, obj.newPassword);
                } else {
                  SetisFirstTimeLogin(true);
                }
              } else {
                let currentSession = await Auth.currentSession();
                let idToken = currentSession.getIdToken().getJwtToken()
                localStorage.setItem('authenticationToken', idToken);
                navigate("/auth/dashboard");
              }
            })
            .catch((error) => {
              console.log("signIn error error", error);
              console.log("signIn error error message", error.message);
              let errorObj = JSON.parse(JSON.stringify(error));
              let errMessage = errorObj.hasOwnProperty("errorMessage")
                ? errorObj.errorMessage
                : error.message;
              message.error(errMessage);
              setIsLoading(false);
            });

          window.sessionStorage.setItem(
            "tenantResult",
            JSON.stringify(tenantResult)
          );
          window.sessionStorage.setItem("userData", JSON.stringify(userData));
        } else {
          let validationMessage = await getMessages(MESSAGE.RESTRICT_LOGIPULSE_WEB_APPLICATION_USER, currentLanguage);
          message.error(validationMessage);
          setIsLoading(false);
        }
      } else {
        let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
        message.error(validationMessage);
        setIsLoading(false);
      }
    } catch (error: any) {
      console.log("err.....", error);
      let errorObj = JSON.parse(JSON.stringify(error));
      let errMessage = errorObj.hasOwnProperty("errorMessage")
        ? errorObj.errorMessage
        : error;
      message.error(errMessage);
      setIsLoading(false);
    }
  };

  const register = () => {
    navigate("/register");
  };

  const resetPassword = () => {
    navigate("/reset");
  };

  const emailValidator = (rule: any, value: string) => {
    return new Promise((resolve, reject) => {
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;

      if (value && !emailRegex.test(value)) {
        reject('Please enter a valid email address');
      } else {
        resolve(undefined);
      }
    });
  };

  return (
    <div>
      <Header />
      <div className="home-screen">
        <Container>
          <br />
          <div className="page-header-box">
            <div>User Account</div>
          </div>
          <Row>
            <Col sm="3" md="3" xs="12"></Col>
            <Col sm="6" md="6" xs="12">
              <br /> <br />
              <br /> <br />
              <Card
                style={{ borderRadius: 5, padding: 10 }}
                title={
                  <div>
                    <div className="Formtxt1">Sign In</div>
                  </div>
                }
              >
                <Form onFinish={login}>
                  <div
                    className="NewApplication-txt10"
                    style={{ marginBottom: "2.19%" }}
                  >
                    Email
                  </div>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: 'Please input your Email' },
                      // { type: 'email', message: 'Please enter a valid email address' },
                      // { validator: emailValidator }
                    ]}
                  >
                    <Input
                      style={styles.input}
                      placeholder="Username"
                    // suffix={<MdPersonOutline style={styles.input_Icon} />}
                    />
                  </Form.Item>
                  <div
                    className="NewApplication-txt10"
                    style={{ marginBottom: "2.19%" }}
                  >
                    Password
                  </div>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Password",
                        // pattern:
                        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        // message:
                        //   "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
                      },
                    ]}
                  >
                    <Input.Password
                      style={styles.input}
                      placeholder="Password"
                    />
                  </Form.Item>
                  {isFirstTimeLogin ? (
                    <>
                      <div
                        className="NewApplication-txt10"
                        style={{ marginBottom: "2.19%" }}
                      >
                        New Password
                      </div>
                      <Form.Item
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            pattern:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message:
                              "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
                          },
                        ]}
                      >
                        <Input.Password
                          style={styles.input}
                          placeholder="Password"
                        />
                      </Form.Item>
                    </>
                  ) : null}
                  <Row>
                    <Col sm="6" md="6" xs="6">
                      <Form.Item>
                        <Checkbox>
                          <b>Remember me</b>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col sm="6" md="6" xs="6">
                      <div
                        className="Formtxt3"
                        style={{ textAlign: "right", marginTop: 5 }}
                        onClick={() => resetPassword()}
                      >
                        Forgot password?
                      </div>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button
                      type="primary"
                      size="small"
                      style={{ width: 150 }}
                      htmlType="submit"
                      loading={isloading}
                    >
                      Sign In
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
              <br />
              <div>
                <span>Does not have account?</span>{" "}
                <span className="Formtxt3" onClick={() => register()}>
                  Register Now
                </span>
              </div>
            </Col>
            <Col sm="3" md="3" xs="12"></Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default LoginScreen;
