const styles = {
  btn_Icon: {
    marginRight: 10,
    fontSize: 20,
    marginBottom: 5,
  },
  input_Icon: {
    fontSize: 20,
    color: "grey",
  },
};

export default styles;
