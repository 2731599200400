import axios from "axios";
import { Auth } from "aws-amplify";
import API from "../Config/API";
import jwt_decode from 'jwt-decode';

const getToken = async () => {
  const currentSession = await Auth.currentSession();
  console.log("currentSession+++++++++++++", currentSession);
  console.log("token++++++++++", currentSession.getIdToken().getJwtToken());
  return currentSession.getIdToken().getJwtToken();
};

const getTokenDetailsByDecoding = () => {
  let token = localStorage.getItem('authenticationToken');

  if (token !== '' && token !== undefined && token !== null) {
    let decodedToken = jwt_decode(token);

    let tenantGuid = decodedToken['custom:tenantGuid'];
    let tenantUserGuid = decodedToken['custom:tenantUserGuid'];
    let tenantUserRole = decodedToken['custom:tenantUserRole'];
    let tenantUserName = decodedToken['custom:tenantUserName'];
    let tenantUserEmail = decodedToken['email'];
    let exp = decodedToken['exp'];
    let locationGuid = decodedToken['custom:locationGuid'];
    let currencyGuid = decodedToken['custom:currencyGuid'];
    let entityGroupGuid = decodedToken['custom:entityGroupGuid'];
    let entityGuid = decodedToken['custom:entityGuid'];

    return { tenantGuid, tenantUserGuid, locationGuid, currencyGuid, tenantUserRole, tenantUserName, tenantUserEmail, exp, entityGroupGuid, entityGuid };
  } else {
    return null;
  }
}

const clearLocalStorage = async () => {
  // let selectedLanguage = JSON.parse(localStorage.getItem("Language"));
  // console.log('selectedLanguage++++++++',selectedLanguage)
  // localStorage.clear();
  // localStorage.setItem("Language", JSON.stringify(selectedLanguage));
  localStorage.clear();
}

const getUserSelectedLanguage = () => {
  let LanguageGuid = API.REACT_APP_ENGLISH_LANGUAGE_GUID;
  let Code = API.REACT_APP_ENGLISH_LANGUAGE_CODE;
  let LanguageFromSessionStorage = sessionStorage.getItem("Language");

  if (LanguageFromSessionStorage != "undefined") {
    let storedArray = JSON.parse(sessionStorage.getItem("Language"));
    Code = storedArray.Code;
    LanguageGuid = storedArray.LanguageGuid;
    return { LanguageGuid, Code };
  } else {
    return { LanguageGuid, Code };
  }
}

export { getTokenDetailsByDecoding, getToken, clearLocalStorage, getUserSelectedLanguage };
