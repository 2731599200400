import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Modal, Button, Form, Input, Select, DatePicker, message } from "antd";
import { Moment } from 'moment';
import { DateRangeEnum, DefaultValueEnum } from "../../Config/default-value-enum";
import { SearchOperationBuilder, SearchParam } from "wherecomposer/dist/SearchParam";
import { DateCreator, DateRange } from 'wherecomposer/dist/DateCreator';
import { CustomDateTime } from 'wherecomposer/dist/CustomDateTime';
import { getDocumentStatus, getDocumentCategory } from "../../Services/document-job-service";
import { getLanguageDateRange } from "../../Services/predefined-service";
import moment from 'moment';

const initialFormValue = {
  documentJobId: "",
  documentJobNo: "",
  documentCategory: DefaultValueEnum.EmptyGuid,
  documentStatus: DefaultValueEnum.EmptyGuid,
  fromDate: new Date(),
  toDate: new Date(),
  createdDateLabel: DateRangeEnum.All
}

const SearchPopup = (props: any) => {
  const [documentJobInfoForm, setDocumentJobInfoForm] = useState(initialFormValue);
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const dateFormat = 'YYYY-MM-DD';
  const [isDisableCreatedDate, setDisableCreatedDate] = useState(true);
  const [dateRangeList, setDateRange] = useState([]);
  const [createdFromDate, setCreatedFromDate] = useState(moment(new Date()));
  const [createdToDate, setCreatedToDate] = useState(moment(new Date()));


  useEffect(() => {
    clearAdvanceSearchPopUp();
    loadDateRange();
    setDefaultValues();
    loadDocumentStatus();
    loadDocumentCategory();
  }, []);


  const loadDocumentStatus = async () => {
    getDocumentStatus()
      .then((response) => {
        setStatus(response);
      })
      .catch((error) => {
        console.log(error);
        message.error("Could'nt load Document Status");
      });
  }

  const loadDateRange = () => {
    getLanguageDateRange()
      .then((response: any) => {
        setDateRange(response);
      })
      .catch((error) => {
        console.log('getLanguageDateRange error+++++++', error);
        message.error("Could'nt load Date Range");
      })
  }

  const setDefaultValues = () => {
    documentJobInfoForm.createdDateLabel = DateRangeEnum.Today;
  }

  const getAdvanceViewModelObj = () => {
    let searchParam = [];
    const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (documentJobInfoForm.documentJobNo !== "" && documentJobInfoForm.documentJobNo !== null && documentJobInfoForm.documentJobNo !== undefined) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentJobNo", "String", documentJobInfoForm.documentJobNo, SearchOperationBuilder.create(true, false, false), true);
      searchParam.push(objSearchParam);
    }


    if (documentJobInfoForm.documentJobId !== "" && documentJobInfoForm.documentJobId !== null && documentJobInfoForm.documentJobId !== undefined) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentJobID", "Int", documentJobInfoForm.documentJobId.toString(), SearchOperationBuilder.create(true, false, false), true);
      searchParam.push(objSearchParam);
    }

    if (validRegex.test(documentJobInfoForm.documentCategory)) {
      let objContactName = new SearchParam();
      objContactName.searchParamCreate("DocumentCategoryGuid", "Guid", documentJobInfoForm.documentCategory, SearchOperationBuilder.create(true, false, false), true);
      searchParam.push(objContactName);
    }

    if (validRegex.test(documentJobInfoForm.documentStatus)) {
      let objContactName = new SearchParam();
      objContactName.searchParamCreate("DocumentStatusGuid", "Guid", documentJobInfoForm.documentStatus, SearchOperationBuilder.create(true, false, false), true);
      searchParam.push(objContactName);
    }

    //#region Created Date
    if (documentJobInfoForm.createdDateLabel !== DateRangeEnum.All && documentJobInfoForm.createdDateLabel !== undefined) {
      if (documentJobInfoForm.createdDateLabel !== DateRange.Custom) {
        let objCreatedDateParam = new SearchParam();
        DateCreator.getDate(documentJobInfoForm.createdDateLabel);
        objCreatedDateParam.searchParamFromTo("CreatedDate", "DateTime", new CustomDateTime(DateCreator.startDate), new CustomDateTime(DateCreator.endDate), true);
        searchParam.push(objCreatedDateParam);
      }
      else {
        let objCreatedDateParam = new SearchParam();
        objCreatedDateParam.searchParamFromTo("CreatedDate", "DateTime", new CustomDateTime(documentJobInfoForm.fromDate), new CustomDateTime(documentJobInfoForm.toDate), true);
        searchParam.push(objCreatedDateParam);
      }
    }
    //#endregion Created Date

    // let objCreator = new ConditionCreator();
    // let objWhere = objCreator.getSearchCondition(searchParam).toJsonString();

    // let applicationViewModelObj = {
    //   "Where": { "OperationList": objWhere.toJsonString() },
    //   "IsFull": true,
    //   "RequestedPage": 1,
    //   "PageSize": 25
    // };

    return searchParam;
  }

  const searchApplicationResult = async () => {
    try {
      setLoading(true);
      let applicationViewModelObj = getAdvanceViewModelObj();
      props.searchModal(applicationViewModelObj);
      props.close();
    } catch (error) {
      console.log('searchResult error++++++', error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentJobInfoForm({ ...documentJobInfoForm, [event.target.name]: event.target.value.trim() })
  }

  const handleChangeDocumentCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentJobInfoForm({ ...documentJobInfoForm, ['documentCategory']: event as any })
  }

  const handleChangeDocumentStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentJobInfoForm({ ...documentJobInfoForm, ['documentStatus']: event as any })
  }

  const handleChangeCreatedDate = (event: string) => {

    let selectedCreatedDateValue = event as any;
    let selectedFromDate = new Date();
    let selectedToDate = new Date();

    if (selectedCreatedDateValue != DateRangeEnum.All) {
      if (selectedCreatedDateValue == DateRangeEnum.Custom) {
        setDisableCreatedDate(false);
      }
      else {
        DateCreator.getDate(selectedCreatedDateValue);
        selectedFromDate = DateCreator.startDate;
        selectedToDate = DateCreator.endDate;
        setDisableCreatedDate(true);
      }
    } else {
      setDisableCreatedDate(true);
    }

    setCreatedFromDate(moment(selectedFromDate));
    setCreatedToDate(moment(selectedToDate));
    setDocumentJobInfoForm({ ...documentJobInfoForm, ['createdDateLabel']: event as any });
  }

  const handleToDateChange = (date: Moment | null, dateString: string) => {
    let selectedDate = new Date(dateString)
    setDocumentJobInfoForm({ ...documentJobInfoForm, ['toDate']: selectedDate });
  };

  const handleFromDateChange = (date: Moment | null, dateString: string) => {
    let selectedDate = new Date(dateString)
    setDocumentJobInfoForm({ ...documentJobInfoForm, ['fromDate']: selectedDate });
  };

  const loadDocumentCategory = async () => {
    getDocumentCategory()
      .then(function (response: any) {
        setCategory(response);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Could'nt load Document Category");
      });
  };

  const clearAdvanceSearchPopUp = async () => {
    setDocumentJobInfoForm(initialFormValue);
  }

  return (
    <Modal
      width={850}
      title={<div className="SearchPopup-title">Advanced Search</div>}
      open={props.visible}
      onOk={() => props.close()}
      onCancel={() => props.close()}
      footer={false}
    >
      <Form>
        <div className="SearchPopup-Box">
          <Row>
            <Col sm="3" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                Code
              </div>
              <Form.Item>
                <Input size="small" placeholder="Code" name="documentJobId" onChange={handleChange} />
              </Form.Item>
            </Col>
            <Col sm="3" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                No
              </div>
              <Form.Item>
                <Input size="small" placeholder="No" name="documentJobNo" onChange={handleChange} />
              </Form.Item>
            </Col>
            <Col sm="3" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                Category
              </div>
              <Form.Item>
                <Select size="small" placeholder="Category" onChange={handleChangeDocumentCategory}>
                  {category.length &&
                    category?.map((item: any, index) => {
                      return (
                        <Select.Option key={index} value={item.LanguageGroupGuid}>
                          {item.DisplayName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col sm="3" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                Status
              </div>
              <Form.Item>
                <Select size="small" placeholder="Document Status" onChange={handleChangeDocumentStatus}>
                  {status.length &&
                    status?.map((item: any, index) => {
                      return (
                        <Select.Option key={index} value={item.LanguageGroupGuid}>
                          {item?.DisplayName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* <Col sm="4" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                Name
              </div>
              <Form.Item>
                <Input size="small" placeholder="Name" />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                E-mail
              </div>
              <Form.Item>
                <Input size="small" placeholder="E-mail" />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                Contact Number
              </div>
              <Form.Item>
                <Input size="small" placeholder="Contact Number" />
              </Form.Item>
            </Col> */}
            <Col sm="4" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                From
              </div>
              <Form.Item>
                <DatePicker value={createdFromDate} size="small" style={{ width: "100%" }} name="fromDate"
                  onChange={handleFromDateChange} disabled={isDisableCreatedDate}
                />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                To
              </div>
              <Form.Item>
                <DatePicker value={createdToDate} format={dateFormat} size="small" style={{ width: "100%" }} name="toDate" onChange={handleToDateChange} disabled={isDisableCreatedDate} />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <div
                className="NewApplication-txt10"
                style={{ marginBottom: "2.19%" }}
              >
                Created Date
              </div>
              <Form.Item>
                <Select size="small" placeholder="Created Date" onChange={handleChangeCreatedDate} value={documentJobInfoForm.createdDateLabel}>
                  {dateRangeList.map((item: any, index) => {
                    return (
                      <Select.Option key={index} value={item?.GroupName}>{item?.Name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col sm="6" xs="12"></Col>
          <Col sm="6" xs="12">
            <Row>
              <Col sm="6" xs="6">
                <Button
                  type="default"
                  htmlType="button"
                  block
                  size="small"
                  style={{ backgroundColor: "#DDDDDD" }}
                  onClick={() => props.close()}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm="6" xs="6">
                <Button htmlType="submit" type="primary" block size="small" onClick={(event) => searchApplicationResult()}>
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SearchPopup;

