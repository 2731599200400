import React, { useEffect, useState } from "react";
import CardItem from "./CardItem";
import { Container, Row, Col } from "react-bootstrap";
import { MdOutlineLocalShipping } from "react-icons/md";
import { MdOutlineAssignment } from "react-icons/md";
import { MdOutlineReceipt } from "react-icons/md";
import { MdOutlineLocalActivity } from "react-icons/md";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { TbFileDescription } from "react-icons/tb";
import { Card, Form, Input, Button, message } from "antd";
import CardCount from "./CardCount";
import API from "../../Config/API";
import axios from "axios";
import LoadingBox from "../../Components/LoadingBox";
import { getDashboardSummary } from "../../Services/document-job-service";


const DashboardScreen = () => {
  const [loading, setLoading] = useState(false);
  const [activeDocumentList, setActiveDocumentData] = useState([]);
  const [completedDocumentCount, setCompletedCount] = useState([]);
  const [openDocs, SetOpenCount] = useState([]);
  const [cancelledDocs, setCancelledCount] = useState();
  const [activeDocs, setActiveCount] = useState();
  const currentDate = new Date().toLocaleString();

  let REACT_APP_OPEN_STATUS = API.REACT_APP_OPEN_STATUS;
  let REACT_APP_CANCELED_STATUS = API.REACT_APP_CANCELED_STATUS;
  let REACT_APP_COMPLETED_STATUS = API.REACT_APP_COMPLETED_STATUS;

  const colors = [
    "#f7fff3",
    "#FFFEDC",
    "#E1EDFF",
    "#EFFFFA",
    "#FFF5F5",
    "F9FFF3",
  ];

  const borderColors = [
    "#20e204",
    "#E8D103",
    "#3485FF",
    "#61D5B1",
    "#F69494",
    "#A3DF6E",
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    {
      await getDashboardSummary()
        .then((data) => {
          let dashBoardSummaryList = JSON.parse(JSON.stringify(data));

          let activeDocumentList = dashBoardSummaryList.filter((obj: any) => {
            return obj.DocumentStatusGuid.toLowerCase() !== REACT_APP_CANCELED_STATUS.toLowerCase() &&
              obj.DocumentStatusGuid.toLowerCase() !== REACT_APP_COMPLETED_STATUS.toLowerCase()
          });

          let activeDocumentCount = activeDocumentList.reduce((total: any, obj: any) => {
            return total + obj.TotalRecords;
          }, 0);

          let cancelledDocumentList = dashBoardSummaryList.filter((obj: any) => {
            return obj.DocumentStatusGuid.toLowerCase() === REACT_APP_CANCELED_STATUS.toLowerCase()
          });

          let cancelledDocumentCount = cancelledDocumentList.reduce((total: any, obj: any) => {
            return total + obj.TotalRecords;
          }, 0);

          let completedDocumentList = dashBoardSummaryList.filter((obj: any) => {
            return obj.DocumentStatusGuid.toLowerCase() === REACT_APP_COMPLETED_STATUS.toLowerCase()
          });

          let completedDocumentCount = completedDocumentList.reduce((total: any, obj: any) => {
            return total + obj.TotalRecords;
          }, 0);

          setActiveDocumentData(activeDocumentList);
          setActiveCount(activeDocumentCount);
          setCompletedCount(completedDocumentCount);
          setCancelledCount(cancelledDocumentCount);
          setLoading(false);
          // storeDocument(data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          message.error("Could'nt load Dashboard Summary.......");
        });
    }
  };

  const storeDocument = (data: any) => {
    let Closed = data && data[0].TotalRecords;
    let Open = data && data[1].TotalRecords;
    let Cancelled = data && data[2].TotalRecords;
    setCompletedCount(Closed);
    SetOpenCount(Open);

    data.map((item: any, index: any) => {
      if (item.DocumentStatusGuid === "a24aed48-08e2-47e3-95bf-b503bc755875") {
        setCancelledCount(item.TotalRecords);
      }
    });
    sessionStorage.setItem("OpenGuid", data && data[1].DocumentStatusGuid);
    let active = data.reduce(function (tot: any, arr: any) {
      if (arr.DocumentStatusName != "Cancelled") {
        return Number(tot) + Number(arr.TotalRecords);
      } else {
        return tot;
      }
    }, 0);
    setActiveCount(active);
    setLoading(false);
  };

  return (
    <div className="DashboardScreen">
      {loading ? (
        <LoadingBox />
      ) : (
        <Container fluid={true}>
          <br />
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <div className="DashboardScreen-box1">
                <div className="DashboardScreen-box2">
                  <TbFileDescription color="#4ACB70" size={40} />
                </div>
                <div className="DashboardScreen-box3">
                  <div
                    className="DashboardScreen-txt1"
                    style={{ marginBottom: "5px" }}
                  >
                    Active Documents
                  </div>
                  <div className="DashboardScreen-txt2">
                    {currentDate}
                  </div>
                  <br />
                  <br />
                  <Row>
                    {activeDocumentList.map((item: any, index: any) => {
                      if (
                        item.DocumentStatusName != "Cancelled" &&
                        item.DocumentStatusName != "Closed"
                      ) {
                        return (
                          <Col xs="6" sm="6" md="4" lg="2">
                            <CardCount
                              bgColor={item.BackgroundColor}
                              borderColor={
                                item.BorderColor
                              }
                              count={item.TotalRecords}
                              label={item.DocumentStatusName}
                              keyValue={index}
                            />
                          </Col>
                        );
                      }
                    })}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" xs="12" md="4" lg="4">
              <div className="DashboardScreen-box4">
                <div
                  className="DashboardScreen-box2 border_bottom_left_radius_8 border_top_left_radius_8"
                  style={{ background: "#4ACB70" }}
                >
                  <TbFileDescription color="#9ED10F" size={40} />
                </div>
                <div className="DashboardScreen-box3 ">
                  <div
                    className="DashboardScreen-txt1"
                    style={{ marginBottom: "5px" }}
                  >
                    Active Documents
                  </div>
                  <div className="DashboardScreen-txt2">
                    {currentDate}
                  </div>
                  <div className="DashboardScreen-txt3">
                    {activeDocs ? activeDocs : "0"}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12" xs="12" md="4" lg="4">
              <div className="DashboardScreen-box4">
                <div
                  className="DashboardScreen-box2 border_bottom_left_radius_8 border_top_left_radius_8"
                  style={{ background: "#c3b86c" }}
                >
                  <TbFileDescription color="#E71313" size={40} />
                </div>
                <div className="DashboardScreen-box3 ">
                  <div
                    className="DashboardScreen-txt1"
                    style={{ marginBottom: "5px" }}
                  >
                    Cancelled Documents
                  </div>
                  <div className="DashboardScreen-txt2">
                    {currentDate}
                  </div>
                  <div className="DashboardScreen-txt3">
                    {cancelledDocs ? cancelledDocs : "0"}
                  </div>
                </div>
              </div>
            </Col>

            <Col sm="12" xs="12" md="4" lg="4">
              <div className="DashboardScreen-box4">
                <div
                  className="DashboardScreen-box2 border_bottom_left_radius_8 border_top_left_radius_8"
                  style={{ background: "#FEE2E2" }}
                >
                  <TbFileDescription color="#FB7A7A" size={40} />
                </div>
                <div className="DashboardScreen-box3 ">
                  <div
                    className="DashboardScreen-txt1"
                    style={{ marginBottom: "5px" }}
                  >
                    Completed Documents
                  </div>
                  <div className="DashboardScreen-txt2">
                    Updated on 12 Oct 2022
                  </div>
                  <div className="DashboardScreen-txt3">
                    {completedDocumentCount ? completedDocumentCount : "0"}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col xs="12" sm="12" md="6" lg="3">
              <CardItem
                bgColor={"#B9D7E4"}
                color={"#026d3c"}
                label={"Shipments"}
                Value={"9 New Shipments"}
                desc={"Lorem ipsum dolor"}
                Icon={<MdOutlineLocalShipping />}
              />
            </Col>
            <Col xs="12" sm="12" md="6" lg="3">
              <CardItem
                bgColor={null}
                color={"#fff"}
                label={"Invoices"}
                Value={"1,928,93.00 AED"}
                desc={"Lorem ipsum dolor"}
                Icon={<MdOutlineReceipt />}
              />
            </Col>
            <Col xs="12" sm="12" md="6" lg="3">
              <CardItem
                bgColor={"#026D3C"}
                color={"#fff"}
                label={"Receipts"}
                Value={"19,297.00 AED"}
                desc={"Lorem ipsum dolor"}
                Icon={<MdOutlineAssignment />}
              />
            </Col>
            <Col xs="12" sm="12" md="6" lg="3"></Col>
            <Col xs="12" sm="12" md="6" lg="3">
              <CardItem
                bgColor={"#DDDDDD"}
                color={"#026D3C"}
                label={"Bills"}
                Value={"2,300.00 AED"}
                desc={"Lorem ipsum dolor"}
                Icon={<TbFileDescription />}
              />
            </Col>
            <Col xs="12" sm="12" md="6" lg="3">
              <CardItem
                bgColor={"#026D3C"}
                color={"#fff"}
                label={"Master Booking"}
                Value={"12 new Jobs"}
                desc={"Lorem ipsum dolor"}
                Icon={<MdOutlineBusinessCenter />}
              />
            </Col>
            <Col xs="12" sm="12" md="6" lg="3">
              <CardItem
                bgColor={"#EEEEEE"}
                color={"#026D3C"}
                label={"Vouchers"}
                Value={"1,230.00 AED"}
                desc={"Lorem ipsum dolor"}
                Icon={<MdOutlineLocalActivity />}
              />
            </Col>
          </Row> */}
        </Container>
      )}
    </div>
  );
};

export default DashboardScreen;
