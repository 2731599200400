import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { generateData } from "./data";
const GroupTable = () => {
  const data = generateData(5);

  return (
    <div className="">
      <DataGrid
        id="gridContainer"
        className="datagrid-height-details"
        dataSource={data}
        keyExpr="id"
        showRowLines={true}
        showColumnLines={false}
        rowAlternationEnabled={false}
        rtlEnabled={false}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Selection mode="multiple" />
        <Column
          dataField="id"
          headerCellRender={() => <div className="DataTable-Header">ID</div>}
          width={80}
        />

        <Column
          dataField="firstName"
          headerCellRender={() => (
            <i className="DataTable-Header">Category Type</i>
          )}
        />
        <Column
          dataField="lastName"
          headerCellRender={() => (
            <i className="DataTable-Header">Particulars</i>
          )}
          width={170}
        />
        <Column
          dataField="gender"
          headerCellRender={() => (
            <i className="DataTable-Header">Item Count</i>
          )}
          width={170}
        />
        <Column
          dataField="birthDate"
          headerCellRender={() => (
            <i className="DataTable-Header">Weight (Kg )/ Item</i>
          )}
        />
      </DataGrid>
    </div>
  );
};
export default GroupTable;
