import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import styles from "./styles";
import { Button } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import { MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const GetQuoteScreen = () => {
  let navigate = useNavigate();

  const navTrack = (value: any) => {
    navigate("/getQoute");
  };

  return (
    <div>
      <Header />
      <div className="home-screen">
        <div className="center-box">
          <Container>
            <div className="center-box">
              <div className="home-text1">Get a Quote</div>
              <br />
              <Row>
                <Col sm="5" md="5" xs="12">
                  <div className="home-text2">
                    Lorem ipsum request a quotation for your shipment
                  </div>
                </Col>
              </Row>
              <br />
              <Form onFinish={navTrack}>
                <Row>
                  <Col sm="5" md="5" xs="12">
                    <Form.Item>
                      <Input.Group compact>
                        <Form.Item
                          name={["address", "province"]}
                          noStyle
                          rules={[
                            { required: true, message: "Province is required" },
                          ]}
                        >
                          <Select placeholder="Code" style={styles.select}>
                            <Select.Option value="Zhejiang">+91</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={["address", "street"]}
                          noStyle
                          rules={[
                            { required: true, message: "Street is required" },
                          ]}
                        >
                          <Input
                            style={styles.input1}
                            placeholder="Mobile Number"
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col sm="3" md="3" xs="7">
                    <Form.Item>
                      <Input style={styles.input} placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col sm="2" md="2" xs="5">
                    <Form.Item>
                      <Button
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        icon={<MdArrowForward style={styles.btnIcon} />}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <br />
              <Row>
                <Col sm="5" md="5" xs="12">
                  <div className="home-text3">
                    Mauris non venenatis justo. Nam fermentum lacinia dui id
                    elementum. Donec vestibulum ligula non sagittis
                    accumsan.Nullam lacinia fermentum purus. Aliquam sed quam
                    egestas,
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GetQuoteScreen;
