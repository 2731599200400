
const styles = {
  input: {
    height: 40,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Arial",
  },
  input2: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Arial",
  },
  FormItem: {
    margin: "-5px 0px",
  },
  approvedStatus: {
    backgroundColor: "rgb(216 245 225 / 48%)",
    border: "1px solid rgb(108 203 136)"
  },
  openStatus: {
    backgroundColor: "rgb(249 238 202 / 64%)",
    border: "1px solid rgb(205 183 117)"
  },
  declinedStatus: {
    backgroundColor: "rgb(253 229 231 / 80%)",
    border: "1px solid rgb(223 149 149)"
  },
  historyStatus:{
    width: "80px",
    textAlign: "center",
  }

};

export default styles;
