import styles from "./styles";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Button } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { Form } from "antd";
import { MdOutlineLock, MdPersonAddAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const AuthScreen = () => {
  const navigate = useNavigate();
  const LoginSelect = () => {
    navigate("/login");
  };
  const RegisterSelect = () => {
    navigate("/register");
  };
  return (
    <div>
      <Header />
      <div className="home-screen">
        <div className="center-box">
          <Container>
            <Row>
              <Col sm="5" md="6" xs="12">
                <div className="center-box">
                  {/* <div className="home-text1">User Account</div> */}
                  <br />
                  <div className="home-text2">
                    Login
                    {/* to manage <br /> your shipment. */}
                  </div>
                  <br />
                  <br />
                  <Form layout="inline">
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        block
                        icon={<MdOutlineLock style={styles.btn_Icon} />}
                        onClick={() => LoginSelect()}
                      >
                        Sign In
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        size="large"
                        block
                        ghost
                        type="primary"
                        icon={<MdPersonAddAlt style={styles.btn_Icon} />}
                        onClick={() => RegisterSelect()}
                      >
                        Register Now
                      </Button>
                    </Form.Item>
                  </Form>
                  <br /> <br />
                  {/* <div className="home-text3">
                    Mauris non venenatis justo. Nam fermentum lacinia dui id
                    <br />
                    elementum. Donec vestibulum ligula non sagittis accumsan.
                    <br /> Nullam lacinia fermentum purus. Aliquam sed quam
                    egestas,
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AuthScreen;
