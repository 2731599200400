import axios from "axios";
import API from "../Config/API";
import { USER_ROLE } from "../Enum/user-role-enum";

let tenantIdentityUrl = API.REACT_APP_TENANT_IDENTITY_URL;
let userPortalKey = API.REACT_APP_USER_PORTAL_KEY;

const getLoginUserPoolInformation = async (email) => {
  let apiURL = `${tenantIdentityUrl}/tenantIdentity/getLoginUserPoolInformation/?email=${email}`;

  return new Promise(function (resolve, reject) {
    axios
      .get(apiURL)
      .then((response) => {
        console.log('getLoginUserPoolInformation response', response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log('getLoginUserPoolInformation error', error);
        reject(error.response.data);
      });
  });
};

const getPoolInformation = async (tenantGuid) => {
  try {
    let apiURL = `${tenantIdentityUrl}/tenantIdentity/getTenantByGuid/${tenantGuid}`;

    return new Promise((resolve, reject) => {
      axios.get(apiURL).then((response) => {
        console.log('getPoolInformation response', response);
        resolve(response.data.dataObject[0]);
      }).catch((error) => {
        console.log('getPoolInformation error', error);
        reject(error.response.data);
      });
    });
  } catch (error) {
    console.log('getPoolInformation error', error);
  }
}

const resendTemporaryPasswordWithoutLogin = async (inputData) => {
  let apiURL = `${tenantIdentityUrl}/tenantIdentity/resendTemporaryPasswordWithoutLogin`;
  return new Promise((resolve, reject) => {
    axios.post(apiURL, inputData).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      console.log('resendTemporaryPasswordWithoutLogin error', error);
      reject(error.response.data);
    });
  });
}

const registerUserPortalUser = async (fullName, email) => {
  let apiURL = `${tenantIdentityUrl}/tenantIdentity/registerUserPortalUser`;
  let inputData = {
    'fullName': fullName,
    'email': email,
    'userRole': USER_ROLE.ROLE_CUSTOMER,
    'userPortalKey': userPortalKey
  };

  return new Promise((resolve, reject) => {
    axios.post(apiURL, inputData).then((response) => {
      console.log('registerUserPortalUser response', response);
      resolve(response.data);
    }).catch((error) => {
      console.log('registerUserPortalUser error', error.response);
      reject(error.response.data);
    });
  });
}

export { getLoginUserPoolInformation, getPoolInformation, resendTemporaryPasswordWithoutLogin, registerUserPortalUser };
