import react, { useState } from "react";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { generateData } from "./data";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const DataTable = () => {
  const navigate = useNavigate();
  const data = generateData(1000);

  return (
    <div className="">
      <DataGrid
        id="gridContainer"
        className="datagrid-height"
        dataSource={data}
        keyExpr="id"
        showRowLines={true}
        showColumnLines={false}
        rowAlternationEnabled={false}
        rtlEnabled={false}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        {/* <Selection mode="multiple" /> */}
        <Column
          dataField="id"
          headerCellRender={() => <div className="DataTable-Header">ID</div>}
          width={50}
        />

        <Column
          dataField="firstName"
          headerCellRender={() => (
            <i className="DataTable-Header">First Name</i>
          )}
        />
        <Column
          dataField="lastName"
          headerCellRender={() => <i className="DataTable-Header">Last Name</i>}
        />
        <Column
          dataField="gender"
          headerCellRender={() => <i className="DataTable-Header">Gender</i>}
        />
        <Column
          dataField="birthDate"
          headerCellRender={() => (
            <i className="DataTable-Header">Birth Date</i>
          )}
        />

        <Column
          dataField="sourceAgent"
          headerCellRender={() => (
            <i className="DataTable-Header">Source Agent</i>
          )}
        />

        <Column
          dataField="destinationAgent"
          headerCellRender={() => (
            <i className="DataTable-Header">Destination Agent</i>
          )}
        />
        <Column
          dataField="action"
          headerCellRender={() => (
            <i className="DataTable-Header">Action</i>
          )}
          cellRender={() => (
            <div className="datagrid-action-cell">
              <span className="datagrid-action-icon">
                <MdOutlinePictureAsPdf
                  size={18}
                  fontWeight={900}
                />
              </span>
              <span className="datagrid-action-content">
                View / Download
              </span>
            </div>
          )}
        />
      </DataGrid>
    </div>
  );
};
export default DataTable;
