export enum USER_ROLE {
    ROLE_SYSADMIN = 'SystemAdmin',
    ROLE_TENANT_ADMIN = 'TenantAdmin',
    ROLE_BRANCH_ADMIN = 'BranchAdmin',
    ROLE_EMPLOYEE = 'Employee',
    ROLE_CUSTOMER = 'Customer',
    ROLE_VENDOR = 'Vendor',
    ROLE_USER = 'User'
}

export enum USER_STATUS {
    CONFIRMED = 'CONFIRMED',
    FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
    UNCONFIRMED = 'UNCONFIRMED'
}

