import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const HeaderItem = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const nav = (route: any) => {
    navigate(route);
  };

  return (
    <div
      className={
        location.pathname === props.route ? "Header-txt active" : "Header-txt"
      }
      onClick={() => nav(props.route)}
    >
      <div>{props.name}</div>
    </div>
  );
};

export default HeaderItem;
