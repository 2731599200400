import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import styles from "./styles";
import { Button } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const HomeScreen = () => {
  let navigate = useNavigate();

  const navTrack = (value: any) => {
    navigate("/track");
  };

  return (
    <div>
      <Header />
      <div className="home-screen">
        <div className="center-box">
          <Container>
            <Row>
              <Col sm="6" md="6" xs="12">
                <div className="center-box">
                  <div className="home-text1">Track Shipment</div>
                  <br />
                  <div className="home-text2" style={styles.hometxt1}>
                    Lorem ipsum updates on your shipment.
                  </div>
                  <Row>
                    <Col sm="12" md="12" xs="12">
                      <Form onFinish={navTrack}>
                        <Row>
                          <Col sm="7" md="7" xs="8">
                            <Form.Item
                              name="trackingID"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input tracking ID",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Tracking ID"
                                style={styles.input}
                                size="small"
                              />
                            </Form.Item>
                          </Col>
                          <Col sm="3" md="3" xs="4">
                            <Form.Item>
                              <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                block
                                icon={
                                  <AiOutlineSearch style={styles.btnIcon} />
                                }
                              >
                                Track
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  <br />
                  <div className="home-text3">
                    Mauris non venenatis justo. Nam fermentum lacinia dui id
                    elementum. Donec vestibulum ligula non sagittis
                    accumsan.Nullam lacinia fermentum purus. Aliquam sed quam
                    egestas,
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomeScreen;
