import axios from "axios";
import API from "../Config/API";
import { SearchOperationBuilder, SearchParam } from 'wherecomposer/dist/SearchParam';
import { ConditionCreator } from 'wherecomposer/dist/ConditionCreator';
import { ApplicationStatusNameEnum } from "../Config/default-value-enum";
import { getUserSelectedLanguage, } from "./cognito-token-service";

let REACT_APP_OPEN_STATUS = process.env.REACT_APP_OPEN_STATUS;
let REACT_APP_CANCELED_STATUS = process.env.REACT_APP_CANCELED_STATUS;
let REACT_APP_COMPLETED_STATUS = process.env.REACT_APP_CLOSED_COMPLETED_STATUS;


const getToken = async () => {

  let idToken = localStorage.getItem('authenticationToken');
  return idToken;
};

const getDashboardSummary = async () => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let lstobjSearchParam = [];
    let objCreator = new ConditionCreator();
    let result = objCreator.getSearchCondition(lstobjSearchParam);
    let whereObj = { OperationList: result.toJsonString() };

    let apiURL = API.APPLICATION_URL + '/DocumentJob/SearchDashboardSummary';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, whereObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getDashboardSummary error", error);
  }
};

const getDocumentJobPagingFromPortal = async (selectedType) => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let lstobjSearchParam = [];
    let objWhere = {};

    if (selectedType === ApplicationStatusNameEnum.ACTIVE) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_OPEN_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
      let objCreator = new ConditionCreator();
      objWhere = objCreator.getSearchCondition(lstobjSearchParam).toJsonString();
      // objWhere = {
      //   "NotIn": {
      //     "Name": "DocumentStatusGuid",
      //     "Type": "Guid",
      //     "Values": [REACT_APP_CANCELED_STATUS, REACT_APP_COMPLETED_STATUS]
      //   }
      // }
    } else if (selectedType === ApplicationStatusNameEnum.CANCELLED) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_CANCELED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
      let objCreator = new ConditionCreator();
      objWhere = objCreator.getSearchCondition(lstobjSearchParam).toJsonString();
    } else if (selectedType === ApplicationStatusNameEnum.COMPLETED) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_COMPLETED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
      let objCreator = new ConditionCreator();
      objWhere = objCreator.getSearchCondition(lstobjSearchParam).toJsonString();
    }


    let applicationViewModelObj = {
      "Where": { "OperationList": objWhere },
      "IsFull": true,
      "RequestedPage": 1,
      "PageSize": 25
    };

    let apiURL = API.APPLICATION_URL + '/DocumentJob/SearchDocumentJobPagingFromPortal';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      }
    };

    console.log(JSON.stringify(applicationViewModelObj));

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, applicationViewModelObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getDocumentJobPagingFromPortal error", error);
  }
};

const getDocumentJobPagingFromPortalByWhere = async (objWhere, i) => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let applicationViewModelObj = {
      "Where": { "OperationList": objWhere },
      "IsFull": true,
      "RequestedPage": i,
      "PageSize": 25
    };

    console.log('applicationViewModelObj', applicationViewModelObj);
    
    let apiURL = API.APPLICATION_URL + '/DocumentJob/SearchDocumentJobPagingFromPortal';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      }
    };

    return new Promise(async (resolve, reject) => {
      await axios
        .post(apiURL, applicationViewModelObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          console.log('error',error);
          reject(error.message);
        });
    });
  } catch (error) {
    console.log("getDocumentJobPagingFromPortal error", error);
  }
};

const getDocumentJobPagingFromPortalByRequestPage = async (selectedType, requestedPage) => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let lstobjSearchParam = [];

    if (selectedType === ApplicationStatusNameEnum.ACTIVE) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_OPEN_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
    } else if (selectedType === ApplicationStatusNameEnum.CANCELLED) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_CANCELED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
    } else if (selectedType === ApplicationStatusNameEnum.COMPLETED) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_COMPLETED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
    }
    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(lstobjSearchParam);

    let applicationViewModelObj = {
      "Where": { "OperationList": objWhere.toJsonString() },
      "IsFull": true,
      "RequestedPage": requestedPage,
      "PageSize": 25
    };

    let apiURL = API.APPLICATION_URL + '/DocumentJob/SearchDocumentJobPagingFromPortal';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      }
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, applicationViewModelObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getDocumentJobPagingFromPortal error", error);
  }
};

const getDocumentJobPagingFromAdvanceSearch = async (applicationViewModelObj) => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let apiURL = API.APPLICATION_URL + '/DocumentJob/SearchDocumentJobPagingFromPortal';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      }
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, applicationViewModelObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          console.log("getDocumentJobPagingFromPortal err", error.response.data);
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getDocumentJobPagingFromPortal error", error);
  }
};

const getDocumentJobFromPortal = async (documentJobGuid) => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let objSearchParam = new SearchParam();
    let lstobjSearchParam = [];

    objSearchParam.searchParamCreate("DocumentJobGuid", "Guid", documentJobGuid, SearchOperationBuilder.create(true, false, false));
    lstobjSearchParam.push(objSearchParam);

    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(lstobjSearchParam);


    let applicationViewModelObj = {
      "Where": { "OperationList": objWhere.toJsonString() },
      "IsFull": true,
    };

    let apiURL = API.APPLICATION_URL + '/DocumentJob/SearchDocumentJobFromPortal';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      }
    };

    return await new Promise((resolve, reject) => {
      axios
        .post(apiURL, applicationViewModelObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.message);
        });
    });
  } catch (error) {
    console.log("getDocumentJobPagingFromPortal error", error);
  }
};

const getDocumentCategory = async () => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let lstobjSearchParam = [];
    let objSearchParam = new SearchParam();
    objSearchParam.searchParamCreate("LanguageGuid", "Guid", languageGuid, SearchOperationBuilder.create(true, false, false));
    lstobjSearchParam.push(objSearchParam);
    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(lstobjSearchParam);
    let documentCategoryViewModelObj = {
      "Where": { "OperationList": objWhere.toJsonString() },
      "IsNeedDocumentType": true
    }

    let apiURL = API.APPLICATION_URL + '/DocumentCategory/SearchDocumentCategory';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, documentCategoryViewModelObj, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          console.log("getDocumentCategory err", error.response.data);
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getDocumentCategory error", error);
  }
}

const addDocumentJobFromPortal = async (inputData) => {
  let storedArray = getUserSelectedLanguage();
  const languageGuid = storedArray.LanguageGuid;
  const languageCode = storedArray.Code;

  let apiURL = API.APPLICATION_URL + '/DocumentJob/AddDocumentJobFromPortal';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "TokenAuthorization": token,
      "LanguageGuid": languageGuid,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(apiURL, inputData, headerParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        console.log("addDocumentJobFromPortal err", error.response.data);
        reject(error.response.data);
      });
  });
}

const getS3UploadUrl = async (fileName) => {
  let apiURL = API.APPLICATION_URL + '/DocumentJob/GetS3UploadUrl';
  let queryParam = {
    params: {
      bucketKey: fileName,
    },
  }

  return new Promise((resolve, reject) => {
    axios
      .get(apiURL, queryParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        console.log("getS3UploadUrl err", error.response.data);
        reject(error.response.data);
      });
  });
}

const uploadDocumentToS3 = async (apiURL, buffer) => {
  let headerParam = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*"
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .put(apiURL, buffer, headerParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        console.log("uploadDocumentToS3 err", error.response.data);
        reject(error.response.data);
      });
  });
}

const getDocumentStatus = async () => {
  try {
    let storedArray = getUserSelectedLanguage();
    const languageGuid = storedArray.LanguageGuid;
    const languageCode = storedArray.Code;

    let lstobjSearchParam = [];
    let objSearchParam = new SearchParam();
    objSearchParam.searchParamCreate("LanguageGuid", "Guid", languageGuid, SearchOperationBuilder.create(true, false, false));
    lstobjSearchParam.push(objSearchParam);
    let objCreator = new ConditionCreator();
    let result = objCreator.getSearchCondition(lstobjSearchParam);
    let objWhere = { "OperationList": result.toJsonString() };

    let apiURL = API.APPLICATION_URL + '/DocumentStatus/SearchDocumentStatus';
    let token = await getToken();
    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "TokenAuthorization": token,
        "LanguageGuid": languageGuid,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, objWhere, headerParam)
        .then((response) => {
          resolve(response.data);
        })
        .catch(error => {
          console.log("getDocumentStatus err", error.response.data);
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getDocumentStatus error", error);
  }
}

export {
  getDashboardSummary, getDocumentJobPagingFromPortal, getDocumentCategory, getDocumentJobFromPortal, getDocumentJobPagingFromAdvanceSearch, addDocumentJobFromPortal,
  getS3UploadUrl, uploadDocumentToS3, getDocumentStatus, getDocumentJobPagingFromPortalByRequestPage,
  getDocumentJobPagingFromPortalByWhere
};
