import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import LOGO from "../Assets/Images/BrandLogo.svg";
import LOGO from "../Assets/Images/logo.png";
import { Drawer } from "antd";
import { MdMoreVert, MdOutlineClear } from "react-icons/md";
import HeaderItem from "./HeaderItem";
const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const nav = () => {
    navigate("/");
  };

  const Item = () => {
    return (
      <>
        {/* <HeaderItem name={"Track Shipment"} route={"/home"} /> */}
        {/* <HeaderItem name={"Get a Quote"} route={"/qoute"} /> */}
        <HeaderItem name={"User Account"} route={"/auth"} />
      </>
    );
  };

  return (
    <div className="Header">
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col sm="7" xs="10">
            <div className="Header-Box1">
              <div onClick={nav}>
                <img src={LOGO} className="Header-logo" />
              </div>
              <div style={{ margin: 5 }}></div>
              <div>
                <div className="Header-txt1">AFRICA UNION CARGO</div>
                <div className="Header-txt2">NAMIBIA</div>
              </div>
            </div>
          </Col>
          <Col sm="5" xs="2">
            <div className="Header-Box3" onClick={() => setOpen(true)}>
              <MdMoreVert />
            </div>
            <div className="Header-Box2" style={{ justifyContent: "flex-end" }}>
              <Item />
            </div>
          </Col>
        </Row>
      </Container>
      <Drawer
        placement={"right"}
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        key={"right"}
        width={280}
      >
        <div className="Header-Box4">
          <div style={{ margin: 15 }} onClick={() => setOpen(false)}>
            <MdOutlineClear size={30} />
          </div>
        </div>
        <Item />
      </Drawer>
    </div>
  );
};

export default Header;
