import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Card } from "antd";
import { Container } from "react-bootstrap";

const TrackScreen = () => {
  return (
    <div>
      <Header />
      <div className="home-screen">
        <br />
        <Container fluid={false}>
          <div className="page-header-box">
            <div>Track Shipment</div>
          </div>
          <Card
            style={{ borderRadius: 5, padding: 10 }}
            title={
              <div>
                <div className="Formtxt1">Timeline</div>
                <div className="Formtxt2">Tracking ID : SHP2678236678</div>
              </div>
            }
          >
            <div className="trackBox">
              <div>
                <div className="trackBox-dot-active"></div>
                <div className="trackBox-box-active">
                  <div className="trackBox-box-content">
                    <div className="Formtxt6">Origin Statement</div>
                    <div className="Formtxt5">Lorem ipsum dolor sit</div>
                    <div className="Formtxt5">12 Dec 2022</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="trackBox-dot-active"></div>
                <div className="trackBox-box">
                  <div className="trackBox-box-content">
                    <div className="Formtxt6">Origin Statement</div>
                    <div className="Formtxt5">Lorem ipsum dolor sit</div>
                    <div className="Formtxt5">12 Dec 2022</div>
                    <div className="trackBox-box-content-sub">
                      <div className="Formtxt8">Origin Statement</div>
                      <div className="Formtxt9">Lorem ipsum dolor sit</div>
                      <div className="Formtxt9">12 Dec 2022</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="trackBox-dot"></div>
          </Card>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default TrackScreen;
