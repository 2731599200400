import axios from "axios";
import { Auth } from "aws-amplify";
import API from "../Config/API";
import { SearchOperationBuilder, SearchParam } from 'wherecomposer/dist/SearchParam';
import { ConditionCreator } from 'wherecomposer/dist/ConditionCreator';
import { MenuShortNameEnum } from '../Config/default-value-enum';
import { getUserSelectedLanguage } from "./cognito-token-service";
import { getTokenDetailsByDecoding } from "./cognito-token-service";
import { BusinessNote } from "../Config/business-note-entity";
import { v4 as uuidv4 } from 'uuid';

const getToken = async () => {
  let idToken = localStorage.getItem('authenticationToken');
  return idToken;
};


const searchAttachmentNotes = async (documentAttachmentGuid: string) => {

  let storedArray = getUserSelectedLanguage();
  const languageGuid = storedArray.LanguageGuid;
  const languageCode = storedArray.Code;
  let lstobjSearchParam = [];

  let objReferenceGuidSearchParam = new SearchParam();
  let objReferenceTypeSearchParam = new SearchParam();

  objReferenceGuidSearchParam.searchParamCreate("BusinessEntity", "string", MenuShortNameEnum.DOCUMENT_ATTACHMENT, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceGuidSearchParam);

  objReferenceTypeSearchParam.searchParamCreate("BusinessEntityGuid", "Guid", documentAttachmentGuid, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceTypeSearchParam);


  let objCreator = new ConditionCreator();
  let objWhere = objCreator.getSearchCondition(lstobjSearchParam);


  let searchParamObj = {
    "OperationList": objWhere.toJsonString(),
  };

  let apiURL = API.ATTACHMENT_URL + '/BusinessNote/SearchBusinessNote';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
      'LanguageGuid': languageGuid
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, searchParamObj, headerParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.message);
      });
  });

};

const getUnReadMessageCount = async (documentAttachmentGuid: string) => {

  let storedArray = getUserSelectedLanguage();
  const languageGuid = storedArray.LanguageGuid;
  const languageCode = storedArray.Code;
  let lstobjSearchParam = [];

  let objReferenceGuidSearchParam = new SearchParam();
  let objReferenceTypeSearchParam = new SearchParam();
  let objFromPortalSearchParam = new SearchParam();

  objReferenceGuidSearchParam.searchParamCreate("BusinessEntity", "string", MenuShortNameEnum.DOCUMENT_ATTACHMENT, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceGuidSearchParam);

  objReferenceTypeSearchParam.searchParamCreate("BusinessEntityGuid", "Guid", documentAttachmentGuid, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceTypeSearchParam);

  objFromPortalSearchParam.searchParamCreate("IsFromPortal", "Boolean", false, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objFromPortalSearchParam);


  let objCreator = new ConditionCreator();
  let objWhere = objCreator.getSearchCondition(lstobjSearchParam);


  let searchParamObj = {
    "OperationList": objWhere.toJsonString(),
  };

  let apiURL = API.ATTACHMENT_URL + '/BusinessNote/GetTotalUnReadedBusinessNoteCount';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
      'LanguageGuid': languageGuid
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, searchParamObj, headerParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.message);
      });
  });

};

const createAttachmentNote = async (documentAttachmentNote: BusinessNote) => {

  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;
  const languageGuid = storedArray.LanguageGuid;

  let decodedToken = getTokenDetailsByDecoding();
  let locationGuid = decodedToken?.locationGuid;
  let tenantUserName = decodedToken?.tenantUserName;

  let apiURL = API.ATTACHMENT_URL + '/BusinessNote/CreateBusinessNote';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };

  documentAttachmentNote.LocationId = locationGuid;
  documentAttachmentNote.IsFromPortal = true;
  documentAttachmentNote.BusinessEntity = MenuShortNameEnum.DOCUMENT_ATTACHMENT;
  documentAttachmentNote.LanguageGuid = languageGuid;
  documentAttachmentNote.LanguageGroupGuid = uuidv4();

  console.log(JSON.stringify(documentAttachmentNote));

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, [documentAttachmentNote], headerParam)
      .then((response) => {
        console.log("searchAttachment response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("searchAttachment err", error.response.data);
        reject(error.message);
      });
  });
};


const updateBusinessNoteReadStatus = async (documentAttachmentNoteList: [string]) => {

  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  let apiURL = API.ATTACHMENT_URL + '/BusinessNote/MarkBusinessNoteReadByGroupID';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .put(apiURL, documentAttachmentNoteList, headerParam)
      .then((response) => {
        console.log("searchAttachment response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("searchAttachment err", error.response.data);
        reject(error.message);
      });
  });
};

const getTotalUnReadedBusinessNoteCount = async (searchParameter: any) => {

  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  let apiURL = API.APPLICATION_URL + '/DocumentJob/GetTotalUnReadedBusinessNoteCountFromPortal';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "TokenAuthorization": token,
    }
  };

  console.log('searchParameter', searchParameter);

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, searchParameter, headerParam)
      .then((response) => {
        console.log("searchAttachment response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("searchAttachment err", error.response.data);
        reject(error.message);
      });
  });
};


export { searchAttachmentNotes, createAttachmentNote, getUnReadMessageCount, updateBusinessNoteReadStatus, getTotalUnReadedBusinessNoteCount };
