import react, { useState, useEffect } from "react";
import { Card, Form, Input } from "antd";
import User from "../../Assets/Images/user-avatar.png";
import { Row, Col } from "react-bootstrap";
import { getTokenDetailsByDecoding } from "../../Services/cognito-token-service";

const ProfileCard = (props: any) => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    bindLoginUserData();

  }, []);

  const bindLoginUserData = () => {
    let decodedToken = getTokenDetailsByDecoding();

    if (decodedToken) {
      setUserName(decodedToken.tenantUserName);
    }
  }

  return (
    <Card
      style={{ borderRadius: 5, padding: 10 }}
      title={
        <div>
          <div className="Formtxt1">Profile Details</div>
          <div className="Formtxt2">Business informations</div>
        </div>
      }
    >
      <Row>
        <Col sm="4" md="4" xs="4">
          <img src={User} style={{ width: 100 }} />
        </Col>
        {/* <Col sm="5" md="5" xs="6">
          <br />
          <Button type="primary" size="small" block ghost>
            Change Picture
          </Button>
        </Col> */}
      </Row>
      <br />
      <Form>
        <Form.Item>
          <div
            className="NewApplication-txt10"
            style={{ marginBottom: "2.19%" }}
          >
            Full Name
          </div>
          <Input size="small" placeholder="Full Name" readOnly={true} name="CustomerName" value={userName} />
        </Form.Item>
        {/* <Form.Item>
          <div
            className="NewApplication-txt10"
            style={{ marginBottom: "2.19%" }}
          >
            Business Name
          </div>
          <Input size="small" placeholder="Business Name" />
        </Form.Item>
        <Form.Item>
          <div
            className="NewApplication-txt10"
            style={{ marginBottom: "2.19%" }}
          >
            Administrator
          </div>
          <Select size="small" placeholder="Administrator">
            <Select.Option>Administrator</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="small" style={styles.btn}>
            Update
          </Button>
        </Form.Item> */}
      </Form>
    </Card>
  );
};

export default ProfileCard;
