import React from "react";
import { MdSettings } from "react-icons/md";
import { MdRedo } from "react-icons/md";
import { Popup, Position } from "devextreme-react/popup";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { clearLocalStorage } from "../Services/cognito-token-service";


function UserPopup(props: any) {
  const navigate = useNavigate();
  const nav = () => {
    navigate("/auth/account");
  };

  const logout = async () => {
    await clearLocalStorage();
    await Auth.signOut();
    navigate("/");
  };
  return (
    <Popup
      height={100}
      width={240}
      visible={props.visible}
      hideOnOutsideClick={true}
      contentRender={() => (
        <div className="UserPopup">
          <div className="UserPopup-item" onClick={() => nav()}>
            <div className="UserPopup-icon">
              <MdSettings />
            </div>
            <div className="UserPopup-text">Manage Account</div>
          </div>
          <div className="UserPopup-item" onClick={() => logout()}>
            <div className="UserPopup-icon">
              <MdRedo />
            </div>
            <div className="UserPopup-text">Sign Out</div>
          </div>
        </div>
      )}
      showTitle={false}
      onHiding={() => props.close()}
    >
      <Position my="right top" at="right" of="#userIcon" />
    </Popup>
  );
}

export default UserPopup;
