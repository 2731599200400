const styles = {
  btn1: {
    paddingLeft: 50,
    paddingRight: 50,
    background: "#4ACB70",
    color: "#fff",
    border: 0,
    fontSize: "13px",
  },
  Select: {
    width: "50%",
    fontSize: "12px",
    color: "#AAAAAA",
  },
};
export default styles;
