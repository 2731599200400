import React, { useState } from "react";
import DataTable from "./DataTable";
import { AiOutlineExport } from "react-icons/ai";
import { VscDiffAdded } from "react-icons/vsc";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Tooltip } from "antd";
import SearchPopup from "./SearchPopup";
import { MdManageSearch } from "react-icons/md";
const ShipmentScreen = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const searchPopup = () => {
    setIsPopupVisible(true);
  };
  return (
    <div className="Screen-Container">
      <div className="Screen-Header">
        <div className="Screen-Headertxt1">Shipments</div>
        <div className="Header-Box1">
          <div>
            <Button
            className="btn-advancesearch"
              type="link"
              size="large"
              icon={
                <AiOutlineExport
                  size={18}
                  style={{ marginRight: 10, marginBottom: 5 }}
                />
              }
            >
              Export To Excell
            </Button>
          </div>
          <div>
            <Button
              className="btn-advancesearch"
              type="link"
              size="large"
              icon={
                <MdManageSearch
                  size={18}
                  style={{ marginRight: 10, marginBottom: 5 }}
                />
              }
              onClick={() => searchPopup()}
            >
              Advance Search
            </Button>
            <SearchPopup
              visible={isPopupVisible}
              close={() => setIsPopupVisible(!isPopupVisible)}
            />
          </div>
        </div>
      </div>
      <div className="Screen-box">
        <DataTable />
      </div>
    </div>
  );
};
export default ShipmentScreen;
