import react, { useState, useEffect } from "react";
import { Tag } from "antd";
import DataGrid, {
  Column,
  Selection,
  Paging, Pager
} from "devextreme-react/data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { RxDotsVertical } from "react-icons/rx";

function DataTable(props: any) {

  const [data, setDocumetTypeGuid] = useState('');
  const navigate = useNavigate();
  const pageSizes = [25, 50, 100];
  const currentPageSize = 25;

  const parentToChild = () => {
    setDocumetTypeGuid("This is data from Parent Component to the Child Component.");
  }

  useEffect(() => {
  }, []);

  const onRowDblClick = async (e: any) => {
    let rowData = e.data;

    navigate('/auth/application-details', { state: { documentTypeGuid: rowData.DocumentJobGuid } });
  }

  return props.data ? (
    <div className="">
      <DataGrid
        id="gridContainer"
        className="datagrid-height"
        dataSource={props.data}
        keyExpr="DocumentJobGuid"
        showRowLines={true}
        showColumnLines={false}
        rowAlternationEnabled={false}
        rtlEnabled={false}
        columnHidingEnabled={false}
        columnAutoWidth={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        onRowDblClick={onRowDblClick}
      >
        <Selection mode="multiple" />

        <Column
          dataField="DocumentJobID"
          headerCellRender={() => <div className="DataTable-Header">Code</div>}
          width={50}
          cellRender={(data, text) => {
            return (
              <div>
                <div style={{ margin: "25%" }} />
                <div>{data.data?.DocumentJobID}</div>
              </div>
            );
          }}
        />

        <Column
          dataField="DocumentJobNo"
          headerCellRender={() => (
            <div className="DataTable-Header">Application No</div>
          )}
          cellRender={(data, text) => {
            return (
              <div>
                <div style={{ margin: "5%" }} />
                <div>{data.data?.DocumentJobNo}</div>
              </div>
            );
          }}
        />

        <Column
          dataField="DocumentCategory"
          headerCellRender={() => <i className="DataTable-Header">Category</i>}
          cellRender={(data, text) => {
            return (
              <div>
                <div style={{ margin: "5%" }} />
                <div>
                  {data.data?.DocumentCategory &&
                    data.data?.DocumentCategory.DisplayName}
                </div>
              </div>
            );
          }}
        />
        <Column
          dataField="CreatedDate"
          headerCellRender={() => (
            <i className="DataTable-Header">Date Submitted</i>
          )}
          cellRender={(data, text) => {
            return (
              <div>
                <div style={{ margin: "5%" }} />
                <div>
                  {moment(data.data?.CreatedDate).format("DD MMMM YYYY hh:mm:ss")}
                </div>
              </div>
            );
          }}
        />
        <Column
          dataField="applicant"
          allowSorting="true"
          headerCellRender={() => <i className="DataTable-Header">Applicant</i>}
          cellRender={(data, text) => {
            return (
              <div>
                <div style={{ margin: "5%" }} />
                <div>{data.data?.Customer?.DocumentJobCustomerName}</div>
              </div>
            );
          }}
        />
        < Column
          dataField="uploaded_documents"
          headerCellRender={() => (
            <i className="DataTable-Header">Uploaded Documents</i>
          )}
          cellRender={(data, text) => {
            return (
              <div>
                <div>{data.data?.InboundDocumentsCount}</div>
                <Link to="/auth/application-details" state={{ documentTypeGuid: data.data.DocumentJobGuid }}>
                  <div className="DataTable-Link">View All</div>
                </Link>
              </div>
            );
          }}
        />
        < Column
          dataField="recieved_documents"
          headerCellRender={() => (
            <i className="DataTable-Header">Recieved Documents</i>
          )}
          cellRender={(data, text) => {
            return (
              <div>
                <div>{data.data?.OutboundDocumentsCount}</div>
                <Link to="/auth/application-details" state={{ documentTypeGuid: data.data.DocumentJobGuid }}>
                  <div className="DataTable-Link">View All</div>
                </Link>
                {/* <Link to={"/auth/application-details"}>
                  <div className="DataTable-Link">View All</div>
                </Link> */}
              </div>
            );
          }}
        />
        {/* < Column
          dataField="status"
          headerCellRender={() => <i style={{ textAlign: "left" }} className="DataTable-Header">Status</i>}
          alignment={"left"}
          cellRender={(data, text, index) => {
            let borderColor = `1px solid ${data.data?.DocumentStatus?.BorderColor}`;
            let fontColor = data.data?.DocumentStatus?.BorderColor;
            return (
              <div style={{ textAlign: "left" }}>
                <Tag
                  color={data.data?.DocumentStatus?.BackgroundColor}
                  style={{ fontSize: "10px", border: borderColor, color: fontColor }}
                >
                  {data.data?.DocumentStatus?.Name}
                </Tag>
              </div>
            );
          }}
        /> */}
         <Column
          dataField="status"
          headerCellRender={() => <i className="DataTable-Header">Status</i>}
          alignment={"center"}
          cellRender={(data, text, index) => {
            let borderColor = `1px solid ${data.data?.DocumentStatus?.BorderColor}`;
            let fontColor = data.data?.DocumentStatus?.BorderColor;
            return (
              <div>
                <div style={{ margin: "4%" }} />
                <Tag
                  color={data.data?.DocumentStatus?.BackgroundColor}
                  style={{ fontSize: "10px", border: borderColor, color: fontColor }}
                >
                  {data.data?.DocumentStatus?.Name}
                </Tag>
              </div>
            );
          }}
        />
        <Column
          dataField="totalUnReadedNoteCount"
          headerCellRender={() => <RxDotsVertical className="Screen-HeaderIcon1 text_align_right" size={21} color="#026d3c"></RxDotsVertical>}
          alignment="right"
          cellRender={(data, text) => {
            return (
              <div className="text_align_right">
                {data.data?.TotalUnReadedNoteCount > 0 ? (
                  <div>
                    <div className="table_message_count_circle text_align_right">{data.data?.TotalUnReadedNoteCount}</div>
                  </div>

                ) : (<></>)
                }
              </div>

            );
          }}
        />
        < Paging defaultPageSize={currentPageSize} />
        <Pager
          visible={true}
          showPageSizeSelector={true}
          allowedPageSizes={pageSizes}
          showNavigationButtons={true}
          showInfo={false} />
      </DataGrid >
    </div >
  ) : null;

}

export default DataTable;
