const styles = {
  hometxt1: {
    marginBottom: 36,
  },
  input: {
    width: "100%",
    height: 48,
  },
  input1: {
    width: "70%",
    height: 48,
  },
  input3: {
    height: 40,
  },
  select: {
    width: "30%",
    height: 48,
  },
  btnIcon: {
    marginBottom: 3,
    marginRight: 10,
    fontSize: 20,
  },
};

export default styles;
