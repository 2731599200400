import { Modal, Form, message, Input, Button } from "antd";
import { useState, useEffect, useRef, createRef, useCallback } from "react";
import { searchAttachmentNotes, createAttachmentNote } from "../../Services/note-service";
import { BusinessNote } from "../../Config/business-note-entity";
import CircularProgress from "@mui/material/CircularProgress";
import { DefaultValueEnum } from "../../Config/default-value-enum";


const ChatModal = (props: any) => {

  const [documentAttachmentGuid, setDocumentAttachmentGuid] = useState('');
  const [noteHistory, setNoteHistory] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [inputChat, setChat] = useState('');
  

  const chatInput = useCallback((inputElement: any) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);


  useEffect(() => {
    setDocumentAttachmentGuid(props.props.DocumentAttachmentGuid);
    getNoteHistory(props.props.DocumentAttachmentGuid);

  }, []);

  const getNoteHistory = async (documentAttachmentGuid: string) => {
    setLoading(true);

    await searchAttachmentNotes(documentAttachmentGuid)
      .then(async function (response) {
        let attachmentNotes: any = response;

        if (attachmentNotes.length) {
          attachmentNotes.forEach((noteObj: any) => {

            if (noteObj.hasOwnProperty('ModifiedDate')) {
              noteObj["ModifiedDate"] = new Date(noteObj["ModifiedDate"]).toLocaleString();
            }
          });
        }
        setNoteHistory(attachmentNotes);
        getUnreadMessageList(attachmentNotes);
        setLoading(false);
      })
      .catch(function (error) {
        console.log('error', error);
        message.error(error);
        setLoading(false);
      });
  }

  const getUnreadMessageList = async (attachmentNotes: any) => {

    let unReadNoteGuidList = (attachmentNotes.filter(
      (note: any) => {
        return !note.IsFromPortal && !note.IsRead
      })
      .map((value: any) => value.LanguageGroupGuid));

    if (unReadNoteGuidList.length)
      props.unReadMessageCallback(unReadNoteGuidList);
  }

  const createBussinessNote = async () => {
    let attchmentNote = new BusinessNote(documentAttachmentGuid);
    attchmentNote.Description = inputChat;

    if (inputChat != null && inputChat != undefined && inputChat != DefaultValueEnum.EmptyString) {
      await createAttachmentNote(attchmentNote)
        .then(async function (response) {
          let attachmentNote: any = response;

          if (attachmentNote.length) {

            if (attachmentNote[0].hasOwnProperty('ModifiedDate')) {
              attachmentNote[0]["ModifiedDate"] = new Date(attachmentNote[0]["ModifiedDate"]).toLocaleString();
            }

            let tempNoteList = []
            tempNoteList = JSON.parse(JSON.stringify(noteHistory));
            tempNoteList.push(attachmentNote[0]);
            setNoteHistory(tempNoteList);
          }

          setChat('');
          setLoading(false);
        })
        .catch(function (error) {
          console.log('error', error);
          message.error(error);
          setLoading(false);
        });
    }
    else {
      message.error('Type a message');
    }
  }

  const onEnterChat = async (e: any) => {
    setChat(e);
  }


  return (
    <Modal
      width={650}
      title={<div className="SearchPopup-title">Communications</div>}
      open={props.visible}
      onOk={() => props.close()}
      onCancel={() => props.close()}
      footer={false}
    >
      <Form>
        <div className="height_100P">
          {loading && props.visible ? (
            <>
              <div className="spinning_box">
                <CircularProgress style={{ color: "#4acb70" }} />
                <br />
                <div>Loading . . .</div>
              </div>
            </>
          ) : (
            <div className="width_100 height_100P position_relative">
              <div className="display_flex flex_column width_100 padding_20 chat_content">

                {noteHistory?.map((item: any, i: number) => {
                  return (
                    <div>

                      {item.IsFromPortal == true ? (
                        <div className="display_flex flex_column margin_bottom_10">
                          <div className="width_100">
                            <div className="right_chat_box flex_row margin_bottom_1_5 float_right">
                              <div className="display_flex flex_row width_100 padding_15">
                                {item.Description}
                              </div>
                            </div>
                          </div>
                          <div className="width_100">
                            <div className="display_flex flex_row float_right justify_end chat_actor_name">
                              {item.TenantUserName} &nbsp;
                              {item.ModifiedDate}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="margin_bottom_10">
                          <div className="width_100">
                            <div className="left_chat_box flex_row margin_bottom_1_5" >
                              <div className="display_flex flex_row width_100 padding_15">
                                {item.Description}
                              </div>
                            </div>
                          </div>
                          <div className="width_100">
                            <div className="chat_actor_name">
                              {item.TenantUserName} &nbsp;
                              {item.ModifiedDate}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })
                }
              </div>


              <div style={{ margin: 10 }} />

              <div className="border_bottom_grey_1" />

              <div style={{ margin: 10 }} />

              <div className="display_flex flex_row width_100">
                <div className="margin_right_20 width_100">
                  <Input size="small" placeholder="Enter your message..." name="chat" value={inputChat} ref={chatInput}
                    onChange={e => onEnterChat(e.target.value)} />
                </div>
                <div className="display_flex flex_align_center">
                  <Button type="primary" htmlType="submit" onClick={() => createBussinessNote()}>
                    <span className="NewApplication-txt7">Send</span>
                  </Button>
                </div>
              </div>
            </div>
          )
          }
        </div>
      </Form >
    </Modal >
  );
};

export default ChatModal;

