import axios from "axios";
import API from "../Config/API";

let languageServiceUrl = API.REACT_APP_LANGUAGE_SERVICE_URL;

const getLanguageGuid = async (code) => {
  let apiURL = `${languageServiceUrl}/languageService/language`;

  let response = await axios.get(apiURL);
  let languageArr = response.data.dataObject;

  let languageObj = languageArr.find((obj) => {
    return obj.Code.toUpperCase() === code.toUpperCase();
  });

  return languageObj.LanguageId.toUpperCase();
};

const getAllLanguages = async (code) => {
  let apiURL = `${languageServiceUrl}/languageService/language`;

  let response = await axios.get(apiURL);
  let languageArr = response.data.dataObject;

  return languageArr;
};

const getMessages = async (messageId, languageCode) => {
  let apiURL = `${languageServiceUrl}/languageService/responseEngine`;
  let queryParam = {
    params: {
      messageId: messageId,
      languageCode: languageCode,
    },
  };

  let response = await axios.get(apiURL, queryParam);
  let message = response.data.message;
  return message;
};

const getValueMessages = async (messageId, languageCode, values) => {
  let apiURL = `${languageServiceUrl}/languageService/responseEngine`;
  let queryParam = {
    params: {
      messageId: messageId,
      languageCode: languageCode,
      values: values
    }
  }

  let response = await axios.get(apiURL, queryParam);
  let message = response.data.message;
  return message;
}

const getFieldConsoleMessages = async (messageId, languageCode, fieldsConsole) => {
  let apiURL = `${languageServiceUrl}/languageService/responseEngine`;
  let queryParam = {
    params: {
      messageId: messageId,
      languageCode: languageCode,
      fieldsConsole: JSON.stringify(fieldsConsole)
    }
  }

  let response = await axios.get(apiURL, queryParam);
  let message = response.data.message;
  return message;
}

export { getLanguageGuid, getAllLanguages, getMessages, getValueMessages, getFieldConsoleMessages };
