import LOGO from "../Assets/Images/logo.png";
import LOGO2 from "../Assets/Images/Logi-white.svg";
import DrawerItem from "./DrawerItem";
import { MdOutlineMenu } from "react-icons/md";
import { MdOutlineLocalShipping } from "react-icons/md";
import { MdOutlineAssignment } from "react-icons/md";
import { MdOutlineReceipt } from "react-icons/md";
import { MdOutlineLocalActivity } from "react-icons/md";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { TbFileDescription } from "react-icons/tb";
import { RiFileList2Line } from "react-icons/ri";

const DrawerBox = (props: any) => {
  const WidhChange = () => {
    props.onWidth();
  };
  return (
    <div className="Navigation-drawerBox">
      {props.mobileDrawer ? null : (
        <div className="Navigation-drawerBox2">
          <div onClick={() => WidhChange()}>
            <MdOutlineMenu className="Navigation-Icon1" />
          </div>
          {props.collapsed ? null : (
            <div
              style={{
                display: "flex",
              }}
            >
              <img src={LOGO} alt="My Happy SVG" className="Navigation-Logo" />
              <div style={{ margin: 3 }} />
              <div className="DrawerBox-txt1">AFRICA UNION CARGO NAMIBIA</div>
            </div>
          )}
        </div>
      )}
      <div className="Navigation-devider" />
      <div className="Navigation-drawerBox3">
        <DrawerItem
          name={"Application"}
          route={"/auth/applications"}
          Icon={<RiFileList2Line />}
          onChange={() => {}}
        />
        {/* <DrawerItem
          name={"Shipments"}
          route={"/auth/shipments"}
          Icon={<MdOutlineLocalShipping />}
          onChange={() => props.onChange()}
        />
        <DrawerItem
          name={"Jobs"}
          route={"/auth/jobs"}
          Icon={<MdOutlineBusinessCenter />}
          onChange={() => props.onChange()}
        />
        <DrawerItem
          name={"Invoices"}
          route={"/auth/invoices"}
          Icon={<MdOutlineReceipt />}
          onChange={() => props.onChange()}
        />
        <DrawerItem
          name={"Receipts"}
          route={"/auth/receipts"}
          Icon={<TbFileDescription />}
          onChange={() => props.onChange()}
        />
        <DrawerItem
          name={"Bills"}
          route={"/auth/bills"}
          Icon={<MdOutlineAssignment />}
          onChange={() => props.onChange()}
        />
        <DrawerItem
          name={"Vouchers"}
          route={"/auth/vouchers"}
          Icon={<MdOutlineLocalActivity />}
          onChange={() => props.onChange()}
        /> */}
      </div>
      <div className="Navigation-devider" />
      {props.collapsed ? null : (
        <div className="Navigation-drawerBox4">
          <div>© 2023 | All rights reserved.</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Powered By <span style={{ margin: 3 }}></span>
            <img src={LOGO2} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DrawerBox;
