import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "antd";

function RegisterSuccess() {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };
  return (
    <div>
      <Card
        style={{ borderRadius: 5, padding: 10 }}
        title={
          <div>
            <div className="Formtxt1">Response</div>
          </div>
        }
      >
        <div className="FormSuccessBox">Registered SuccessFully!</div>
        <br />
        <div className="Formtxt5">
          We'll send you Temporary Credentials to your email shortly.
        </div>
        <br />
        <div>
          <span>
            Go to{" "}
            <span className="Formtxt3" onClick={() => login()}>
              Login
            </span>{" "}
            Page
          </span>{" "}
        </div>
      </Card>
    </div>
  );
}

export default RegisterSuccess;
