import { createSlice } from "@reduxjs/toolkit";
const LanguageSlice = createSlice({
  name: "Language",
  initialState: {
    data: {},
  },
  reducers: {
    save: (state, action) => {
      state.data = action.payload;
    },
    clear: (state, action) => {
      state.data = {};
    },
  },
});

export default LanguageSlice;
export const { save, clear } = LanguageSlice.actions;
