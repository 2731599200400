const MESSAGE = {
  NO_MODIFICATION: "03386C00-B54D-11EA-B900-E989F81A2C16",
  SUCCESSFULL_REGISTERATION_LOGIN_MESSAGE: "A464B5C0-F056-11EC-A99B-F5ABDEDBC723",
  AGENCY_USER_LOGIN_ERR_MESSAGE: "13CB8120-F749-11EC-A99B-F5ABDEDBC723",
  EMAIL_ALREADY_REGISTERED: "A7E3C140-1951-11ED-80A8-5F44FEF3BFB4",
  USER_DOES_NOT_EXIST: "FB4C2130-1BE3-11ED-9819-B5188091EA18",
  PASSWORD_DO_NOT_MATCH: "C3B31300-43E8-11ED-9721-A502960E061B",
  OTP_SENT_TO_MAIL: "ED4BFF70-43F6-11ED-B575-C99D12EF6BD8",
  OTP_SENT_TO_MOBILE_NUMBER: "C16F0E10-43F6-11ED-9721-A502960E061B",
  PASSWORD_RESET_SUCCESS_MESSAGE: "A339E850-447F-11ED-9721-A502960E061B",
  VALIDATION_EXCEPTION: "71F3CE70-A641-11EA-85A7-55CDAACD69F6",
  PASSWORD_POLICY_MESSAGE: "1FA8B270-5436-11ED-B575-C99D12EF6BD8",
  USER_IS_NOT_CONFIRMED: "F76FAFC0-55CA-11ED-AB2E-5926861ADE8F",
  TEMPORARY_PASSWORD_RESEND_MESSAGE: "7D329270-4E06-11ED-9721-A502960E061B",
  CONTACT_US_SUCCESS_MESSAGE: "3F78B510-720D-11ED-8ED7-11AAF2E46724",
  RESTRICT_USERPORTAL_USER_LOGIN: "4E8D1F20-AD25-11ED-B5F0-2B1B3058D408",
  INVALID_EMAIL_MESSAGE: "94C3C9C0-B01E-11ED-B5F0-2B1B3058D408",
  INVALID_MOBILE_NUMBER_MESSAGE: "F9945C20-B02D-11ED-B5F0-2B1B3058D408",
  INCORRECT_USERNAME_OR_PASSWORD: "63646060-B294-11ED-B5F0-2B1B3058D408",
  RESTRICT_LOGIPULSE_WEB_APPLICATION_USER: "1B3168D0-BB89-11ED-B5F0-2B1B3058D408"
}

module.exports = {
  MESSAGE
}