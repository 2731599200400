import React from "react";
import { Row, Col } from "react-bootstrap";
import ProfileCard from "./ProfileCard";
import SecurityCard from "./SecurityCard";
const AccountScreen = () => {
  return (
    <div className="Screen-Container">
      <div className="Screen-Header">
        <div className="Screen-Headertxt1">Manage Account</div>
      </div>
      <div className="Screen-box">
        <Row>
          <Col sm="4" md="4" xs="12">
            <ProfileCard />
          </Col>
          <Col sm="4" md="4" xs="12">
            <SecurityCard />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AccountScreen;
