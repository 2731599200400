
import { DateRangeEnum } from "../Config/default-value-enum";
import { getUserSelectedLanguage } from "../Services/cognito-token-service";


const getDateRange = () => {
  let objDateRangeList = [
    { ID: 1, Name: 'Today', GroupName: DateRangeEnum.Today, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: 'C14E704B-B99D-4861-915D-AF2BE9234D6A' },
    { ID: 2, Name: 'Aujourd`hui', GroupName: DateRangeEnum.Today, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: 'C14E704B-B99D-4861-915D-AF2BE9234D6A' },
    { ID: 3, Name: 'اليوم', GroupName: DateRangeEnum.Today, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: 'C14E704B-B99D-4861-915D-AF2BE9234D6A' },
    { ID: 4, Name: '今日', GroupName: DateRangeEnum.Today, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: 'C14E704B-B99D-4861-915D-AF2BE9234D6A' },
    { ID: 5, Name: 'Hoje', GroupName: DateRangeEnum.Today, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: 'C14E704B-B99D-4861-915D-AF2BE9234D6A' },
    { ID: 6, Name: 'Hoy dia', GroupName: DateRangeEnum.Today, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: 'C14E704B-B99D-4861-915D-AF2BE9234D6A' },

    { ID: 7, Name: 'This Week', GroupName: DateRangeEnum.ThisWeek, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '92896171-FC51-434A-A69C-2FA8C4DFFDA7' },
    { ID: 8, Name: 'Cette semaine', GroupName: DateRangeEnum.ThisWeek, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '92896171-FC51-434A-A69C-2FA8C4DFFDA7' },
    { ID: 9, Name: 'هذا الاسبوع', GroupName: DateRangeEnum.ThisWeek, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '92896171-FC51-434A-A69C-2FA8C4DFFDA7' },
    { ID: 10, Name: '今週', GroupName: DateRangeEnum.ThisWeek, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '92896171-FC51-434A-A69C-2FA8C4DFFDA7' },
    { ID: 11, Name: 'Esta semana', GroupName: DateRangeEnum.ThisWeek, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '92896171-FC51-434A-A69C-2FA8C4DFFDA7' },
    { ID: 12, Name: 'Esta semana', GroupName: DateRangeEnum.ThisWeek, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '92896171-FC51-434A-A69C-2FA8C4DFFDA7' },

    { ID: 13, Name: 'This Month', GroupName: DateRangeEnum.ThisMonth, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '7997DB18-5201-43D3-AFEF-75D00BAA1614' },
    { ID: 14, Name: 'Ce mois-ci', GroupName: DateRangeEnum.ThisMonth, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '7997DB18-5201-43D3-AFEF-75D00BAA1614' },
    { ID: 15, Name: 'هذا الشهر', GroupName: DateRangeEnum.ThisMonth, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '7997DB18-5201-43D3-AFEF-75D00BAA1614' },
    { ID: 16, Name: '今月', GroupName: DateRangeEnum.ThisMonth, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '7997DB18-5201-43D3-AFEF-75D00BAA1614' },
    { ID: 17, Name: 'Este mês', GroupName: DateRangeEnum.ThisMonth, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '7997DB18-5201-43D3-AFEF-75D00BAA1614' },
    { ID: 18, Name: 'Este mes', GroupName: DateRangeEnum.ThisMonth, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '7997DB18-5201-43D3-AFEF-75D00BAA1614' },

    { ID: 19, Name: 'This Year', GroupName: DateRangeEnum.ThisYear, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '0D113CCA-9F1F-48C6-90E1-14D7A322174E' },
    { ID: 20, Name: 'Cette année', GroupName: DateRangeEnum.ThisYear, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '0D113CCA-9F1F-48C6-90E1-14D7A322174E' },
    { ID: 21, Name: 'هذه السنة', GroupName: DateRangeEnum.ThisYear, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '0D113CCA-9F1F-48C6-90E1-14D7A322174E' },
    { ID: 22, Name: 'この年', GroupName: DateRangeEnum.ThisYear, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '0D113CCA-9F1F-48C6-90E1-14D7A322174E' },
    { ID: 23, Name: 'Este ano', GroupName: DateRangeEnum.ThisYear, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '0D113CCA-9F1F-48C6-90E1-14D7A322174E' },
    { ID: 24, Name: 'Este año', GroupName: DateRangeEnum.ThisYear, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '0D113CCA-9F1F-48C6-90E1-14D7A322174E' },

    { ID: 25, Name: 'Yesterday', GroupName: DateRangeEnum.Yesterday, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '63FCE90C-351F-4663-930D-5C7A1A9DBCF6' },
    { ID: 26, Name: 'Hier', GroupName: DateRangeEnum.Yesterday, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '63FCE90C-351F-4663-930D-5C7A1A9DBCF6' },
    { ID: 27, Name: 'في الامس', GroupName: DateRangeEnum.Yesterday, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '63FCE90C-351F-4663-930D-5C7A1A9DBCF6' },
    { ID: 28, Name: '昨日', GroupName: DateRangeEnum.Yesterday, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '63FCE90C-351F-4663-930D-5C7A1A9DBCF6' },
    { ID: 29, Name: 'Ontem', GroupName: DateRangeEnum.Yesterday, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '63FCE90C-351F-4663-930D-5C7A1A9DBCF6' },
    { ID: 30, Name: 'El dia de ayer', GroupName: DateRangeEnum.Yesterday, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '63FCE90C-351F-4663-930D-5C7A1A9DBCF6' },

    { ID: 31, Name: 'Last Week', GroupName: DateRangeEnum.LastWeek, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: 'AF10199E-7FB2-4C4B-8ECE-C239A251F4D0' },
    { ID: 32, Name: 'La semaine dernière', GroupName: DateRangeEnum.LastWeek, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: 'AF10199E-7FB2-4C4B-8ECE-C239A251F4D0' },
    { ID: 33, Name: 'الأسبوع الماضي', GroupName: DateRangeEnum.LastWeek, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: 'AF10199E-7FB2-4C4B-8ECE-C239A251F4D0' },
    { ID: 34, Name: '先週', GroupName: DateRangeEnum.LastWeek, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: 'AF10199E-7FB2-4C4B-8ECE-C239A251F4D0' },
    { ID: 35, Name: 'Semana passada', GroupName: DateRangeEnum.LastWeek, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: 'AF10199E-7FB2-4C4B-8ECE-C239A251F4D0' },
    { ID: 36, Name: 'La semana pasada', GroupName: DateRangeEnum.LastWeek, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: 'AF10199E-7FB2-4C4B-8ECE-C239A251F4D0' },

    { ID: 37, Name: 'Last Month', GroupName: DateRangeEnum.LastMonth, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: 'BF0C0BEF-D5F6-4F54-AF1C-DBC8CA3C9824' },
    { ID: 38, Name: 'Le mois dernier', GroupName: DateRangeEnum.LastMonth, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: 'BF0C0BEF-D5F6-4F54-AF1C-DBC8CA3C9824' },
    { ID: 39, Name: 'الشهر الماضي', GroupName: DateRangeEnum.LastMonth, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: 'BF0C0BEF-D5F6-4F54-AF1C-DBC8CA3C9824' },
    { ID: 40, Name: '先月', GroupName: DateRangeEnum.LastMonth, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: 'BF0C0BEF-D5F6-4F54-AF1C-DBC8CA3C9824' },
    { ID: 41, Name: 'Mês passado', GroupName: DateRangeEnum.LastMonth, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: 'BF0C0BEF-D5F6-4F54-AF1C-DBC8CA3C9824' },
    { ID: 42, Name: 'El mes pasado', GroupName: DateRangeEnum.LastMonth, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: 'BF0C0BEF-D5F6-4F54-AF1C-DBC8CA3C9824' },

    { ID: 43, Name: 'Last Year', GroupName: DateRangeEnum.LastYear, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '942831A9-19E6-48C2-89CD-F3B36E475322' },
    { ID: 44, Name: 'L`année dernière', GroupName: DateRangeEnum.LastYear, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '942831A9-19E6-48C2-89CD-F3B36E475322' },
    { ID: 45, Name: 'العام الماضي', GroupName: DateRangeEnum.LastYear, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '942831A9-19E6-48C2-89CD-F3B36E475322' },
    { ID: 46, Name: '去年', GroupName: DateRangeEnum.LastYear, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '942831A9-19E6-48C2-89CD-F3B36E475322' },
    { ID: 47, Name: 'Ano passado', GroupName: DateRangeEnum.LastYear, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '942831A9-19E6-48C2-89CD-F3B36E475322' },
    { ID: 48, Name: 'El año pasado', GroupName: DateRangeEnum.LastYear, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '942831A9-19E6-48C2-89CD-F3B36E475322' },

    { ID: 49, Name: 'Last 7 Days', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '4A742734-DDD8-4330-A04C-7361AF4E5D84' },
    { ID: 50, Name: 'Les 7 derniers jours', GroupName: DateRangeEnum.Last7days, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '4A742734-DDD8-4330-A04C-7361AF4E5D84' },
    { ID: 51, Name: 'اخر 7 ايام', GroupName: DateRangeEnum.Last7days, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '4A742734-DDD8-4330-A04C-7361AF4E5D84' },
    { ID: 52, Name: '過去7日間', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '4A742734-DDD8-4330-A04C-7361AF4E5D84' },
    { ID: 53, Name: 'Últimos 7 dias', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '4A742734-DDD8-4330-A04C-7361AF4E5D84' },
    { ID: 54, Name: 'Los últimos 7 días', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '4A742734-DDD8-4330-A04C-7361AF4E5D84' },

    { ID: 55, Name: 'Last 30 Days', GroupName: DateRangeEnum.Last30Days, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '922E260D-59E6-48DA-BD3D-143E12B867F6' },
    { ID: 56, Name: 'Les 30 derniers jours', GroupName: DateRangeEnum.Last7days, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '922E260D-59E6-48DA-BD3D-143E12B867F6' },
    { ID: 57, Name: 'آخر 30 يومًا', GroupName: DateRangeEnum.Last7days, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '922E260D-59E6-48DA-BD3D-143E12B867F6' },
    { ID: 58, Name: '過去30日間', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '922E260D-59E6-48DA-BD3D-143E12B867F6' },
    { ID: 59, Name: 'Últimos 30 dias', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '922E260D-59E6-48DA-BD3D-143E12B867F6' },
    { ID: 60, Name: 'Los últimos 30 días', GroupName: DateRangeEnum.Last7days, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '922E260D-59E6-48DA-BD3D-143E12B867F6' },

    { ID: 61, Name: 'Custom', GroupName: DateRangeEnum.Custom, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '2F13D7F8-C7B7-4EC6-B86D-D2E1B13CDB1D' },
    { ID: 62, Name: 'Personnalisé', GroupName: DateRangeEnum.Custom, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '2F13D7F8-C7B7-4EC6-B86D-D2E1B13CDB1D' },
    { ID: 63, Name: 'مخصص', GroupName: DateRangeEnum.Custom, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '2F13D7F8-C7B7-4EC6-B86D-D2E1B13CDB1D' },
    { ID: 64, Name: 'カスタム', GroupName: DateRangeEnum.Custom, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '2F13D7F8-C7B7-4EC6-B86D-D2E1B13CDB1D' },
    { ID: 65, Name: 'Personalizado', GroupName: DateRangeEnum.Custom, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '2F13D7F8-C7B7-4EC6-B86D-D2E1B13CDB1D' },
    { ID: 66, Name: 'Personalizado', GroupName: DateRangeEnum.Custom, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '2F13D7F8-C7B7-4EC6-B86D-D2E1B13CDB1D' },

    { ID: 49, Name: 'All', GroupName: DateRangeEnum.All, LanguageGuid: 'F8C0FDCA-11CA-4321-B207-46A75E12A3E5', LanguageGroupGuid: '6F5E3EA7-60CE-4092-833E-09A7D3AE16F3' },
    { ID: 50, Name: 'Tout', GroupName: DateRangeEnum.All, LanguageGuid: '9F7E69EA-FC97-49A5-A36A-0F8DC9D6A86F', LanguageGroupGuid: '6F5E3EA7-60CE-4092-833E-09A7D3AE16F3' },
    { ID: 51, Name: 'الجميع', GroupName: DateRangeEnum.All, LanguageGuid: '7C5E896B-5C91-4590-8769-64FD9BAADE22', LanguageGroupGuid: '6F5E3EA7-60CE-4092-833E-09A7D3AE16F3' },
    { ID: 52, Name: '全て', GroupName: DateRangeEnum.All, LanguageGuid: 'B683AB17-0F62-435B-A575-310EC9200CF0', LanguageGroupGuid: '6F5E3EA7-60CE-4092-833E-09A7D3AE16F3' },
    { ID: 53, Name: 'Tudo', GroupName: DateRangeEnum.All, LanguageGuid: 'C96A7F40-3358-4CED-8EFC-903784850457', LanguageGroupGuid: '6F5E3EA7-60CE-4092-833E-09A7D3AE16F3' },
    { ID: 54, Name: 'Todos', GroupName: DateRangeEnum.All, LanguageGuid: 'A0A232BB-79E7-4EDC-8EAC-DA1F94BBD473', LanguageGroupGuid: '6F5E3EA7-60CE-4092-833E-09A7D3AE16F3' }

  ];

  return objDateRangeList;
}

const getLanguageDateRange = async () => {
  let storedArray = getUserSelectedLanguage();
  const languageGuid = storedArray.LanguageGuid;
  let objDateRangeList = getDateRange();
  let objFilteredList = objDateRangeList.filter((dataRangeObj) => {
    return dataRangeObj.LanguageGuid.toLowerCase() === languageGuid.toLowerCase()
  });
  
  return objFilteredList;
}

export { getLanguageDateRange };
