import { Row, Col } from "react-bootstrap";
import { Modal, Button, Form, Input, Select } from "antd";
const SearchPopup = (props: any) => {
  return (
    <Modal
      width={650}
      title={
        <div className="SearchPopup-title">Advanced search in Shipments</div>
      }
      open={props.visible}
      onOk={() => props.close()}
      onCancel={() => props.close()}
      footer={false}
    >
      <Form>
        <div className="SearchPopup-Box">
          <Row>
            <Col sm="6" xs="12">
              <Form.Item>
                <Input size="small" placeholder="ID" />
              </Form.Item>
              <Form.Item>
                <Input size="small" placeholder="Product Code" />
              </Form.Item>
              <Form.Item>
                <Select size="small" placeholder="Packing Status">
                  <Select.Option>1</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Select size="small" placeholder="Source of Item">
                  <Select.Option>1</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item>
                <Input size="small" placeholder="Barcode" />
              </Form.Item>
              <Form.Item>
                <Input size="small" placeholder="Name" />
              </Form.Item>
              <Form.Item>
                <Select size="small" placeholder="Stock Type">
                  <Select.Option>1</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Select size="small" placeholder="Product Type">
                  <Select.Option>1</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col sm="6" xs="12"></Col>
          <Col sm="6" xs="12">
            <Row>
              <Col sm="6" xs="6">
                <Button
                  type="default"
                  htmlType="button"
                  block
                  size="small"
                  style={{ backgroundColor: "#DDDDDD" }}
                  onClick={() => props.close()}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm="6" xs="6">
                <Button htmlType="submit" type="primary" block size="small">
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SearchPopup;
