import React, { useState, useEffect } from "react";
import { Layout, Drawer, message } from "antd";
import { Auth, Amplify } from "aws-amplify";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import Header from "./Header";
import DrawerBox from "./DrawerBox";
import LOGO from "../Assets/Images/logo.png";
import { save } from ".././Redux/Slices/TokenSlice";
import DashboardScreen from "../Screens/DashboardScreen";
import ShipmentScreen from "../Screens/ShipmentScreen";
import ShipmentDetails from "../Screens/ShipmentScreen/details";
import TrackingDetails from "../Screens/ShipmentScreen/tracking";
import JobsScreen from "../Screens/JobsScreen";
import InvoicesScreen from "../Screens/InvoicesScreen";
import ReceiptsScreen from "../Screens/ReceiptsScreen";
import BillsScreen from "../Screens/BillsScreen";
import VouchersScreen from "../Screens/VouchersScreen";
import AccountScreen from "../Screens/AccountScreen";
import JobDetails from "../Screens/JobsScreen/details";
import ApplicationScreen from "../Screens/ApplicationScreen";
import NewApplication from "../Screens/ApplicationScreen/FormModal";
import ApplicationDetails from "../Screens/ApplicationScreen/details";
import { getPoolInformation } from "../Services/tenant-identity-service";
import { getTokenDetailsByDecoding, clearLocalStorage } from "../Services/cognito-token-service";
import awsPoolConfiguration from "../Config/aws-exports";
import { useNavigate } from "react-router-dom";

const { Sider, Content } = Layout;

const Navigation = () => {
  useEffect(() => {
    //StoreToken();
    configureUserPoolAmplify();
  }, []);

  // onmousemove = () => {
  //   RefreshToken();
  // };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [mobileDrawer, setmobileDrawer] = useState(false);
  const [Authed, setAuthed] = useState(false);
  const [User, setUser] = useState();
  const [UserName, setTenantUserName] = useState();
  const [TenantUserGuid, setTenantUserGuid] = useState();
  const [TenantResult, setTenantResult] = useState();

  const StoreToken = async () => {
    let token = getIdTokenFromCookie();
    Auth.currentSession()
      .then(async (session) => {
        let token = session.getIdToken().getJwtToken();
        // dispatch(save(token));
        window.sessionStorage.setItem("token", token);
        // alert("StoreToken");
        setAuthed(true);
        let user = await Auth.currentAuthenticatedUser();
        let userAttributes = await Auth.currentUserInfo();
        let tenantUserName = userAttributes.attributes["custom:tenantUserName"];
        let tenantUserGuid = userAttributes.attributes["custom:tenantUserGuid"];

        setUser(user);
        setTenantUserName(tenantUserName);
        setTenantUserGuid(tenantUserGuid);
      })
      .catch((error) => {
      });
    if (token !== undefined && token !== null) {
    }
  };

  const getIdTokenFromCookie = async () => {
    try {
      // const session = await Auth.currentSession();
      // const idToken = session.getIdToken().getJwtToken();
      // return idToken;

      let idToken = localStorage.getItem('authenticationToken');
      return idToken;
    } catch (error) {
      console.error("Error retrieving ID token from cookie:", error);
      return null;
    }
  };

  const configureUserPoolAmplify = async () => {
    try {
      let token = localStorage.getItem('authenticationToken');
      let currentTimeInSecond = Date.now() / 1000; // convert to seconds

      if (token !== '' && token !== undefined && token !== null) {
        let decodedToken: any = getTokenDetailsByDecoding();
        let tenantGuid = decodedToken.tenantGuid;
        let tokenExpirationInSecond = decodedToken.exp;
        let minimumTokenRefreshTimeInSecond = 300;
        let timeDiffInSecond = tokenExpirationInSecond - currentTimeInSecond;
        let tenantTableResult = await getPoolInformation(tenantGuid);

        awsPoolConfiguration.aws_project_region = tenantTableResult.region;
        awsPoolConfiguration.aws_cognito_identity_pool_id = tenantTableResult.identityPoolId;
        awsPoolConfiguration.aws_cognito_region = tenantTableResult.region;
        awsPoolConfiguration.aws_user_pools_id = tenantTableResult.userPoolId;
        awsPoolConfiguration.aws_user_pools_web_client_id = tenantTableResult.clientId;

        Amplify.configure(awsPoolConfiguration);
        Auth.configure(awsPoolConfiguration);
        await StoreToken();

        // if (tokenExpirationInSecond < currentTimeInSecond) {
        if (timeDiffInSecond <= minimumTokenRefreshTimeInSecond) {
          await RefreshToken();
        }
      }
      else {
        navigate("/login");
        clearLocalStorage();
      }

    } catch (error: any) {

      message.error("Could'nt configure amplify.......");
    }
  }

  const RefreshToken = async () => {

    let cognitoUser = await Auth.currentAuthenticatedUser();
    let currentSession = await Auth.currentSession();
    let refreshToken = currentSession.getRefreshToken();

    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
      let { idToken, refreshToken, accessToken } = session;
      let newToken = idToken.getJwtToken();
      localStorage.setItem("authenticationToken", newToken);
      window.sessionStorage.setItem("token", newToken);
    });

    message.warn("Section timeout.Please reload");
    // window.location.reload();
  };

  return (
    <Layout>
      <div className="Navigation-drawer">
        <Sider trigger={null} collapsible collapsed={collapsed} width={256}>
          <DrawerBox
            collapsed={collapsed}
            onWidth={() => setCollapsed(!collapsed)}
          />
        </Sider>
      </div>
      <Drawer
        placement="left"
        onClose={() => setmobileDrawer(!mobileDrawer)}
        open={mobileDrawer}
        width={256}
        closeIcon={null}
        headerStyle={{ backgroundColor: "#036d3c" }}
        title={
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <img src={LOGO} alt="My Happy SVG" className="Navigation-Logo" />
            <div style={{ margin: 3 }} />
            <div className="DrawerBox-txt2">AFRICA UNION CARGO NAMIBIA</div>
          </div>
        }
      >
        <DrawerBox
          mobileDrawer={mobileDrawer}
          collapsed={false}
          onChange={() => setmobileDrawer(!mobileDrawer)}
        />
      </Drawer>
      <Layout className="site-layout">
        <Header mobileDrawer={() => setmobileDrawer(!mobileDrawer)} />
        <Content className="Navigation-container">
          <Routes>
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/applications" element={<ApplicationScreen />} />
            <Route path="/new-application" element={<NewApplication />} />
            <Route
              path="/application-details"
              element={<ApplicationDetails />}
            />
            <Route path="/shipments" element={<ShipmentScreen />} />
            <Route
              path="/shipments-details/:id"
              element={<ShipmentDetails />}
            />
            <Route path="/tracking-details/:id" element={<TrackingDetails />} />
            <Route path="/jobs" element={<JobsScreen />} />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route path="/invoices" element={<InvoicesScreen />} />
            <Route path="/receipts" element={<ReceiptsScreen />} />
            <Route path="/bills" element={<BillsScreen />} />
            <Route path="/vouchers" element={<VouchersScreen />} />
            <Route path="/account" element={<AccountScreen />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Navigation;
