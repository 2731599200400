import axios from "axios";
import { Auth } from "aws-amplify";
import API from "../Config/API";
import { getUserSelectedLanguage } from "../Services/cognito-token-service";

const getToken = async () => {
  // const currentSession = await Auth.currentSession();
  // console.log("currentSession+++++++++++++", currentSession);
  // console.log("token++++++++++", currentSession.getIdToken().getJwtToken());
  // return currentSession.getIdToken().getJwtToken();

  let idToken = localStorage.getItem('authenticationToken');
  return idToken;
};

const getCustomer = async () => {
  try {
    let storedArray = getUserSelectedLanguage();
    console.log('storedArray++++++', storedArray);
    const languageCode = storedArray.Code;
    let apiURL = API.REACT_APP_GET_CUSTOMER_URL + '/Customer/GetPortalCustomerInfo';
    let token = await getToken();
    let body = {};

    let headerParam = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "language": languageCode,
        "Authorization": token
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiURL, body, headerParam)
        .then((response) => {
          console.log("getCustomer response++++++++++", response);
          resolve(response.data);
        })
        .catch(error => {
          console.log("getCustomer err", error.response.data);
          reject(error.response.data);
        });
    });
  } catch (error) {
    console.log("getCustomer error", error);
  }
};

export { getCustomer };
