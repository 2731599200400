import { IconType } from "react-icons";
import { RiShieldStarFill, RiShipFill } from "react-icons/ri";
import { IoMdAirplane, IoMdTrain } from "react-icons/io";
import { MdLocalShipping } from "react-icons/md";
const RadioIconButton = (props: any) => {
  type IconName =
    | "RiShieldStarFill"
    | "RiShipFill"
    | "IoMdAirplane"
    | "MdLocalShipping"
    | "IoMdTrain";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 24, color = "black" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      RiShieldStarFill: RiShieldStarFill,
      RiShipFill: RiShipFill,
      IoMdAirplane: IoMdAirplane,
      MdLocalShipping: MdLocalShipping,
      IoMdTrain: IoMdTrain,
    };

    const IconComponent = icons[iconName];

    return <IconComponent size={size} color={color} />;
  }

  return (
    <div key={props.keyValue}
      className={`RadioBoxBtn2 ${props.value === props.text ? "active" : ""}`}
      onClick={() => props.select(props.text)}
    >
      <Icon iconName={props.icon} size={20} />
      <div style={{ margin: 2 }} />
      <div className="NewApplication-txt1">{props.text}</div>
    </div>
  );
};

export default RadioIconButton;
