import React from "react";
import { Card } from "antd";
import { Row, Col } from "react-bootstrap";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const TrackingDetails = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="Screen-Container">
      <div className="Screen-Header">
        <div className="Screen-Headertxt1">
          <MdArrowBack
            size={30}
            style={{ marginRight: 15 }}
            onClick={() => navigate(-1)}
          />{" "}
          Tracking Details <span className="Formtxt10">( SHP2426527 )</span>
        </div>
      </div>
      <div className="Screen-box">
        <Card
          style={{ borderRadius: 10 }}
          title={
            <div>
              <div className="Formtxt11">Traking Item</div>
            </div>
          }
        >
          <Row>
            <Col sm="4" md="4" xs="12">
              <Card style={{ borderRadius: 10, padding: 10 }}>
                <div className="trackBox">
                  <div>
                    <div className="trackBox-dot-active"></div>
                    <div className="trackBox-box-active">
                      <div className="trackBox-box-content">
                        <div className="Formtxt6">Origin Statement</div>
                        <div className="Formtxt5">Lorem ipsum dolor sit</div>
                        <div className="Formtxt5">12 Dec 2022</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="trackBox-dot-active"></div>
                    <div className="trackBox-box-active">
                      <div className="trackBox-box-content">
                        <div className="Formtxt6">Origin Statement</div>
                        <div className="Formtxt5">Lorem ipsum dolor sit</div>
                        <div className="Formtxt5">12 Dec 2022</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="trackBox-dot-active"></div>
                    <div className="trackBox-box-active">
                      <div className="trackBox-box-content">
                        <div className="Formtxt6">Destination</div>
                        <div className="Formtxt5">Lorem ipsum dolor sit</div>
                        <div className="Formtxt5">12 Dec 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="trackBox-dot-active"></div>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default TrackingDetails;
