import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function LoadingBox() {
  return (
    <div className="LoadingBox">
      <CircularProgress style={{ color: "#4acb70" }} />
      <br />
      <div>Loading . . .</div>
    </div>
  );
}

export default LoadingBox;
