import { useState } from "react";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { Auth, Amplify } from "aws-amplify";
import axios from "axios";
import { Card, Form, Input, Button, Checkbox, Select, message } from "antd";
import { MdPersonOutline, MdOutlineVerifiedUser } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import API from "../../Config/API";
import RegisterSuccess from "./RegisterSuccess";
import { registerUserPortalUser } from "../../Services/tenant-identity-service";


interface RegisterFormValue {
  fullName: "",
  email: ""
}

const RegisterScreen = (props: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [check, setChecked] = useState(false);

  const emailValidator = (rule: any, value: string) => {
    return new Promise((resolve, reject) => {
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;

      if (value && !emailRegex.test(value)) {
        reject('Please enter a valid email address');
      } else {
        resolve(undefined);
      }
    });
  };

  const onFinish = (values: RegisterFormValue) => {
    setLoading(true);
    let formValue = values;

    registerUserPortalUser(formValue.fullName, formValue.email)
      .then(function (response) {
        setSuccess(true);
        setLoading(false);
      })
      .catch(function (error) {
        setSuccess(false);
        setLoading(false);
        let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
        message.error(errorMessage);
      });
  };

  const onClick = () => {
    setChecked(!check);
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <div>
      <Header />
      <div className="home-screen">
        <Container>
          <br />
          <div className="page-header-box">
            <div>User Account</div>
          </div>

          <Row>
            <Col sm="3" md="3" xs="12"></Col>
            <Col sm="6" md="6" xs="12">
              {success ? (
                <div>
                  <RegisterSuccess />
                </div>
              ) : (
                <>
                  <br /> <br />
                  <Card
                    style={{ borderRadius: 5, padding: 10, width: "90%" }}
                    title={
                      <div>
                        <div className="Formtxt1">Register Now</div>
                      </div>
                    }
                    className="Register-Card"
                  >
                    <Form onFinish={onFinish}>
                      <div
                        className="NewApplication-txt10"
                        style={{ marginBottom: "2.19%" }}
                      >
                        Name
                      </div>
                      <Form.Item
                        name="fullName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Name",
                          },
                        ]}
                      >
                        <Input
                          style={styles.input}
                          placeholder="Username"
                          suffix={<MdPersonOutline style={styles.input_Icon} />}
                        />
                      </Form.Item>
                      {/* <Form.Item>
                    <div
                      className="NewApplication-txt10"
                      style={{ marginBottom: "2.19%" }}
                    >
                      Password
                    </div>
                    <Input.Password
                      style={styles.input}
                      placeholder="Password"
                    />
                  </Form.Item> */}
                      {/* <Form.Item>
                    <div
                      className="NewApplication-txt10"
                      style={{ marginBottom: "2.19%" }}
                    >
                      Confirm Password
                    </div>
                    <Input.Password
                      style={styles.input}
                      placeholder="Confirm password"
                    />
                  </Form.Item> */}
                      <div
                        className="NewApplication-txt10"
                        style={{ marginBottom: "2.19%" }}
                      >
                        Email
                      </div>

                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: 'Please input your Email' },
                          // { type: 'email', message: 'Please enter a valid email address' },
                          // { validator: emailValidator }
                        ]}
                      >
                        <Input
                          style={styles.input}
                          placeholder="Email"
                          suffix={
                            <MdOutlineVerifiedUser style={styles.input_Icon} />
                          }
                        />
                      </Form.Item>
                      {/* <div
                    className="NewApplication-txt10"
                    style={{ marginBottom: "2.19%" }}
                  >
                    Role
                  </div>
                  <Form.Item
                    name="userRole"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Any Role",
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option key="1">Customer</Select.Option>
                      <Select.Option key="2">Vendor</Select.Option>
                    </Select>
                  </Form.Item> */}
                      {/* <div
                    className="NewApplication-txt10"
                    style={{ marginBottom: "2.19%" }}
                  >
                    Verification Code
                  </div>
                  <Form.Item>
                    <Input
                      style={styles.input}
                      placeholder="Verification Code"
                    />
                  </Form.Item> */}
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Checkbox onChange={onClick}>
                          <span>
                            I Agreed the{" "}
                            <span className="Formtxt4">
                              Terms and Conditions
                            </span>
                          </span>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          block
                          type="primary"
                          size="small"
                          style={{ width: 150 }}
                          htmlType="submit"
                          loading={loading}
                        // disabled={!check}
                        >
                          Sign Up
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                  <br />
                  <div>
                    <span>Already have an account?</span>{" "}
                    <span className="Formtxt3" onClick={() => login()}>
                      Sign In
                    </span>
                  </div>
                  <br /> <br /> <br />
                </>
              )}
            </Col>
            <Col sm="3" md="3" xs="12"></Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterScreen;
