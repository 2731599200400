const RadioIconButton = (props: any) => {
  return (
    <div
      className={`RadioBoxBtn ${props.value === props.text ? "active" : ""}`}
      onClick={() => props.select(props.text)}
    >
      <div className="RadioBoxBtnIcon">{props.icon}</div>
      <div>{props.text}</div>
    </div>
  );
};

export default RadioIconButton;
