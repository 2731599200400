import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Button, message } from "antd";
import { MdPersonOutline } from "react-icons/md";
import styles from "./styles";
import { getTokenDetailsByDecoding } from "../../Services/cognito-token-service";
import { Auth } from 'aws-amplify';

const initialFormValue = {
  email: "",
  oldPassword: "",
  newPassword: ""
}


const SecurityCard = (props: any) => {
  const [changePasswordFormData, setChangePasswordFormData] = useState(initialFormValue);
  const [showFormSubmitLoader, setFormSubmitLoader] = useState(false);

  useEffect(() => {
    bindLoginUserData();

  }, []);

  const bindLoginUserData = () => {
    let decodedToken = getTokenDetailsByDecoding();

    if (decodedToken) {
      setChangePasswordFormData({ ...changePasswordFormData, ['email']: decodedToken.tenantUserName })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangePasswordFormData({ ...changePasswordFormData, [event.target.name]: event.target.value })
  }

  const submitFormData = async () => {
    setFormSubmitLoader(true);
    const { oldPassword, newPassword } = changePasswordFormData;
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        setFormSubmitLoader(false);
        message.success("Password Changed Successfully.");
      })
      .catch((err) => {
        setFormSubmitLoader(false);
        message.error(err.message);
      });
  };

  return (
    <Card
      style={{ borderRadius: 5, padding: 10 }}
      title={
        <div>
          <div className="Formtxt1">Security</div>
          <div className="Formtxt2">Username and Password</div>
        </div>
      }
    >
      <Form onFinish={submitFormData}>
        <Form.Item>
          <div
            className="NewApplication-txt10"
            style={{ marginBottom: "2.19%" }}
          >
            Username
          </div>
          <Input
            size="small"
            placeholder="Username" name="email" disabled={true} required value={changePasswordFormData.email} onChange={handleChange}
            suffix={<MdPersonOutline style={styles.input_Icon} />}
          />
        </Form.Item>
        <Form.Item>
          <div
            className="NewApplication-txt10"
            style={{ marginBottom: "2.19%" }}
          >
            Password
          </div>
          <Input.Password size="small" placeholder="Password" name="oldPassword" required value={changePasswordFormData.oldPassword} onChange={handleChange} />
        </Form.Item>
        <Form.Item>
          <div
            className="NewApplication-txt10"
            style={{ marginBottom: "2.19%" }}
          >
            Update Password
          </div>
          <Input.Password size="small" placeholder="Update Password" name="newPassword" required value={changePasswordFormData.newPassword} onChange={handleChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="small" style={styles.btn} loading={showFormSubmitLoader}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SecurityCard;
