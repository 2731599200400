import react, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { BsBell } from "react-icons/bs";
import { MdOutlineMenu } from "react-icons/md";
import User from "../Assets/Images/user-avatar.png";
import { getTokenDetailsByDecoding } from "../Services/cognito-token-service";
import UserPopup from "./UserPopup";
import { ConditionCreator } from "wherecomposer/dist/ConditionCreator";
import { SearchParam } from "wherecomposer/dist/SearchParam";
import { getTotalUnReadedBusinessNoteCount } from "../Services/note-service";

const Header = (props: any) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [totalUnreadedCount, setTotalUnreadedCount] = useState(0 as any);
  let navigate = useNavigate();
  const location = useLocation();

  document.addEventListener('refreshData', function () {
    getTotalUnReadedBusinessNoteChatCount();
  });

  document.addEventListener('updateMessgeUnReadCount', function () {
    getTotalUnReadedBusinessNoteChatCount();
  });

  useEffect(() => {
    bindLoginUserData();
    getTotalUnReadedBusinessNoteChatCount();
  }, []);

  const getTotalUnReadedBusinessNoteChatCount = async () => {
    let lstobjSearchParam: SearchParam[] = [];

    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(lstobjSearchParam);

    let inputAttachmentViewModel = {
      "OperationList": objWhere.toJsonString()
    }

    await getTotalUnReadedBusinessNoteCount(inputAttachmentViewModel)
      .then(async function (response) {
        console.log('getTotalUnReadedBusinessNoteCountresponse+++', response);
        let totalUnreadedCount = response;
        setTotalUnreadedCount(totalUnreadedCount);
      })
      .catch(function (error) {
        console.log('error', error);
      });
  }


  const bindLoginUserData = () => {
    let decodedToken = getTokenDetailsByDecoding();

    if (decodedToken) {
      setUserName(decodedToken.tenantUserName);
    }
  }


  const navToDashBoard = (route: any) => {
    navigate(route);
  };

  const navigateToTable = (route: any) => {

    if (location.pathname == '/auth/applications') {
      const customEvent = new Event('filterByUnReadCount');
      document.dispatchEvent(customEvent);
      navigate(route);
    }
    else {
      navigate(route, { state: { "isFilterByUnReadCount": true } });
    }
  };

  return (
    <div className="Navigation-Header">
      <div
        onClick={() => props.mobileDrawer()}
        className="Navigation-mbileOpen"
      >
        <MdOutlineMenu className="Navigation-Icon1" />
      </div>
      <div style={{ flex: 1 }}></div>
      <div className="Navigation-HeaderBox1">{/* <BsSearch /> */}</div>
      <div className="Navigation-devider2" />

      <div
        className="Navigation-HeaderBox1"
        onClick={() => navToDashBoard("/auth/dashboard")}
      >
        <BsFillGrid3X3GapFill />
      </div>

      <div className="Navigation-devider2" />

      <span className="display_flex align_center position_relative">
        <div className="Navigation-HeaderBox1 display_flex align_center">
          <BsBell className="Screen-HeaderIcon1 margin_right_12" size={21} color="#707070" onClick={() => navigateToTable("/auth/applications")} />
        </div>
        {totalUnreadedCount > 0 ? (
          <div className="notification_circle font_11 font_weight_600 position_absolute" >
            {totalUnreadedCount}
          </div>
        ) : (<></>)
        }
      </span>

      <div className="Navigation-devider2" />

      <div
        className="Navigation-HeaderBox2"
        onClick={() => setIsPopupVisible(true)}
      >
        <div className="Navigation-txt">
          Hi
          <span className="Navigation-txt2"> {userName}</span>
        </div>
        <div id="userIcon">
          <img src={User} alt="My Happy SVG" className="Navigation-Avatar" />
        </div>
      </div>
      <UserPopup
        visible={isPopupVisible}
        close={() => setIsPopupVisible(!isPopupVisible)}
      />
    </div>
  );
};

export default Header;

interface Customer {
  "ID": number;
  "CustomerGuid": string;
  "GroupGuid": string;
  "LocationGuid": string;
  "CurrencyGuid": string;
  "Name": string;
  "EmailAddress": string;
  "CellFullNumber": string;
  "IsCompany": boolean;
  "CompanyName": string;
  "PrimaryAddress": string;
}

