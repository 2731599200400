import React, { useState } from "react";
import styles from "./styles";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Select, Card, Button, Checkbox, DatePicker } from "antd";
import QuoteSuccess from "./QuoteSuccess";
import {
  MdFlight,
  MdLocalShipping,
  MdTripOrigin,
  MdSailing,
  MdViewCompact,
  MdWorkspaces,
  MdDeleteOutline,
  MdAddCircle,
} from "react-icons/md";
import RadioIconButton from "../../Components/RadioIconButton";
import RadioBoxButton from "../../Components/RadioBoxButton";

const GetQuoteForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [transportMode, setTransportMode] = useState("air");
  const [loadingMode, setloadingMode] = useState("FCL");
  const [goodstype, setgoodstype] = useState("Batteries");
  const [orginTrack, setOrginTrack] = useState("Yes");
  const [destinationTrack, setDestinationTrack] = useState("Yes");

  const onFinish = (value: any) => {
    console.log(value);
    setIsSuccess(true);
  };

  return (
    <div>
      <Header />
      <div className="home-screen">
        <br />
        <Container fluid={false}>
          <div className="page-header-box">
            <div>Get a Quote</div>
          </div>
          {isSuccess ? (
            <QuoteSuccess />
          ) : (
            <Form
              onFinish={onFinish}
              layout="vertical"
              initialValues={{
                package: [{ id: 1 }],
              }}
            >
              <Card>
                <div className="Formtxt1">Business Info</div>
                <br />
                <Row>
                  <Col sm="4" md="4" xs="12">
                    <Form.Item label="Mobile">
                      <Input.Group compact>
                        <Form.Item name={["address", "province"]} noStyle>
                          <Select
                            placeholder="Code"
                            size="small"
                            style={{ width: "30%" }}
                          >
                            <Select.Option value="Zhejiang">+91</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name={["address", "street"]} noStyle>
                          <Input
                            style={{ width: "70%", height: 40 }}
                            placeholder="Mobile Number"
                            size="small"
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col sm="4" md="4" xs="12">
                    <Form.Item label="Email">
                      <Input placeholder="Email" style={styles.input3} />
                    </Form.Item>
                  </Col>
                  <Col sm="4" md="4" xs="12"></Col>
                  <Col sm="4" md="4" xs="12">
                    <Form.Item label="Business Name">
                      <Input
                        style={styles.input3}
                        placeholder="Business Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="4" md="4" xs="12">
                    <Form.Item label="Office address">
                      <Input
                        style={styles.input3}
                        placeholder="Office address"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <br />
              <Card>
                <div className="Formtxt1">Shipment info</div>
                <br />
                <Form.Item label="Transportation Mode">
                  <Row>
                    <Col sm="2" md="2" xs="6">
                      <RadioIconButton
                        text={"Air"}
                        value={transportMode}
                        icon={<MdFlight />}
                        select={(value: any) => setTransportMode(value)}
                      />
                    </Col>
                    <Col sm="2" md="2" xs="6">
                      <RadioIconButton
                        text={"Ocean"}
                        value={transportMode}
                        icon={<MdSailing />}
                        select={(value: any) => setTransportMode(value)}
                      />
                    </Col>
                    <Col sm="2" md="2" xs="6">
                      <RadioIconButton
                        text={"Land"}
                        value={transportMode}
                        icon={<MdLocalShipping />}
                        select={(value: any) => setTransportMode(value)}
                      />
                    </Col>
                    <Col sm="2" md="2" xs="6">
                      <RadioIconButton
                        text={"Any type"}
                        value={transportMode}
                        icon={<MdTripOrigin />}
                        select={(value: any) => setTransportMode(value)}
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Loading Mode">
                  <Row>
                    <Col sm="2" md="2" xs="6">
                      <RadioIconButton
                        text={"FCL"}
                        value={loadingMode}
                        icon={<MdViewCompact />}
                        select={(value: any) => setloadingMode(value)}
                      />
                    </Col>
                    <Col sm="2" md="2" xs="6">
                      <RadioIconButton
                        text={"LCL"}
                        value={loadingMode}
                        icon={<MdWorkspaces />}
                        select={(value: any) => setloadingMode(value)}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Card>
              <br />
              <Row>
                <Col sm="6" md="6" xs="12">
                  <Card>
                    <div className="Formtxt1">Origin</div>
                    <br />
                    <Form.Item label="Do you need origin tracking service ?">
                      <Row>
                        <Col sm="3" md="3" xs="6">
                          <RadioBoxButton
                            text={"Yes"}
                            value={orginTrack}
                            select={(value: any) => setOrginTrack(value)}
                          />
                        </Col>
                        <Col sm="3" md="3" xs="6">
                          <RadioBoxButton
                            text={"No"}
                            value={orginTrack}
                            select={(value: any) => setOrginTrack(value)}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label="Origin location">
                      <Select
                        size="small"
                        placeholder="Enter / Search address"
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Zhejiang">+91</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Country">
                      <Select
                        size="small"
                        placeholder="Code"
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Zhejiang">+91</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Port name">
                      <Select
                        size="small"
                        placeholder="Code"
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Zhejiang">+91</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Expected date of pickup">
                      <DatePicker size="small" style={{ width: "50%" }} />
                    </Form.Item>
                    <br />
                    <div className="home-text3">
                      Mauris non venenatis justo. Nam fermentum lacinia dui id
                      eletum. Donec vestibulum ligula non sagittis accumsan
                    </div>
                  </Card>
                  <br />
                </Col>
                <Col sm="6" md="6" xs="12">
                  <Card>
                    <div className="Formtxt1">Destination</div>
                    <br />
                    <Form.Item label="Do you need destination tracking service ?">
                      <Row>
                        <Col sm="3" md="3" xs="6">
                          <RadioBoxButton
                            text={"Yes"}
                            value={destinationTrack}
                            select={(value: any) => setDestinationTrack(value)}
                          />
                        </Col>
                        <Col sm="3" md="3" xs="6">
                          <RadioBoxButton
                            text={"No"}
                            value={destinationTrack}
                            select={(value: any) => setDestinationTrack(value)}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label="Destination location">
                      <Select
                        size="small"
                        placeholder="Enter / Search address"
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Zhejiang">+91</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Country">
                      <Select
                        size="small"
                        placeholder="Code"
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Zhejiang">+91</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Port name">
                      <Select
                        size="small"
                        placeholder="Code"
                        style={{ width: "100%" }}
                      >
                        <Select.Option value="Zhejiang">+91</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Expected date of delivery">
                      <DatePicker size="small" style={{ width: "50%" }} />
                    </Form.Item>
                    <br />
                    <div className="home-text3">
                      Mauris non venenatis justo. Nam fermentum lacinia dui id
                      eletum. Donec vestibulum ligula non sagittis accumsan
                    </div>
                  </Card>
                  <br />
                </Col>
              </Row>
              <Card>
                <div className="Formtxt1">Consignment details</div>
                <br />
                <Form.List name={"package"}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div className="FormPackageBox">
                          <Row>
                            <Col sm="6" md="6" xs="8">
                              <div className="Formtxt5">
                                <b>Package {key + 1}</b>
                              </div>
                            </Col>
                            {key > 0 ? (
                              <Col sm="6" md="6" xs="4">
                                <div
                                  style={{ textAlign: "right" }}
                                  onClick={() => remove(name)}
                                >
                                  <MdDeleteOutline size={25} />
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                          <br />
                          <Row>
                            <Col sm="6" md="6" xs="12">
                              <Form.Item label="Package name">
                                <Input
                                  size="small"
                                  placeholder="Enter package name"
                                />
                              </Form.Item>
                            </Col>
                            <Col sm="3" md="3" xs="6">
                              <Form.Item label="Package type">
                                <Select
                                  size="small"
                                  placeholder="Package type"
                                  style={{ width: "100%" }}
                                >
                                  <Select.Option value="Zhejiang">
                                    Package type
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col sm="2" md="2" xs="6">
                              <Form.Item label="Item count">
                                <Input size="small" placeholder="121" />
                              </Form.Item>
                            </Col>
                            <Col sm="4" md="4" xs="6">
                              <Form.Item label="Weight / Item">
                                <Input
                                  size="small"
                                  placeholder="Weight / Item"
                                />
                              </Form.Item>
                            </Col>
                            <Col sm="2" md="2" xs="6">
                              <Form.Item label="Unit">
                                <Select
                                  size="small"
                                  placeholder="Unit"
                                  style={{ width: "100%" }}
                                >
                                  <Select.Option value="kg">Kg</Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col sm="4" md="4" xs="12">
                              <Form.Item label="Dimensions ( Length x Width x Height )">
                                <div className="rowbox">
                                  <div>
                                    <Form.Item noStyle>
                                      <Input
                                        size="small"
                                        placeholder="0"
                                        style={{
                                          borderRadius: "10px 0px 0px 10px",
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item noStyle>
                                      <Input
                                        size="small"
                                        placeholder="0"
                                        style={{ borderRadius: 0 }}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item noStyle>
                                      <Input
                                        size="small"
                                        placeholder="0"
                                        style={{
                                          borderRadius: "0px 10px 10px 0px",
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Form.Item>
                            </Col>
                            <Col sm="1" md="1" xs="6">
                              <Form.Item label="Unit">
                                <Select
                                  size="small"
                                  placeholder="Unit"
                                  style={{ width: "100%" }}
                                >
                                  <Select.Option value="cm">Cm</Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm="3" md="3" xs="6">
                              <div>
                                Weight: <b>300.32 Kg</b>
                              </div>
                            </Col>
                            <Col sm="3" md="3" xs="6">
                              <div>
                                Volume: <b>324 cm3</b>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <Button
                        icon={
                          <MdAddCircle size={20} style={{ marginRight: 10 }} />
                        }
                        type="link"
                        onClick={add}
                      >
                        Add New Package
                      </Button>
                    </>
                  )}
                </Form.List>
                <br /> <br />
                <Row>
                  <Col sm="3" md="3" xs="12">
                    <Form.Item label="Total Weight">
                      <Input size="small" placeholder="Enter package name" />
                    </Form.Item>
                  </Col>
                  <Col sm="3" md="3" xs="12">
                    <Form.Item label="Total Volume">
                      <Input size="small" placeholder="Enter package name" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <br />
              <Card>
                <div className="Formtxt1">Additional info</div>
                <br />
                <Row>
                  <Col sm="4" md="4" xs="12">
                    <Form.Item label="Product description (Optional)">
                      <Input size="small" placeholder="Enter package name" />
                    </Form.Item>
                  </Col>
                  <Col sm="8" md="8" xs="12"></Col>
                  <Col sm="8" md="8" xs="12">
                    <Form.Item label="Do your shipment contains any of the following goods?">
                      <Row>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Batteries"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Lorem ipsum dolor 1"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Lorem ipsum dolor 2"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Lorem ipsum dolor 3"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Lorem ipsum dolor 4"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Lorem ipsum dolor 5"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                        <Col sm="4" md="4" xs="12">
                          <RadioBoxButton
                            text={"Lorem ipsum dolor 6"}
                            value={goodstype}
                            select={(value: any) => setgoodstype(value)}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col sm="4" md="4" xs="12"></Col>
                  <Col sm="4" md="4" xs="12">
                    <Form.Item label="Notes to share with us (ptional)">
                      <Input.TextArea
                        size="small"
                        placeholder="Enter your comments"
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <br />
              <Row style={{ padding: 10 }}>
                <Col sm="4" md="7" xs="12">
                  <Checkbox>
                    I agree Terms & Conditions Mauris non venenatis justo.
                  </Checkbox>
                  <br />
                  <br />
                </Col>
                <Col sm="2" md="1" xs="12">
                  <Button size="small" block>
                    Cancel
                  </Button>
                  <br />
                  <br />
                </Col>
                <Col sm="3" md="2" xs="12">
                  <Button size="small" block ghost type="primary">
                    Preview Requirements
                  </Button>
                  <br />
                  <br />
                </Col>
                <Col sm="3" md="2" xs="12">
                  <Button
                    size="small"
                    block
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Send me Quotation
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          <br />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default GetQuoteForm;
