import "./App.scss";
import "./App.less";
import { Routes, Route } from "react-router-dom";
import LandingScreen from "./Screens/LandingScreen";
import HomeScreen from "./Screens/HomeScreen";
import GetQuoteScreen from "./Screens/GetQuoteScreen";
import GetQuoteForm from "./Screens/GetQuoteScreen/GetQuoteForm";
import TrackScreen from "./Screens/TrackScreen";
import AuthScreen from "./Screens/AuthScreen";
import LoginScreen from "./Screens/LoginScreen";
import RegisterScreen from "./Screens/RegisterScreen";
import Navigation from "./Navigation";
import ForgotPasswordScreen from "./Screens/ForgotPasswordScreen";
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingScreen />} />
      <Route path="/home" element={<HomeScreen />} />
      <Route path="/qoute" element={<GetQuoteScreen />} />
      <Route path="/getQoute" element={<GetQuoteForm />} />
      <Route path="/track" element={<TrackScreen />} />
      <Route path="/auth" element={<AuthScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/reset" element={<ForgotPasswordScreen />} />
      <Route path="/auth/*" element={<Navigation />} />
    </Routes>
  );
};

export default App;
