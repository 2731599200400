import { Container, Row, Col } from "react-bootstrap";
import LOGO2 from "../Assets/Images/Logi-grey.svg";
const Footer = () => {
  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col sm="6" xs="12">
            <div className="Footer-txt1">
              © 2023 | All rights reserved.
            </div>
          </Col>
          <Col sm="6" xs="12">
            <div className="Footer-txt2">
              Powered By <span style={{ margin: 3 }}></span>
              <img src={LOGO2} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
