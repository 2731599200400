import { Row, Col } from "react-bootstrap";
import { Modal, Button, Form, Select, Upload, message } from "antd";
import React, { useState, useEffect } from "react";
import { DefaultValueEnum, MenuShortNameEnum } from "../../Config/default-value-enum";
import moment from "moment";
import axios from "axios";
import API from "../../Config/API";
import { createAttachmentFile } from "../../Services/attachment-service";
import CircularProgress from "@mui/material/CircularProgress";
import { MdVerified, MdCloudUpload, MdOutlineDelete } from "react-icons/md";
import { getTokenDetailsByDecoding } from "../../Services/cognito-token-service";

const UpdateDocumentsModal = (props: any) => {

  const [documentType, setDocumentType] = useState([] as any);
  const [documentAttachmentList, setDocumentAttachmentList] = useState([] as any);
  const [documentJob, setDocumentJob] = useState({} as any);
  const [documentJobGuid, setDocumentJobGuid] = useState('');
  const [documentJobNo, setDocumentJobNo] = useState('');
  const [documentJobCreatedLocation, setDocumentJobCreatedLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const initialFormValue = {
    DocumentTypeGuid: DefaultValueEnum.EmptyGuid,
  }
  const [documentAttchamentForm, setDocumentAttchamentForm] = useState(initialFormValue);
  const { Dragger } = Upload;
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [isUploadLoading, setUploadLoading] = useState(false);

  let userData = JSON.parse(sessionStorage.getItem("userData") as any);
  let tenantResult = JSON.parse(sessionStorage.getItem("tenantResult") as any);


  useEffect(() => {
    setDocumentJob(props.props);
    setDocumentType(props.props.DocumentCategory?.InboundDocumentTypes);
    setDocumentJobGuid(props.props.DocumentJobGuid);
    setDocumentJobNo(props.props.DocumentJobNo);
    setDocumentJobCreatedLocation(props.props.CreatedLocationGuid);
  }, []);

  const handleChangeDocumentType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentAttchamentForm({ ...documentAttchamentForm, ['DocumentTypeGuid']: event as any })
  }

  async function handleImageChange(event: any) {
    let file = event.target?.files?.[0];
    let decodedToken = getTokenDetailsByDecoding();
    let tenantGuid = decodedToken?.tenantGuid;
    let tenantUserGuid = decodedToken?.tenantUserGuid;

    const fileName = `${tenantGuid}/${tenantUserGuid}/${moment().unix()}/${MenuShortNameEnum.DOCUMENT_JOB}/${file?.name}`;

    await fileToDataUri(file)
      .then(async (dataUri) => {
        let bufff = _base64ToArrayBuffer(dataUri);
        await getS3UpUrl(file, fileName, bufff);
      })
      .catch(function (error) {
        console.log("error ==========>", error);
      });
  }

  const getS3UpUrl = async (
    file: any,
    fileName: any,
    buffer: any
  ) => {

    setAttachmentLoading(true);

    let URL = API.APPLICATION_URL + '/DocumentJob/GetS3UploadUrl';
    axios
      .get(URL, {
        params: {
          bucketKey: fileName,
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          let url = response.data;
          await uploadToS3(file, url, fileName, buffer);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadToS3 = async (
    file: any,
    url: any,
    fileName: any,
    buffer: any
  ) => {
    let URL = url.S3PutURL;

    axios
      .put(URL, buffer, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*"
        },
      })
      .then(async function (response) {
        if (response.status === 200) {
          await addToAttachmentList(file, fileName);
          setAttachmentLoading(false);
          setSucess(true);
          message.success("Success");
        }
      })
      .catch(function (error) {
        console.log(error);
        setAttachmentLoading(false);
      });
  };

  const addToAttachmentList = (
    file: any,
    fileName: any
  ) => {

    let documentAttachment = new DocumentAttachment();
    documentAttachment.Title = file?.name;
    documentAttachment.FileName = file?.name;
    documentAttachment.FileType = file.type;
    documentAttachment.IsInboundFile = true;
    documentAttachment.SavedS3Location = fileName;
    documentAttachment.IsOutBoundFile = false;
    documentAttachment.ShowOnPortal = true;
    documentAttachment.UploadedFromPortal = true;
    documentAttachment.IsPreDefinedUrl = true;
    documentAttachment.DocumentTypeGuid = documentAttchamentForm.DocumentTypeGuid;
    documentAttachment.IsRelatedItem = false;
    documentAttachment.RelatedModules = [];
    documentAttachment.BusinessEntity = MenuShortNameEnum.DOCUMENT_JOB;
    documentAttachment.BusinessEntityGuid = documentJobGuid;
    documentAttachment.CreatedLocationId = documentJobCreatedLocation;
    documentAttachment.BusinessEntityNo = documentJobNo;

    let attachmentList: any = [];
    attachmentList.push(documentAttachment);
    setDocumentAttachmentList(attachmentList);
  };

  function _base64ToArrayBuffer(base64: any) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result
            .toString()
            .replace("data:", "")
            .replace(/^.+,/, "");
          resolve(base64String);
        }
      };
      reader.readAsDataURL(file);
    });

  const onUploadAttachment = async () => {

    if (documentAttchamentForm.DocumentTypeGuid != null && documentAttchamentForm.DocumentTypeGuid != undefined && documentAttchamentForm.DocumentTypeGuid != DefaultValueEnum.EmptyGuid) {

      if (documentAttachmentList.length != 0) {
        setUploadLoading(true);
        await createAttachmentFile(documentAttachmentList)
          .then(function (response) {
            let attchmentList = response;
            setDocumentAttachmentList([]);
            setSucess(false);
            props.parentCallback(attchmentList);
            setUploadLoading(false);
            message.success('Attachment uploaded successfully');
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setUploadLoading(false);
            setLoading(false);
          });
      }
      else {
        message.error('Please choose an attachment.');
      }
    }
    else {
      message.error('Please select a document type.');
    }
  }

  function handleClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*, application/pdf";
    input.addEventListener("change", (event: any) =>
      handleImageChange(event)
    );
    input.click();
  }

  const deleteAttachment = async () => {
    setDocumentAttachmentList([]);
    setSucess(false);
  }

  return (
    <Modal
      width={850}
      title={<div className="SearchPopup-title">Add New Documents</div>}
      open={props.visible}
      onOk={() => props.close()}
      onCancel={() => props.close()}
      footer={false}
    >
      <Form>
        <div className="SearchPopup-Box">
          <Row>
            <Col sm="2" xs="2"></Col>
            <Col sm="8" xs="8">
              <div className="">
                <Select size="small" placeholder="Document Type" style={{ width: "100%" }} onChange={handleChangeDocumentType}>
                  {documentType.length &&
                    documentType?.map((item: any, index: any) => {
                      return (
                        <Select.Option key={index} value={item.LanguageGroupGuid}>
                          {item.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
                <br />
                <br />

                <div className="NewApplication-txt11">
                  Upload your document
                </div>
                <div style={{ margin: "1%" }} />
                <div className="NewApplication-txt9">
                  Acceptable formats: PDF, DOCX, XLSX, PPTX, JPG, DOC, XLS,
                  PPT, JPEG, PNG <br />
                  File Size: Min. 100kb. Max. 500kb
                </div>
                <div style={{ margin: "1.9%" }} />

                <div >
                  <div style={{ margin: "1%" }} />
                  <div className="display_flex flex_row flex_align_center">
                    <div
                      className="NewApplication-box3 margin_right_10"
                      contentEditable={true}
                      data-image-url={imageUrl}
                      suppressContentEditableWarning={true}
                      onClick={() => handleClick()}
                      style={{ outline: "none" }}
                    >
                      {attachmentLoading ? (
                        <>
                          <CircularProgress
                            style={{ color: "#4acb70" }}
                          />
                        </>
                      ) : success ? (
                        <>
                          <MdVerified size={25} color="#4acb70" />
                          <div className="NewApplication-txt3 ">
                            {`${documentAttachmentList[0] &&
                              documentAttachmentList[0].FileName &&
                              documentAttachmentList[0]?.FileName
                              }`}
                          </div>
                        </>
                      ) : (
                        <>
                          <MdCloudUpload size={30} color="#ccc" />
                          <div className="NewApplication-txt3 ">
                            Select File
                          </div>
                        </>
                      )}


                    </div>
                    <MdOutlineDelete className="Screen-HeaderIcon1" size={25} color="#000000" onClick={() => deleteAttachment()} />
                  </div>

                  <div style={{ margin: "1.9%" }} />

                </div>

              </div>
            </Col>
            <Col sm="2" xs="2"></Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col sm="6" xs="12"></Col>
          <Col sm="6" xs="12">
            <Row>
              <Col sm="6" xs="6">
                <Button
                  type="default"
                  htmlType="button"
                  block
                  size="small"
                  style={{ backgroundColor: "#DDDDDD" }}
                  onClick={() => props.close()}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm="6" xs="6">
                <Button
                  htmlType="submit"
                  type="primary"
                  block size="small"
                  loading={isUploadLoading}
                  onClick={(event) => onUploadAttachment()}>
                  Upload
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
};

export class DocumentAttachment {
  BusinessEntity: string = '';
  BusinessEntityGuid: string = DefaultValueEnum.EmptyGuid;
  BusinessEntityNo: string = '';
  CreatedLocationId: string = DefaultValueEnum.EmptyGuid;
  Title: string = ''
  ModifiedDate: Date = new Date();
  CreatedDate: Date = new Date();
  FileName: string = ''
  FileType: string = ''
  RelatedModules: any[] = [];
  IsInboundFile: boolean = false;
  IsOutBoundFile: boolean = false;
  SavedS3Location: string = '';
  DocumentTypeGuid: string = DefaultValueEnum.EmptyGuid;
  IsPreDefinedUrl: boolean = true;
  ShowOnPortal: boolean = true;
  UploadedFromPortal: boolean = true;
  IsRelatedItem: boolean = false;
  Base64File: string = '';
}

export default UpdateDocumentsModal;
