import { useNavigate, useLocation } from "react-router-dom";

const DrawerItem = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nav = (route: any) => {
    navigate(route);
    props.onChange();
  };
  return (
    <div
      className={
        location.pathname === props.route
          ? "Navigation-drawerItem active"
          : "Navigation-drawerItem"
      }
      onClick={() => nav(props.route)}
    >
      <div className="Navigation-Icon2">{props.Icon}</div>
      <span className="Navigation-text1">{props.name}</span>
    </div>
  );
};

export default DrawerItem;
