const DefaultValueEnum = {
    EmptyGuid: "00000000-0000-0000-0000-000000000000",
    EmptyString: "",
    StringAsValue: "string",
}

const ApplicationStatusNameEnum = {
    ALL: 0,
    ACTIVE: 1,
    COMPLETED: 2,
    CANCELLED: 3
}


const DateRangeEnum = {
    Today: "Today",
    ThisWeek: "ThisWeek",
    ThisMonth: "ThisMonth",
    ThisYear: "ThisYear",
    Yesterday: "Yesterday",
    LastWeek: "LastWeek",
    LastMonth: "LastMonth",
    LastYear: "LastYear",
    NextWeek: "NextWeek",
    NextMonth: "NextMonth",
    NextYear: "NextYear",
    Last7days: "Last7days",
    Last30Days: "Last30Days",
    Custom: "Custom",
    All: "All"
}

const MenuShortNameEnum = {
    DOCUMENT_JOB: "DOCUMENT_JOB",
    DOCUMENT_ATTACHMENT: "DOCUMENT_ATTACHMENT",
}

const DocumentAttachmentStatus =
{
    OPEN: 0,
    APPROVED: 1,
    DISAPPROVED: 2
}

export {
    DefaultValueEnum,
    DateRangeEnum,
    MenuShortNameEnum,
    ApplicationStatusNameEnum,
    DocumentAttachmentStatus
}

