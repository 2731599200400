import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
const RadioBoxButton = (props: any) => {
  return (
    <div
      className={`RadioBoxBtn ${props.value === props.text ? "active" : ""}`}
      style={{ height: 40 }}
      onClick={() => props.select(props.text)}
    >
      <div>
        {props.value === props.text ? (
          <MdCheckBox size={20} style={{ marginRight: 10 }} />
        ) : (
          <MdCheckBoxOutlineBlank size={20} style={{ marginRight: 10 }} />
        )}
      </div>
      <div>{props.text}</div>
    </div>
  );
};

export default RadioBoxButton;
