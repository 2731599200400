const styles = {
  btn: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  input: {
    height: 40,
  },
  input_Icon: {
    fontSize: 18,
    color: "#BBBBBB",
  },
};

export default styles;
