import React from "react";

function Tabs(props: any) {
  let selectedValue = props.defaultValue;
  return (
    <div
      className={`Tabs ${props.value === selectedValue ? "active" : ""}`}
      onClick={() => props.select(props.value)}
    >
      <div className="Tabs-txt1">{props.text} ({props.count ? (props.count) : (0)})</div>
    </div>
  );
}

export default Tabs;
