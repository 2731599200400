import { Modal, Form, message } from "antd";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { ConditionCreator } from "wherecomposer/dist/ConditionCreator";
import { SearchOperationBuilder, SearchParam } from "wherecomposer/dist/SearchParam";
import { getAttachmentStatusHistory } from "../../Services/attachment-service";
import { Tag } from "antd";
import styles from "./styles";

const StatusHistoryModal = (props: any) => {

  const [documentAttachmentGuid, setDocumentAttachmentGuid] = useState('');
  const [attachementStatusHistory, setAttachementStatusHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDocumentAttachmentGuid(props.props.DocumentAttachmentGuid);
    getAttchemntHistory(props.props.DocumentAttachmentGuid);
  }, []);

  const getAttchemntHistory = async (documentAttachmentGuid: string) => {
    let lstobjSearchParam = [];
    setLoading(true);

    let objReferenceTypeSearchParam = new SearchParam();

    objReferenceTypeSearchParam.searchParamCreate("DocumentAttachmentGuid", "Guid", documentAttachmentGuid, SearchOperationBuilder.create(true, false, false));
    lstobjSearchParam.push(objReferenceTypeSearchParam);

    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(lstobjSearchParam);

    let inputAttachmentViewModel = {
      "OperationList": objWhere.toJsonString()
    }

    await getAttachmentStatusHistory(inputAttachmentViewModel)
      .then(async function (response) {
        let attchemntHistoty = response;

        if (attchemntHistoty != null && attchemntHistoty != undefined && attchemntHistoty.length > 0) {
          attchemntHistoty.forEach((stateObj: any) => {
            if (stateObj.hasOwnProperty('CreatedDate')) {
              stateObj["CreatedDate"] = new Date(stateObj["CreatedDate"]).toLocaleString();
            }
          });
        }
        else {
          attchemntHistoty = [];
        }

        setAttachementStatusHistory(attchemntHistoty);
        setLoading(false);
      })
      .catch(function (error) {
        console.log('error', error);
        message.error(error);
        setLoading(false);
      });
  }

  return (
    <Modal
      width={650}
      title={<div className="SearchPopup-title">Status Change History</div>}
      open={props.visible}
      onOk={() => props.close()}
      onCancel={() => props.close()}
      footer={false}
    >
      <Form>
        <div className="height_100P">
          <div className="width_100 display_flex height_100P">
            <div
              className="display_flex flex_column width_100 padding_lr_20 height_100P overflow_y_auto overflow_x_hidden">
              <div className="display_flex flex_row height_100P width_100">
                <div className="display_flex width_100">
                  <div className="ApplicationDetails-txt2">
                    Status
                  </div>
                </div>
                <div className="display_flex width_100">
                  <div className="ApplicationDetails-txt2">
                    Modified Date
                  </div>
                </div>
                <div className="display_flex width_100">
                  <div className="ApplicationDetails-txt2">
                    Changed By
                  </div>
                </div>
              </div>

              {loading && props.visible ? (
                <>
                  <div className="spinning_box">
                    <CircularProgress style={{ color: "#4acb70" }} />
                    <br />
                    <div>Loading . . .</div>
                  </div>
                </>
              ) : (
                <div className="display_flex flex_column history_content width_100 margin_top_20">
                  {attachementStatusHistory?.map((item: any, index) => {
                    return (
                      <div className="width_100">
                        {item.NewStatusID == 1 ? (
                          <div className="display_flex flex_row" style={{ flex: 3 }}>
                            <div className="display_flex width_100">
                              <div className="ApplicationDetails-txt10">
                                <Tag
                                  style={{ fontSize: "10px", backgroundColor: styles.approvedStatus.backgroundColor, border: styles.approvedStatus.border, width: styles.historyStatus.width, textAlign: "center" }}>
                                  Approved
                                </Tag>
                              </div>
                            </div>
                            <div className="display_flex width_100">
                              <div className="ApplicationDetails-txt10">
                                {item.CreatedDate}
                              </div>
                            </div>
                            <div className="display_flex width_100">
                              <div className="ApplicationDetails-txt10">
                                {item.CreatedBy}
                              </div>
                            </div>
                          </div>
                        ) : (<div>
                          {item.NewStatusID == 2 ? (
                            <div className="display_flex flex_row" style={{ flex: 3 }}>
                              <div className="display_flex width_100">
                                <div className="ApplicationDetails-txt10">
                                  <Tag
                                    style={{ fontSize: "10px", backgroundColor: styles.declinedStatus.backgroundColor, border: styles.declinedStatus.border, width: styles.historyStatus.width, textAlign: "center" }}>
                                    Disapproved
                                  </Tag>
                                </div>
                              </div>
                              <div className="display_flex width_100">
                                <div className="ApplicationDetails-txt10">
                                  {item.CreatedDate}
                                </div>
                              </div>
                              <div className="display_flex width_100">
                                <div className="ApplicationDetails-txt10">
                                  {item.CreatedBy}
                                </div>
                              </div>
                            </div>
                          ) : (<div className="display_flex flex_row" style={{ flex: 3 }}>
                            <div className="display_flex width_100">
                              <div className="ApplicationDetails-txt10">
                                <Tag
                                  style={{ fontSize: "10px", backgroundColor: styles.openStatus.backgroundColor, border: styles.openStatus.border, width: styles.historyStatus.width, textAlign: "center" }}>
                                  Open
                                </Tag>
                              </div>
                            </div>
                            <div className="display_flex width_100">
                              <div className="ApplicationDetails-txt10">
                                {item.CreatedDate}
                              </div>
                            </div>
                            <div className="display_flex width_100">
                              <div className="ApplicationDetails-txt10">
                                {item.CreatedBy}
                              </div>
                            </div>
                          </div>)
                          }
                        </div>)}

                        <div style={{ margin: "3.5%" }} />

                      </div>)
                  })
                  }
                </div>
              )
              }
            </div>
          </div>
        </div>
      </Form >
    </Modal >
  );
};

export default StatusHistoryModal;
