import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import LanguageSlice from "./LanguageSlice";
import TokenSlice from "./TokenSlice";

const Slices = combineReducers({
  Auth: AuthSlice.reducer,
  Language: LanguageSlice.reducer,
  Token: TokenSlice.reducer,
});

export default Slices;
