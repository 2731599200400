import React from "react";
import { Card } from "antd";
import { MdArrowBack } from "react-icons/md";
import GroupTable from "./GroupTable";
import { useNavigate } from "react-router-dom";
const ShipmentDetails = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="Screen-Container">
      <div className="Screen-Header">
        <div className="Screen-Headertxt1">
          <MdArrowBack
            size={30}
            style={{ marginRight: 15 }}
            onClick={() => navigate(-1)}
          />{" "}
          Shipments Details <span className="Formtxt10">( SHP2426527 )</span>
        </div>
      </div>
      <div className="Screen-box">
        <Card
          style={{ borderRadius: 10 }}
          title={
            <div>
              <div className="Formtxt11">Groups</div>
            </div>
          }
        >
          <GroupTable />
        </Card>
      </div>
    </div>
  );
};
export default ShipmentDetails;
