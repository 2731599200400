import axios from "axios";
import { Auth } from "aws-amplify";
import API from "../Config/API";
import { SearchOperationBuilder, SearchParam } from 'wherecomposer/dist/SearchParam';
import { ConditionCreator } from 'wherecomposer/dist/ConditionCreator';
import { MenuShortNameEnum } from '../Config/default-value-enum';
import { getUserSelectedLanguage } from "../Services/cognito-token-service";

const getToken = async () => {
  let idToken = localStorage.getItem('authenticationToken');
  return idToken;
};

const searchAttachment = async (documentJobGuid) => {

  let storedArray = getUserSelectedLanguage();
  const languageGuid = storedArray.LanguageGuid;
  const languageCode = storedArray.Code;
  let lstobjSearchParam = [];

  let objInbountTypeSearchParam = new SearchParam();
  let objOutbountTypeSearchParam = new SearchParam();
  let objReferenceGuidSearchParam = new SearchParam();
  let objReferenceTypeSearchParam = new SearchParam();
  let objShowOnPortalSearchParam = new SearchParam();


  objReferenceGuidSearchParam.searchParamCreate("BusinessEntity", "string", MenuShortNameEnum.DOCUMENT_JOB, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceGuidSearchParam);

  objReferenceTypeSearchParam.searchParamCreate("BusinessEntityGuid", "Guid", documentJobGuid, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceTypeSearchParam);

  objShowOnPortalSearchParam.searchParamCreate("ShowOnPortal", "Boolean", "true", SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objShowOnPortalSearchParam);


  let objStartParanthesis = new SearchParam();
  objStartParanthesis.hasMultiParam = true;

  objInbountTypeSearchParam.searchParamCreate("IsInboundFile", "Boolean", "true", SearchOperationBuilder.create(true, false, false), false);
  objStartParanthesis.multiparams.push(objInbountTypeSearchParam);

  objOutbountTypeSearchParam.searchParamCreate("IsOutBoundFile", "Boolean", "true", SearchOperationBuilder.create(true, false, false));
  objStartParanthesis.multiparams.push(objOutbountTypeSearchParam);

  lstobjSearchParam.push(objStartParanthesis);

  let objCreator = new ConditionCreator();
  let objWhere = objCreator.getSearchCondition(lstobjSearchParam);


  let searchParamObj = {
    "OperationList": objWhere.toJsonString(),
  };


  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/SearchDocumentAttachmentFromPortal';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
      'LanguageGuid': languageGuid
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, searchParamObj, headerParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.message);
      });
  });

};

const searchAttachmentByGuid = async (documentAttachmentGuid) => {

  let storedArray = getUserSelectedLanguage();
  const languageGuid = storedArray.LanguageGuid;
  const languageCode = storedArray.Code;
  let lstobjSearchParam = [];


  let objReferenceGuidSearchParam = new SearchParam();
  let objReferenceTypeSearchParam = new SearchParam();

  objReferenceGuidSearchParam.searchParamCreate("BusinessEntity", "string", MenuShortNameEnum.DOCUMENT_JOB, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceGuidSearchParam);

  objReferenceTypeSearchParam.searchParamCreate("DocumentAttachmentGuid", "Guid", documentAttachmentGuid, SearchOperationBuilder.create(true, false, false));
  lstobjSearchParam.push(objReferenceTypeSearchParam);

  let objCreator = new ConditionCreator();
  let objWhere = objCreator.getSearchCondition(lstobjSearchParam);


  let searchParamObj = {
    "OperationList": objWhere.toJsonString(),
  };


  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/SearchDocumentAttachmentFromPortal';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
      'LanguageGuid': languageGuid
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, searchParamObj, headerParam)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.message);
      });
  });

};

const downloadAttachmentFile = async (documentAttachmentObj) => {

  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  delete documentAttachmentObj.ModifiedDate;
  delete documentAttachmentObj.CreatedDate;

  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/DownloadAttachment';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, documentAttachmentObj, headerParam)
      .then((response) => {
        console.log("searchAttachment response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("searchAttachment err", error.response.data);
        reject(error.message);
      });
  });
};

const deleteAttachmentFile = async (documentAttachments) => {
  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/DeleteDocumentAttachmentFromPortal';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, documentAttachments, headerParam)
      .then((response) => {
        console.log("searchAttachment response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("searchAttachment err", error);
        reject(error.message);
      });
  });
};

const createAttachmentFile = async (documentAttachments) => {

  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/CreateDocumentAttachmentS3';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };

  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, documentAttachments, headerParam)
      .then((response) => {
        console.log("searchAttachment response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("searchAttachment err", error.response.data);
        reject(error.message);
      });
  });
};

const updateAttachmentStatus = async (documentAttachmentViewModel) => {
  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/UpdateDocumentAttachmentStatus';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };

  console.log('inputAttachmentViewModel', documentAttachmentViewModel);

  return await new Promise((resolve, reject) => {
    axios
      .put(apiURL, documentAttachmentViewModel, headerParam)
      .then((response) => {
        console.log("approveAttachmentFile response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("approveAttachmentFile err", error);
        reject(error.message);
      });
  });
};

const getAttachmentStatusHistory = async (documentAttachmentViewModel) => {
  let storedArray = getUserSelectedLanguage();
  const languageCode = storedArray.Code;

  let apiURL = API.ATTACHMENT_URL + '/DocumentAttachment/SearchDocumentAttachmentStateHistory';
  let token = await getToken();
  let headerParam = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "language": languageCode,
      "Authorization": token,
    }
  };


  return await new Promise((resolve, reject) => {
    axios
      .post(apiURL, documentAttachmentViewModel, headerParam)
      .then((response) => {
        console.log("approveAttachmentFile response++++++++++", response);
        resolve(response.data);
      })
      .catch(error => {
        console.log("approveAttachmentFile err", error);
        reject(error.message);
      });
  });
};

const downloadFile = async (base64String, fileName, p_szfiletype) => {
  const source = `data:${p_szfiletype};base64,${base64String}`;
  const link = document.createElement("a");
  link.href = source;
  link.download = `${fileName}`
  link.click();
}

export { searchAttachment, downloadAttachmentFile, downloadFile, deleteAttachmentFile, createAttachmentFile, updateAttachmentStatus, getAttachmentStatusHistory, searchAttachmentByGuid };
