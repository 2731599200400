import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LOGO from "../../Assets/Images/logo@2x.png";
import AUCLOGO from "../../Assets/Images/AUC-Logo.png";
import LOGO2 from "../../Assets/Images/Logi-white.svg";
import LOGO3 from "../../Assets/Images/ogefrem80.png";
import { Button, Select, message } from "antd";
import { Row, Col } from "react-bootstrap";
import { save } from "../../Redux/Slices/LanguageSlice";
import axios from "axios";
import styles from "./styles";
import API from "../../Config/API";

const LandingScreen = () => {
  useEffect(() => {
    loadLanguages();
  }, []);

  const [languages, setLanguages] = useState([]);
  const [LanguageID, setLanguageId] = useState(
    "F8C0FDCA-11CA-4321-B207-46A75E12A3E5"
  );

  const loadLanguages = () => {
    axios
      .get(API.GET_LANGUAGES)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setLanguages(data);
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error("Sorry Could'nt load Languages.......");
      });
  };

  let navigate = useNavigate();
  const navHome = () => {
    const selectedLanguageItem = languages.find(
      (item: any) => item.LanguageGuid === LanguageID
    );
    window.sessionStorage.setItem(
      "Language",
      JSON.stringify(selectedLanguageItem)
    );

    localStorage.setItem('Language', JSON.stringify(selectedLanguageItem));
    navigate("/auth");
  };

  const onChange = (id: any) => {
    setLanguageId(id);
  };

  return (
    <div className="landing-screen">
      <Row style={{ width: "100%" }}>
        <Col sm="4" xs="12"></Col>
        <Col sm="4" xs="12">
          <div className="landing-box1">
            <div className="landing-box2">
              <img src={AUCLOGO} width="80"/>
            </div>
            <div style={{ margin: 10 }} />
            <div className="landing-box2">
              <img src={LOGO3} width="76" />
            </div>
          </div>
          <br />
          <br />
          <div className="landing-txt4">AFRICA UNION CARGO</div>
          <div className="landing-txt5">NAMIBIA</div>
          <br />
          <Select
            size="small"
            placeholder="Select Language"
            style={styles.Select}
            defaultValue={"English"}
            onChange={onChange}
          >
            {languages.map((item: any, index: any) => {
              return (
                <Select.Option key={item.LanguageGuid}>
                  {item.Name}
                </Select.Option>
              );
            })}
          </Select>
          <div className="landing-txt2"></div>
          <Button size="large" onClick={() => navHome()} style={styles.btn1}>
            Get Me In
          </Button>
          <div className="landing-txt3">
            © 2023 | All rights reserved.
          </div>
          <div className="landing-txt6">
            Powered By
            <span style={{ margin: 3 }}></span>
            <img src={LOGO2} />
          </div>
        </Col>
        <Col sm="4" xs="12"></Col>
      </Row>
    </div>
  );
};

export default LandingScreen;
