import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Card, Form, Input, Button, Alert, message } from "antd";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import styles from "./styles";
import { Store } from 'antd/lib/form/interface';
import { getLoginUserPoolInformation, resendTemporaryPasswordWithoutLogin } from "../../Services/tenant-identity-service";
import { getMessages, getValueMessages } from "../../Services/language-service";
import awsPoolConfiguration from "../../Config/aws-exports";
import { MESSAGE } from "../../Config/message-enum";
import { Auth, Amplify } from "aws-amplify";
import { USER_ROLE, USER_STATUS } from "../../Enum/user-role-enum";
// import { v4 as uuidv4 } from 'uuid';

interface ForgotPasswordFormValue {
  email: "",
  confirmationCode: "",
  newPassword: "",
}

enum FORM_TYPE {
  SEND_OTP = 'SEND_OTP',
  CONFIRM_OTP = 'CONFIRM_OTP'
}

function ForgotPasswordScreen() {
  const navigate = useNavigate();
  let timer: any = null;
  const [success, setSuccess] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingResetBtn, setIsLoadingResetBtn] = useState(false);
  const [formType, setFormType] = useState(FORM_TYPE.SEND_OTP);
  const [form] = Form.useForm<ForgotPasswordFormValue>();

  useEffect(() => {

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleFinish = (values: Store) => {
    let formValue = values as ForgotPasswordFormValue;

    if (formType === FORM_TYPE.SEND_OTP) {
      sentVerificationCode(formValue);
    } else {
      confirmVerificationCode(formValue)
    }
  };

  const validateConfirmPassword = ({ getFieldValue }: { getFieldValue: Function }) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords that you entered do not match!'));
    },
  });

  const emailValidator = (rule: any, value: string) => {
    return new Promise((resolve, reject) => {
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;

      if (value && !emailRegex.test(value)) {
        reject('Please enter a valid email address');
      } else {
        resolve(undefined);
      }
    });
  };

  const sentVerificationCode = async (formValue: ForgotPasswordFormValue) => {
    try {
      let userResult = [];
      let currentLanguage = 'en';
      setIsLoading(true);
      console.log('sentVerificationCode formValue', formValue);
      userResult = await getLoginUserPoolInformation(formValue.email);
      console.log('sentVerificationCode userResult', userResult);

      if (userResult.length > 0) {
        let userData = userResult[0];
        let userRole = userData.role;
        let tenantResult = userData.Tenant;
        let userStatus = userData.userConfirmed;

        if (userRole === USER_ROLE.ROLE_CUSTOMER) {
          if (userStatus === USER_STATUS.FORCE_CHANGE_PASSWORD) {
            let resendTemporaryPassswordResponse = {};
            let resendPasswordObj = {
              'email': formValue.email
            }

            resendTemporaryPassswordResponse = await resendTemporaryPasswordWithoutLogin(resendPasswordObj);
            console.log('resendTemporaryPassswordResponse', JSON.stringify(resendTemporaryPassswordResponse));

            let validationMessage = await getMessages(MESSAGE.TEMPORARY_PASSWORD_RESEND_MESSAGE, currentLanguage);
            message.success(validationMessage);
            timer = setTimeout(() => navigate('/login'), 3000);
            setIsLoading(false);
          } else if (userStatus === USER_STATUS.CONFIRMED) {
            awsPoolConfiguration.aws_project_region = tenantResult.region;
            awsPoolConfiguration.aws_cognito_identity_pool_id = tenantResult.identityPoolId;
            awsPoolConfiguration.aws_cognito_region = tenantResult.region;
            awsPoolConfiguration.aws_user_pools_id = tenantResult.userPoolId;
            awsPoolConfiguration.aws_user_pools_web_client_id = tenantResult.clientId;

            console.log('awsPoolConfiguration+++++++++++', JSON.stringify(awsPoolConfiguration));

            Amplify.configure(awsPoolConfiguration);
            Auth.forgotPassword(userData.email).then(async (data) => {
              console.log('forgotPassword result', JSON.stringify(data));
              let result = data;
              let deliveryMedium = result.CodeDeliveryDetails.DeliveryMedium;
              let destination = result.CodeDeliveryDetails.Destination;
              let validationMessage = "";

              let valueFields = { V1: destination };
              validationMessage = await getValueMessages(MESSAGE.OTP_SENT_TO_MAIL, currentLanguage, JSON.stringify(valueFields));
              message.success(validationMessage);
              setCodeSent(true);
              setIsLoading(false);
            }).catch((err) => {
              console.log('forgotPassword error', err);
              message.error(err.message);
              setIsLoading(false);
            })
          } else {
            let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
            message.error(validationMessage);
            setIsLoading(false);
          }
        } else {
          let validationMessage = await getMessages(MESSAGE.RESTRICT_LOGIPULSE_WEB_APPLICATION_USER, currentLanguage);
          message.error(validationMessage);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
        message.error(validationMessage);
      }
    } catch (error: any) {
      console.log('sentVerificationCode error+++++++', JSON.stringify(error));
      let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
      message.error(errorMessage);
      setIsLoading(false);
    }
  };

  const confirmVerificationCode = async (formValue: ForgotPasswordFormValue) => {
    try {
      console.log('confirmverificationCode formValue++++++', formValue);
      let currentLanguage = 'en';
      setIsLoadingResetBtn(true);
      Auth.forgotPasswordSubmit(
        formValue.email,
        formValue.confirmationCode,
        formValue.newPassword
      ).then(async (data) => {
        let validationMessage = await getMessages(MESSAGE.PASSWORD_RESET_SUCCESS_MESSAGE, currentLanguage);
        message.success(validationMessage);
        setIsLoadingResetBtn(false);
        navigate("/login");
      }).catch((err) => {
        console.log('confirmation err+++++', err);
        setIsLoadingResetBtn(false);
        message.error(err.message);
      });
    } catch (error: any) {
      console.log('confirmVerificationCode error+++++++', error);
      message.error(error.message);
      setIsLoadingResetBtn(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="home-screen">
        <Container>
          <br />
          <div className="page-header-box">
            <div>User Account</div>
          </div>
          <Row>
            <Col sm="4" md="4" xs="12"></Col>
            <Col sm="4" md="4" xs="12">
              {success ? (
                <Alert
                  message={
                    <div className="FormSuccessBox2">
                      Password Reset SuccessFully
                      <TiTickOutline color="58a52c" />
                    </div>
                  }
                  type="success"
                />
              ) : null}
              <br /> <br />
              <Card
                style={{ borderRadius: 5, padding: 10 }}
                title={
                  <div>
                    <div className="Formtxt1">Forgot Password</div>
                  </div>
                }
              >
                <Form form={form} onFinish={handleFinish}>
                  <Fragment>
                    <div
                      className="NewApplication-txt10"
                      style={{ marginBottom: "2.19%" }}
                    >
                      Email
                    </div>

                    <Form.Item name="email" rules={[
                      { required: true, message: 'Please input your email!' },
                      // { type: 'email', message: 'Please enter a valid email address' },
                      { validator: emailValidator }
                    ]}>
                      <Input style={styles.input} placeholder="Email" disabled={codeSent} />
                    </Form.Item>

                    {
                      !codeSent ? (
                        <Form.Item>
                          <Button
                            block
                            type="primary"
                            size="small"
                            style={{ width: 150 }}
                            htmlType="submit"
                            onClick={() => setFormType(FORM_TYPE.SEND_OTP)}
                            loading={isloading}
                          >
                            Send OTP
                          </Button>
                        </Form.Item>) : null}
                  </Fragment>

                  {
                    codeSent ? (
                      <Fragment>
                        <div
                          className="NewApplication-txt10"
                          style={{ marginBottom: "2.19%" }}
                        >
                          OTP
                        </div>
                        <Form.Item name="confirmationCode" rules={[
                          {
                            required: true,
                            message: "Please enter your OTP",
                          },
                        ]}>
                          <Input
                            style={styles.input}
                            placeholder="OTP"
                            suffix={
                              <MdOutlineVerifiedUser style={styles.input_Icon} />
                            }
                          />
                        </Form.Item>
                        <div
                          className="NewApplication-txt10"
                          style={{ marginBottom: "2.19%" }}
                        >
                          Password
                        </div>

                        <Form.Item name="newPassword" rules={[
                          {
                            required: true,
                            pattern:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message:
                              "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
                          }
                        ]}>
                          <Input.Password
                            style={styles.input}
                            placeholder="Password"
                          />
                        </Form.Item>
                        <div
                          className="NewApplication-txt10"
                          style={{ marginBottom: "2.19%" }}
                        >
                          Confirm Password
                        </div>
                        <Form.Item name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                              message:
                                "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
                            },
                            validateConfirmPassword,
                          ]}>
                          <Input.Password
                            style={styles.input}
                            placeholder="Confirm password"
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button
                            block
                            type="primary"
                            size="small"
                            style={{ width: 150 }}
                            htmlType="submit"
                            loading={isloadingResetBtn} onClick={() => setFormType(FORM_TYPE.CONFIRM_OTP)}
                          >
                            Reset Password
                          </Button>
                        </Form.Item>
                      </Fragment>
                    ) : null
                  }
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
      <Footer />
    </div >
  );
}

export default ForgotPasswordScreen;
