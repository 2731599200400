import { Card, Button } from "antd";
import { MdDownload } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
const QuoteSuccess = (props: any) => {
  return (
    <Card
      style={{ borderRadius: 5, padding: 10 }}
      title={
        <div>
          <div className="Formtxt1">Response</div>
        </div>
      }
    >
      <div className="FormSuccessBox">
        Your request has been successfully submitted!
      </div>
      <br />
      <div className="Formtxt5">
        We'll send you the quotation to your email shortly. Nam fer mentum
        lacinia dui id elementum. Donec vestibulum ligula non sagittis accumsan.
        Nullam lacinia fermentum purus.
      </div>
      <br />
      <Row>
        <Col sm="4">Request ID</Col>
        <Col sm="4">
          <b>RQ132424335535</b>
        </Col>
        <Col sm="4"></Col>
        <Col sm="4">Registered Email</Col>
        <Col sm="4">
          <b>email@domain.com</b>
        </Col>
        <Col sm="4"></Col>
        <Col sm="4">Date</Col>
        <Col sm="4">
          <b>12 December 2022</b>
        </Col>
      </Row>
      <br />
      <div className="Formtxt5">
        Donec vestibulumligula non sagittis accumsan.Nullam lacinia fermentum
        purus. Aliquam sed quam egestas,
      </div>
      <br />
      <Button
        icon={<MdDownload size={20} style={{ marginRight: 10 }} />}
        type="link"
      >
        Download receipt
      </Button>
    </Card>
  );
};

export default QuoteSuccess;
