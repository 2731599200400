import React from "react";

function CardCount(props: any) {
  return (
    <div key={props.keyValue}
      className="CardCount"
      style={{
        background: props.bgColor,
        border: `1px solid ${props.borderColor}`,
      }}
    >
      <div className="CardCount-txt1">{props.count}</div>
      <div className="CardCount-txt2">{props.label}</div>
    </div>
  );
}

export default CardCount;
