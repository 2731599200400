import React, { useState, useEffect, Fragment } from "react";
import { Card, Checkbox, Input, Button, Form, message, Upload } from "antd";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import API from "../../Config/API";
import RadioIconButton from "./components/RadioIconButton";
import styles from "./styles";
import LoadingBox from "../../Components/LoadingBox";
import CircularProgress from "@mui/material/CircularProgress";
import { MdVerified, MdCloudUpload, MdArrowBack, MdOutlineDelete } from "react-icons/md";
import { getDocumentCategory, addDocumentJobFromPortal, getS3UploadUrl, uploadDocumentToS3 } from "../../Services/document-job-service";
import { getCustomer } from "../../Services/customer-service";
import { RuleObject, Rule } from "antd/lib/form";
import { FIELD_NAME } from '../../Config/field-name-enum';
import { getFieldConsoleMessages, getValueMessages } from "../../Services/language-service";
import { MESSAGE } from "../../Config/message-enum";
import { getTokenDetailsByDecoding } from "../../Services/cognito-token-service";
import { DefaultValueEnum, MenuShortNameEnum } from "../../Config/default-value-enum";
import { LoadingOutlined } from '@ant-design/icons';

const initialFormValue = {
  "ID": 0,
  "CustomerGuid": DefaultValueEnum.EmptyGuid,
  "GroupGuid": DefaultValueEnum.EmptyGuid,
  "LocationGuid": DefaultValueEnum.EmptyGuid,
  "CurrencyGuid": DefaultValueEnum.EmptyGuid,
  "Name": "",
  "EmailAddress": "",
  "CellFullNumber": "",
  "IsCompany": false,
  "CompanyName": "",
  "PrimaryAddress": "",
}


let attachmentList: any[] = [];
function NewApplication() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFormSubmitLoader, setFormSubmitLoader] = useState(false);
  const [success, setSucess] = useState(false);
  const [mode, setMode] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [category, setCategory] = useState([]);
  const [InboundDocumentTypes, SetInboundDocumentTypes] = useState([]);
  const [CategoryLanguageGroupGuid, setCategoryLanguageGroupGuid] = useState(DefaultValueEnum.EmptyGuid);
  const [Index, setIndex] = useState(0);
  const [attachmentlist, setAttachmentlist] = useState([] as any);
  const [isChecked, setIsChecked] = useState(false);
  const [customerData, setCustomerData] = useState(initialFormValue);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const { Dragger } = Upload;
  const { TextArea } = Input;



  useEffect(() => {
    attachmentList = [];
    setAttachmentlist([]);
    loadDocumentCategory();
    searchCustomer();
  }, []);

  function handleCheckboxChange(e: any) {
    setIsChecked(e.target.checked);
  }

  const loadDocumentCategory = async () => {
    setLoading(true);
    getDocumentCategory()
      .then(async function (data) {
        setCategory(data);
        if (data.length > 0) {

          let initialCategoryObj = data[0];
          let initialDocumentTypeList = initialCategoryObj.InboundDocumentTypes;

          for (let i = 0; i < initialDocumentTypeList; i++) {
            attachmentList.push(0);
          }

          await setMode(initialCategoryObj.DisplayName);
          await setCategoryLanguageGroupGuid(initialCategoryObj.LanguageGroupGuid);
          await SetInboundDocumentTypes(initialDocumentTypeList);
        }
        setLoading(false);

      })
      .catch(function (error) {
        console.log(error);
        message.error("Could'nt Complete the request ...");
        setLoading(false);
      });
  };

  const AddToAttachmentList = (file: any, fileName: any, item: any, index: any) => {
    let obj = {
      id: item.ID,
      Title: file.name,
      FileName: file.name,
      FileType: file.type,
      DocumentTypeGuid: item.LanguageGroupGuid,
      SavedS3Location: fileName,
    };

    attachmentList[index] = obj;
    setAttachmentlist(attachmentList);
  };

  const onSelectCategory = (value: any, item: any) => {
    attachmentList = [];
    setAttachmentlist([]);
    setMode(value);
    SetInboundDocumentTypes(item.InboundDocumentTypes);
    setCategoryLanguageGroupGuid(item.LanguageGroupGuid);

    for (let i = 0; i < item.InboundDocumentTypes.length; i++) {
      attachmentList.push(0);
    }
  };

  const searchCustomer = () => {
    setLoading(true);
    getCustomer()
      .then(async function (response: any) {
        setCustomerData(response);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Could'nt Complete the request ...");
        setLoading(false);
      });
  }

  function _base64ToArrayBuffer(base64: any) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result
            .toString()
            .replace("data:", "")
            .replace(/^.+,/, "");
          resolve(base64String);
        }
      };
      reader.readAsDataURL(file);
    });

  const validateRequestData = async (customerData: Customer) => {
    try {
      const currentLanguageCode = 'en';
      let inValidList = [];
      let validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let bCanContinue = true;

      if (customerData.Name === null || customerData.Name === "" || customerData.Name === undefined) {
        inValidList.push(FIELD_NAME.FULL_NAME);
        bCanContinue = false;
      }

      if (customerData.EmailAddress === null || customerData.EmailAddress === "" || customerData.EmailAddress === undefined) {
        inValidList.push(FIELD_NAME.EMAIL);
        bCanContinue = false;
      }

      if (customerData.CellFullNumber === null || customerData.CellFullNumber === "" || customerData.CellFullNumber === undefined) {
        inValidList.push(FIELD_NAME.CONTACT_NUMBER);
        bCanContinue = false;
      }

      // if (customerData.CompanyName === null || customerData.CompanyName === "" || customerData.CompanyName === undefined) {
      //   inValidList.push(FIELD_NAME.COMPANY_NAME);
      //   bCanContinue = false;
      // }

      // if (customerData.PrimaryAddress === null || customerData.PrimaryAddress === "" || customerData.PrimaryAddress === undefined) {
      //   inValidList.push(FIELD_NAME.CONTACT_ADDRESS);
      //   bCanContinue = false;
      // }

      if (bCanContinue === false) {
        let validationResponse = await getFieldConsoleMessages(MESSAGE.VALIDATION_EXCEPTION, currentLanguageCode, inValidList);
        message.error(validationResponse);
      }
      else {
        if (validEmailRegex.test(customerData.EmailAddress) === false) {
          bCanContinue = false;
          let email = customerData.EmailAddress;
          let valueFields = { V1: email };
          let validationMessage = await getValueMessages(MESSAGE.INVALID_EMAIL_MESSAGE, currentLanguageCode, JSON.stringify(valueFields));
          message.error(validationMessage);
        }
      }

      return inValidList;
    } catch (error) {
      console.log('validateRequestData error', error);
    }
  }

  const submitDocument = async () => {
    if (isChecked) {
      let isValid = await validateRequestData(customerData);

      if (isValid) {
        setFormSubmitLoader(true);
        let decodedToken = getTokenDetailsByDecoding();
        let locationGuid = decodedToken?.locationGuid;
        let uploadedList = attachmentlist.filter((obj: any) => {
          return obj != 0
        })

        let inputData = {
          "DocumentJob": {
            "BusinessNo": "",
            "DocumentCategoryGuid": CategoryLanguageGroupGuid,
            "DocumentStatusGuid": API.REACT_APP_OPEN_STATUS,
            "Customer": {
              "DocumentJobCustomerName": customerData.Name,
              "DocumentJobCustomerAddress": customerData.PrimaryAddress,
              "DocumentJobCustomerCompanyName": customerData.CompanyName,
              "DocumentJobCustomerCompanyAddress": customerData.PrimaryAddress,
              "DocumentJobCustomerCellPhoneNumber": customerData.CellFullNumber,
              "DocumentJobCustomerEmailAddress": customerData.EmailAddress,
            },
            "CreatedLocationGuid": locationGuid,
            "IsFromPortal": true,
          },
          "AttachmentList": uploadedList,
        };

        addDocumentJobFromPortal(inputData)
          .then(function (response) {
            setFormSubmitLoader(false);
            message.success("Successfully Created");
            navigate("/auth/applications");
          })
          .catch(function (error) {
            console.log(error);
            setFormSubmitLoader(false);
          });

      }
    } else {
      message.error("Please accept terms and conditions before you submit the application.");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, [event.target.name]: event.target.value.trim() })
  }

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomerData({ ...customerData, [event.target.name]: event.target.value.trim() })
  };

  //#region upload document
  function handleClick(item: any, index: number) {
    setIndex(index);
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*, application/pdf";
    input.addEventListener("change", (event: any) =>
      handleImageChange(event, item, index)
    );
    input.click();
  }

  async function handleImageChange(event: any, item: any, index: any) {
    let file = event.target?.files?.[0];
    let decodedToken = getTokenDetailsByDecoding();
    let tenantGuid = decodedToken?.tenantGuid;
    let tenantUserGuid = decodedToken?.tenantUserGuid;

    const fileName = `${tenantGuid}/${tenantUserGuid}/${moment().unix()}/${MenuShortNameEnum.DOCUMENT_JOB}/${file?.name}`;

    await fileToDataUri(file)
      .then(async (dataUri) => {
        let bufff = _base64ToArrayBuffer(dataUri);
        await getS3UpUrl(file, fileName, bufff, item, index);
      })
      .catch(function (error) {
        console.log("handleImageChange error ==========>", error);
      });
  }

  const getS3UpUrl = async (file: any, fileName: any, buffer: any, item: any, index: any) => {
    setAttachmentLoading(true);
    getS3UploadUrl(fileName)
      .then(async function (response) {
        let url = response;
        await uploadToS3(file, url, fileName, buffer, item, index);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadToS3 = async (file: any, url: any, fileName: any, buffer: any, item: any, index: any) => {
    let URL = url.S3PutURL;
    uploadDocumentToS3(URL, buffer)
      .then(async function (response) {
        await AddToAttachmentList(file, fileName, item, index);
        setAttachmentLoading(false);
        setSucess(true);
        message.success("Document uploaded successfully.");
      })
      .catch(function (error) {
        console.log(error);
        setAttachmentLoading(false);
      });
  };

  const deleteUploadedDocument = async (item: any, index: any) => {
    if (attachmentList.length > 0) {
      let tempAttachmentList = JSON.parse(JSON.stringify(attachmentlist))
      tempAttachmentList.splice(index, 1);
      setAttachmentlist(tempAttachmentList);
    }
  }

  const resetFormData = (event: React.MouseEvent<HTMLButtonElement>) => {
    // event.preventDefault();
    // setIsChecked(false);
    // // for (let i = 0; i < InboundDocumentTypes.length; i++) {
    // //   attachmentList.push(0);
    // // }

    // // setAttachmentlist(attachmentList);
    // console.log('resetFormData @@@@##$$%%%', InboundDocumentTypes);
  }

  //#endregion

  return loading ? (
    <LoadingBox />
  ) : (
    <div className="Screen-Container">
      <div className="Screen-Header3">
        <div className="Screen-Headertxt1">
          <MdArrowBack
            size={25}
            style={{ marginRight: 15 }}
            onClick={() => navigate(-1)}
            className="Screen-HeaderIcon1"
          />{" "}
          New Application
        </div>
      </div>
      <div className="Screen-box2">
        <Form onFinish={submitDocument}>
          <Row>
            <Col xs="12" sm="8" md="8">
              <div className="NewApplication-box1">
                {category.length &&
                  category?.map((item: any, index) => {
                    return (
                      <RadioIconButton
                        text={item.DisplayName}
                        value={mode}
                        icon={item.IconName}
                        keyValue={index}
                        select={(value: any) => onSelectCategory(value, item)}
                      />
                    );
                  })}
              </div>
              <div style={{ marginBottom: "15px" }} />
              <div className="" style={{ padding: "0px 6px" }}>
                <Card
                  style={{
                    borderRadius: 10,
                  }}
                >
                  <div className="scrollable-body">
                    <div className="NewApplication-txt11">
                      Upload your documents
                    </div>
                    <div style={{ margin: "1%" }} />
                    <div className="NewApplication-txt9">
                      Acceptable formats: PDF, DOCX, XLSX, PPTX, JPG, DOC, XLS,
                      PPT, JPEG, PNG <br />
                      File Size: Min. 100kb. Max. 500kb
                    </div>
                    <div style={{ margin: "1.9%" }} />
                    {InboundDocumentTypes.length &&
                      InboundDocumentTypes.map((item: any, index: any) => {
                        return (

                          <div className="" key={index}>

                            <div className="NewApplication-txt2">
                              {index + 1}.Upload Your {item.Name}
                            </div>

                            <div style={{ margin: "1%" }} />
                            <div className="display_flex flex_row flex_align_center">
                              <div
                                className="NewApplication-box3 margin_right_20"
                                contentEditable={true}
                                data-image-url={imageUrl}
                                suppressContentEditableWarning={true}
                                onClick={() => handleClick(item, index)}
                                style={{ outline: "none", display: "flex" }}
                              >

                                <div className="display_flex flex_column flex_align_center align_center">
                                  {attachmentLoading && index === Index ? (
                                    <>
                                      <CircularProgress
                                        style={{ color: "#4acb70" }}
                                      />
                                    </>
                                  ) : attachmentlist[index] && attachmentlist[index] != 0 ? (
                                    <>
                                      <MdVerified size={25} color="#4acb70" />

                                      <div className="NewApplication-txt3">
                                        {attachmentlist[index].FileName}
                                      </div>

                                    </>
                                  ) : (
                                    <>
                                      <MdCloudUpload size={30} color="#ccc" />
                                      <div className="NewApplication-txt3 ">
                                        Select File
                                      </div>
                                    </>
                                  )}
                                </div>

                                {/* <Fragment>
                                  {
                                    attachmentlist[index] && attachmentlist[index] != 0 ? (
                                      <MdOutlineDelete className="Screen-HeaderIcon1" size={25} color="#000000" onClick={() => deleteUploadedDocument(item, index)} />
                                    ) : null
                                  }
                                </Fragment> */}
                              </div>

                              <div>
                                {
                                  attachmentlist[index] && attachmentlist[index] != 0 ? (
                                    <MdOutlineDelete className="Screen-HeaderIcon1" size={25} color="#000000" onClick={() => deleteUploadedDocument(item, index)} />
                                  ) : null
                                }
                              </div>
                            </div>
                            <div style={{ margin: "1.9%" }} />
                          </div>
                        );
                      })}
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs="12" sm="4" md="4">
              <Card
                style={{
                  borderRadius: 10,
                  margin: "0px 10px 0px -10px",
                }}
              >
                <>
                  <div className="" style={{ minHeight: "56.6vh" }}>
                    <div className="NewApplication-txt5">Contact Person</div>
                    <div style={{ margin: "3.5%" }} />
                    <div className="NewApplication-txt10">Full Name</div>
                    <div style={{ margin: "3%" }} />
                    <Form.Item
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Data" style={styles.input} name="Name" required value={customerData.Name} onChange={handleChange} />
                    </Form.Item>
                    <div style={{ margin: "3%" }} />
                    <div className="NewApplication-txt10">Email</div>
                    <div style={{ margin: "3%" }} />
                    <Form.Item
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Data" style={styles.input} name="EmailAddress" required value={customerData.EmailAddress} onChange={handleChange} />
                    </Form.Item>
                    <div style={{ margin: "3%" }} />
                    <div className="NewApplication-txt10">Contact Number</div>
                    <div style={{ margin: "3%" }} />
                    <Form.Item
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Data" type="number" style={styles.input} name="CellFullNumber" required value={customerData.CellFullNumber} onChange={handleChange} />
                    </Form.Item>
                    <div style={{ margin: "3.9%" }} />
                    <div className="NewApplication-txt5">Business Info</div>
                    <div style={{ margin: "3%" }} />
                    <div className="NewApplication-txt10">Company Name</div>
                    <div style={{ margin: "3%" }} />
                    <Form.Item
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Data" style={styles.input} name="CompanyName" value={customerData.CompanyName} onChange={handleChange} />
                    </Form.Item>
                    <div style={{ margin: "3%" }} />
                    <div className="NewApplication-txt10">Company Address</div>
                    <div style={{ margin: "3%" }} />
                    <Form.Item
                      style={styles.FormItem}
                    >
                      <TextArea
                        placeholder="Enter Data"
                        rows={2}
                        style={styles.input2} value={customerData.PrimaryAddress} name="PrimaryAddress" onChange={handleTextAreaChange}
                      />
                    </Form.Item>
                  </div>
                </>
              </Card>
            </Col>
          </Row>
          <div className="" style={{ padding: "8px", marginTop: "2%" }}>
            <Checkbox onChange={handleCheckboxChange}>
              <div style={{ fontSize: "12px" }}>
                I agree to {"  "}
                <span className="NewApplication-txt6">Terms & Conditions </span>
              </div>
            </Checkbox>
            <div style={{ display: "flex", marginTop: "1.3%" }}>
              <Button>
                <span className="NewApplication-txt6" onClick={resetFormData}>Cancel</span>
              </Button>
              <div style={{ margin: 10 }} />
              <Button type="primary" htmlType="submit" loading={showFormSubmitLoader}>
                <span className="NewApplication-txt7">Send Application</span>
              </Button>
            </div>
          </div>
        </Form>
      </div >
    </div >
  );
}

export default NewApplication;

interface Customer {
  "ID": number;
  "CustomerGuid": string;
  "GroupGuid": string;
  "LocationGuid": string;
  "CurrencyGuid": string;
  "Name": string;
  "EmailAddress": string;
  "CellFullNumber": string;
  "IsCompany": boolean;
  "CompanyName": string;
  "PrimaryAddress": string;
}
