import { DefaultValueEnum, MenuShortNameEnum } from "./default-value-enum";

export class BusinessNote {
  BusinessEntity: string = MenuShortNameEnum.DOCUMENT_ATTACHMENT;
  BusinessEntityGuid: string = DefaultValueEnum.EmptyGuid;
  Description: string = '';
  Title: string = '';
  LocationId: string = DefaultValueEnum.EmptyGuid;
  LanguageGuid: string = DefaultValueEnum.EmptyGuid;
  IsFromPortal: boolean = true;
  LanguageGroupGuid: string = DefaultValueEnum.EmptyGuid;

  constructor(businessEntityGuid: string) {
    this.BusinessEntityGuid = businessEntityGuid;
  }

}