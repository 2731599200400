const FIELD_NAME = {
  FULL_NAME: "F11F45D0-1A39-11ED-9819-B5188091EA18",
  COMPANY_NAME: "6BCC3A80-D622-11EA-AE02-57A132B33678",
  CONTACT_NUMBER: "32CCDC40-BA2B-11ED-B5F0-2B1B3058D408",
  EMAIL: "59B5B030-12A3-11EA-909D-894EE92CA4F3",
  CONTACT_ADDRESS: "60E27F90-BA2B-11ED-B5F0-2B1B3058D408"
}

module.exports = {
  FIELD_NAME
}