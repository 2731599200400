import { Button, message } from "antd";
import { useState, useEffect, useRef } from "react";
import { MdManageSearch } from "react-icons/md";
import { IoAddCircleSharp } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../Config/API";
import DataTable from "./DataTable";
import Tabs from "./components/Tabs";
import SearchPopup from "./SearchPopup";
import LoadingBox from "../../Components/LoadingBox";
import { getDocumentJobPagingFromPortalByRequestPage, getDocumentJobPagingFromPortalByWhere } from "../../Services/document-job-service";
import { ApplicationStatusNameEnum } from "../../Config/default-value-enum";
import { SearchOperationBuilder, SearchParam } from 'wherecomposer/dist/SearchParam';
import { ConditionCreator } from 'wherecomposer/dist/ConditionCreator';
import { BiRefresh } from "react-icons/bi";

function ApplicationScreen(props: any) {

  const navigate = useNavigate();
  const location = useLocation();
  let isReadFilterUnreadedCount = false;

  let [dataSourceData, setDataSourceData] = useState([]);
  let [totalDataSource, setTotalDataSourceData] = useState([]);
  let [globalSearchParam, setGlobalSearchParam] = useState([]);
  const [loading, setLoading] = useState(false);
  let [type, setType] = useState(ApplicationStatusNameEnum.ALL);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  let isEventFiredComplete = false;

  let totalInitialActiveStatusCount: number = 0;

  let [totalApplicationCount, setTotalApplicationCount] = useState(0);
  let [totalActiveStatusCount, setActiveStatusCount] = useState(totalInitialActiveStatusCount);
  const [totalCompletedStatusCount, setCompletedStatusCount] = useState(0);
  let [totalCancelledStatusCount, setCancelledStatusCount] = useState(0);


  let REACT_APP_CANCELED_STATUS = API.REACT_APP_CANCELED_STATUS;
  let REACT_APP_COMPLETED_STATUS = API.REACT_APP_COMPLETED_STATUS;

  const searchPopup = () => {
    setIsPopupVisible(true);
  };

  useEffect(() => {
    console.log('REDIREDED PARAM', location.state);

    document.addEventListener('filterByUnReadCount', filterByUnReadCount);

    if (location.state != null && location.state != undefined) {
      if (location.state.hasOwnProperty('isFilterByUnReadCount')) {
        isReadFilterUnreadedCount = location.state.stateisFilterByUnReadCount;
      }
      else {
        isReadFilterUnreadedCount = false;
      }

      navigate(location.pathname, { replace: true });
    }
    else {
      isReadFilterUnreadedCount = false;
    }


    setType(ApplicationStatusNameEnum.ALL);
    loadInitialData(ApplicationStatusNameEnum.ALL);
    return () => { document.removeEventListener('filterByUnReadCount', filterByUnReadCount) };
  }, []);

  const searchAdvanceSearch = async (searchParamList: any) => {
    try {
      setType(ApplicationStatusNameEnum.ALL);
      setGlobalSearchParam(searchParamList);
      isReadFilterUnreadedCount = false;
      isEventFiredComplete = false;
      console.log('EVENT', isEventFiredComplete);
      await getDocumentJobPagingFromServer(searchParamList, ApplicationStatusNameEnum.ALL);

    }
    catch (error) {
      console.log('error.........', error);
    }
  }


  const filterByUnReadCount = async () => {
    console.log('EVENT FIRE COMPLTE', isEventFiredComplete);

    if (location.pathname == '/auth/applications') {
      console.log('EVENT', location.pathname);
      isEventFiredComplete = true;
      isReadFilterUnreadedCount = true;
      await setDefaultValues();
      await loadInitialData(ApplicationStatusNameEnum.ALL);
      isReadFilterUnreadedCount = false;
      navigate(location.pathname, { replace: true });
    }
  }

  const setDefaultValues = async () => {
    setTotalApplicationCount(0);
    setActiveStatusCount(0);
    setCompletedStatusCount(0);
    setCancelledStatusCount(0);
    setTotalDataSourceData([]);
    setDataSourceData([]);
  }

  const addNewApplication = () => {
    navigate("/auth/new-application");
  };

  const onChangeType = async (selectedType: any) => {
    setType(selectedType);
    await setDataSourceByType(selectedType, totalDataSource);
  };

  const loadInitialData = async (selectedType: any) => {
    setLoading(true);

    if (isReadFilterUnreadedCount == false) {
      let searchParamList = await getInitialWhere(selectedType);
      setGlobalSearchParam(searchParamList);
      await getDocumentJobPagingFromServer(searchParamList, selectedType);
    }
    else {
      setType(ApplicationStatusNameEnum.ALL);
      let searchParamList = await getFilterByUnReadedCount();
      setGlobalSearchParam(searchParamList);
      await getDocumentJobPagingFromServer(searchParamList, selectedType);
    }
  };

  const refreshTableData = async () => {
    setType(ApplicationStatusNameEnum.ALL);
    await getDocumentJobPagingFromServer(globalSearchParam, type);
    const customEvent = new Event('refreshData');
    document.dispatchEvent(customEvent);
  }

  const getDocumentJobPagingFromServer = async (searchParamList: any, selectedType: any) => {
    setLoading(true);

    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(searchParamList).toJsonString();

    await getDocumentJobPagingFromPortalByWhere(objWhere, 1)
      .then(async function (response: any) {

        let readedResult = JSON.parse(JSON.stringify(response));

        if (readedResult.pageCount > 1) {
          let nCount = readedResult.pageCount + 1;

          for (var i = 2; i < nCount; i++) {

            await getDocumentJobPagingFromPortalByRequestPage(objWhere, i).then(
              async (data) => {
                let objRemainingData = JSON.parse(JSON.stringify(data));
                readedResult.results.push(...JSON.parse(JSON.stringify(objRemainingData.results)));
              }).catch
              ((error) => {
                setLoading(false);
              });
          }
        }

        if (selectedType == ApplicationStatusNameEnum.ALL) {
          let applicationSummaryList = JSON.parse(JSON.stringify(response.resultSummaries));

          let activeDocumentList = applicationSummaryList.filter((obj: any) => {
            return obj.DocumentStatusGuid.toLowerCase() !== REACT_APP_CANCELED_STATUS.toLowerCase() &&
              obj.DocumentStatusGuid.toLowerCase() !== REACT_APP_COMPLETED_STATUS.toLowerCase()
          });

          let activeDocumentCount = activeDocumentList.reduce((total: any, obj: any) => {
            return total + obj.TotalRecords;
          }, 0);

          let cancelledDocumentList = applicationSummaryList.filter((obj: any) => {
            return obj.DocumentStatusGuid.toLowerCase() === REACT_APP_CANCELED_STATUS.toLowerCase()
          });

          let cancelledDocumentCount = cancelledDocumentList.reduce((total: any, obj: any) => {
            return total + obj.TotalRecords;
          }, 0);

          let completedDocumentList = applicationSummaryList.filter((obj: any) => {
            return obj.DocumentStatusGuid.toLowerCase() === REACT_APP_COMPLETED_STATUS.toLowerCase()
          });

          let completedDocumentCount = completedDocumentList.reduce((total: any, obj: any) => {
            return total + obj.TotalRecords;
          }, 0);


          let totalApplicationCount = activeDocumentCount + completedDocumentCount + cancelledDocumentCount;
          setTotalApplicationCount(totalApplicationCount);
          setActiveStatusCount(activeDocumentCount);
          setCompletedStatusCount(completedDocumentCount);
          setCancelledStatusCount(cancelledDocumentCount);
          setTotalDataSourceData(readedResult.results);
          await setDataSourceByType(selectedType, readedResult.results);
        }

        setTotalDataSourceData(readedResult.results);
        await setDataSourceByType(selectedType, readedResult.results);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        message.error("Something went wrong.....!");
      });
  }

  function setDataSourceByType(selectedType: any, totalDataSource: any) {
    setDataSourceData([]);

    if (selectedType == ApplicationStatusNameEnum.ALL) {
      setDataSourceData(totalDataSource);
    }
    else if (selectedType == ApplicationStatusNameEnum.ACTIVE) {
      let inActiveStateGuidList: any = [];
      inActiveStateGuidList.push(REACT_APP_CANCELED_STATUS.toLowerCase(), REACT_APP_COMPLETED_STATUS.toLowerCase());

      let activeApplications = totalDataSource.filter(function (e: any) {
        return !inActiveStateGuidList.includes(e.DocumentStatusGuid.toLowerCase())
      });

      setDataSourceData(activeApplications);
    }
    else if (selectedType == ApplicationStatusNameEnum.CANCELLED) {
      let cancelledApplications = totalDataSource.filter(function (e: any) {
        return e.DocumentStatusGuid.toLowerCase() == API.REACT_APP_CANCELED_STATUS.toLowerCase()
      });

      setDataSourceData(cancelledApplications);
    }
    else if (selectedType == ApplicationStatusNameEnum.COMPLETED) {
      let completedApplications = totalDataSource.filter(function (e: any) {
        return e.DocumentStatusGuid.toLowerCase() == API.REACT_APP_COMPLETED_STATUS.toLowerCase()
      });

      setDataSourceData(completedApplications);
    }
  }

  const getInitialWhere = async (selectedType: any) => {
    let lstobjSearchParam: any = [];

    if (selectedType === ApplicationStatusNameEnum.ACTIVE) {

      let objCancelledSearchParam = new SearchParam();
      objCancelledSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_CANCELED_STATUS, SearchOperationBuilder.create(false, false, false));
      lstobjSearchParam.push(objCancelledSearchParam);

      let objCompletedSearchParam = new SearchParam();
      objCompletedSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_COMPLETED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objCompletedSearchParam);
    }
    else if (selectedType === ApplicationStatusNameEnum.CANCELLED) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_CANCELED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
    }
    else if (selectedType === ApplicationStatusNameEnum.COMPLETED) {
      let objSearchParam = new SearchParam();
      objSearchParam.searchParamCreate("DocumentStatusGuid", "Guid", REACT_APP_COMPLETED_STATUS, SearchOperationBuilder.create(true, false, false));
      lstobjSearchParam.push(objSearchParam);
    }

    return lstobjSearchParam;
  }

  const getFilterByUnReadedCount = async () => {
    let lstobjSearchParam: any = [];
    let compareValue = 0;

    let objUnReadedCountSearchParam = new SearchParam();
    objUnReadedCountSearchParam.searchParamCreate("TotalUnReadedNoteCount", "Int", compareValue.toString(), SearchOperationBuilder.create(false, false, false));
    lstobjSearchParam.push(objUnReadedCountSearchParam);

    return lstobjSearchParam;
  }

  return (
    <div className="Screen-Container">
      <div className="Screen-Header">
        <div className="Screen-Headertxt1">Applications</div>
        <div className="Header-Box1">
          <div>
            <Button
              className="btn-advancesearch"
              type="link"
              size="large"
              icon={
                <BiRefresh
                  size={24}
                  style={{ marginRight: 10, marginBottom: 5 }}

                />
              }
              onClick={() => refreshTableData()}
            >
            </Button>

            <Button
              className="btn-advancesearch"
              type="link"
              size="large"
              icon={
                <MdManageSearch
                  size={22}
                  style={{ marginRight: 10, marginBottom: 5 }}
                />
              }
              onClick={() => searchPopup()}
            >
              Advanced Search
            </Button>
            {isPopupVisible ? (
              <SearchPopup
                visible={isPopupVisible}
                close={() => setIsPopupVisible(!isPopupVisible)}
                searchModal={searchAdvanceSearch}
              />
            ) : null}

            <Button
              className="btn-advancesearch"
              type="link"
              size="large"
              icon={
                <IoAddCircleSharp
                  size={20}
                  style={{ marginRight: 10, marginBottom: 5 }}
                />
              }
              onClick={() => addNewApplication()}
            >
              New Application
            </Button>
          </div>
        </div>
      </div>
      <br />
      <div className="Screen-Header2">
        <div className="Header-Box5">
          <Tabs
            text="All Applications"
            defaultValue={ApplicationStatusNameEnum.ALL}
            count={totalApplicationCount}
            value={type}
            select={(value: any) => onChangeType(ApplicationStatusNameEnum.ALL)}
          />
          <Tabs
            text="Active"
            defaultValue={ApplicationStatusNameEnum.ACTIVE}
            count={totalActiveStatusCount}
            value={type}
            select={(value: any) => onChangeType(ApplicationStatusNameEnum.ACTIVE)}
          />
          <Tabs
            text="Completed"
            defaultValue={ApplicationStatusNameEnum.COMPLETED}
            count={totalCompletedStatusCount}
            value={type}
            select={(value: any) => onChangeType(ApplicationStatusNameEnum.COMPLETED)}
          />
          <Tabs
            text="Cancelled"
            defaultValue={ApplicationStatusNameEnum.CANCELLED}
            count={totalCancelledStatusCount}
            value={type}
            select={(value: any) => onChangeType(ApplicationStatusNameEnum.CANCELLED)}
          />
        </div>
      </div>
      {loading ? (
        <LoadingBox />
      ) : (
        <div className="Screen-box">
          <DataTable data={dataSourceData} reload={() => loadInitialData(type)} />
        </div>
      )}
    </div>
  );
};

export default ApplicationScreen;
